// This file contains type declarations for resources we receive from the api.

import { Store } from 'redux';
import { Observable } from 'rxjs';
import { UserTypeKeyLabel } from './UserTypeLocale';
import { ApiRequest, ApiRequestMethod, ApiRequestPath, ZmdiIcon } from './common-types';
import * as constant from './constants';
import { RootState } from './core/RootReducer';
import { FileTypes } from './core/forms/FormUtils';
import { SinglewireHttpRequestOptions } from './core/utils/api';
import { API_RESOURCES } from './core/utils/api-resources';

export type MobileApiResourceUpdatePermissionMethod = 'get' | 'put' | 'delete';

export type MobileApiResourcePermissionMethod = MobileApiResourceUpdatePermissionMethod | 'post';

export interface MobileApiBoundaryTriggerActivity {
  id: string;
  createdAt: number;
  status: {
    status: 'failure' | 'success' | 'validation-error';
  };
  reason: 'entered' | 'exited';
  device: {
    name: string;
    user: {
      name: string;
      email: string;
      id: string;
    };
  };
}

export interface MobileApiResourcePermissions {
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiEditableResource {
  editing?: boolean;
  deleting?: boolean;
  original?: any;
}

export interface MobileApiPageEnvelope<T> {
  next: string | null;
  previous: string | null;
  total: number;
  data: T[];
}

export type ErrorReasonType =
  | 'site-id-immutable'
  | 'site-already-in-use'
  | 'already-verified'
  | 'required'
  | 'building-not-found'
  | 'verkada-server-error';

export type MobileApiValidationError = {
  field: string;
  type: ErrorReasonType;
  message: string;
};

export type MobileApiValidationErrorResponse = {
  message: string;
  reason: Array<MobileApiValidationError>;
};

export type MobileApiValidationIcopError = {
  field: string;
  type: ErrorReasonType;
  message: string;
  extras: {
    messageCode?: string;
    description?: string;
    fieldValue?: string;
    extendedMessage?: string;
    dependencies?: string;
    validOptions?: string;
    databaseId?: string;
  };
};

export type MobileApiValidationIcopErrorResponse = {
  message: string;
  reason: Array<MobileApiValidationIcopError>;
};

export interface MobileApiSecondaryModelValue {
  id: string;
  name: string;
}

export interface MobileApiIcopServerBuildInfo {
  app: string;
  version: string;
  buildSet: string;
  buildNumber: string;
  apiVersion: number;
  hostUuid: string;
  ipAddresses: {
    [interfaceName: string]: string[];
  };
  fqdn: string;
  ipv4Address?: string;
}

export interface MobileApiIcopServerConfig {
  backupSchedule?: string;
  description?: string;
  timeZone?: string;
}

export enum MobileApiIcopServerFailoverTypeEnum {
  Backup = 'backup',
  Primary = 'primary',
  Standalone = 'standalone',
}

export interface MobileApiIcopServerPhoneCacheData {
  createdAt: string;
  id: string;
  severity: string;
  state: string;
  type: string;
}

/**
 * All resource types that we sync down to fusion servers.
 * Can be found listed in `icop_syncer/sync/sync.clj`
 * {@link https://gitlab.singlewire.lan/fusion/icop-syncer/-/blob/56ad025ce0e0a051e1769b8c9c3a354c4ca0b486/src/icop_syncer/sync/sync.clj#L48}
 */
export type SyncableResourceType =
  | 'subscriptions'
  | 'message-templates'
  | 'distribution-lists'
  | 'bell-schedules'
  | 'cucm-clusters'
  | 'cucm-cluster-credentials'
  | 'cucm-cluster-axl'
  | 'ring-lists'
  | 'scheduled-notifications'
  | 'device-groups'
  | 'clear-devices-schedules'
  | 'activation-groups'
  | 'idn-admin-settings'
  | 'fusion-config'
  | 'tts-lexicons'
  | 'tts-voices'
  | 'failover-config'
  | 'fusion-servers'
  | 'license-config'
  | 'phone-config'
  | 'ip-speakers'
  | 'ip-speaker-settings'
  | 'ip-speaker-sip-parameters'
  | 'dialcast-dialing-configurations'
  | 'dialcast-phone-exceptions';

export interface MobileApiSyncErrors {
  resourceType: SyncableResourceType;
  resourceId: string;
  errors: string | null; // JSON payload, should always be an Array.
}

export interface MobileApiIcopServer extends MobileApiResourcePermissions {
  buildInfo: MobileApiIcopServerBuildInfo | null;
  config?: MobileApiIcopServerConfig;
  createdAt: string;
  extensionId: string;
  failoverBackup: MobileApiFailoverEndpoint | null;
  failoverConfigSyncState?: MobileApiSyncState;
  failoverPrimary: MobileApiFailoverEndpoint | null;
  failoverType: MobileApiIcopServerFailoverTypeEnum;
  features: string[];
  healthy: boolean;
  status: string;
  redReasons: string[];
  yellowReasons: string[];
  statusLastUpdated: string;
  id: string;
  name: string;
  isCucmMigrated?: boolean;
  lastPhoneCacheDistribute?: MobileApiIcopServerPhoneCacheData | null;
  lastPhoneCacheUpdate?: MobileApiIcopServerPhoneCacheData | null;
  syncErrors?: MobileApiSyncErrors[] | null;
  hasMigratedSpeakers?: boolean;
  hasMigratedDialcast?: boolean;
}

export interface MobileApiIcopServerBackup {
  id: string;
  lastModified: string;
  size: number;
  md5: string;
}

export interface MobileApiIcopServerActivity {
  createdAt: string;
  endpointId: string;
  extensionId: string;
  id: string;
  referenceId: string | null;
  referenceType: string | null;
  severity: string;
  state: 'complete' | 'success' | 'pending' | 'failed' | 'unknown';
  type: string;
  value: object | null;
}

export interface MobileApiGeneralCountsReport {
  usersCount: number;
  androidDevicesCount: number;
  speakersCount: number;
  pluginsCount: number;
  maxSpeakers: number;
  fusionServersCount: number;
  anonymousUsersCount: number;
  applicationsCount: number;
  idnsCount: number;
  lockedUsersCount: number;
  unlicensedUsersCount: number;
  emailDevicesCount: number;
  anonymousSubscriptionSize: number;
  maxFusionServers: number;
  phoneCallDevicesCount: number;
  phonesCount: number;
  polyPhonesCount: number;
  userIpPhonesCount: number;
  iosDevicesCount: number;
  smsDevicesCount: number;
  maxIdns: number;
  maxPhones: number;
  maxPolyPhones: number;
  subscriptionSize: number;
}

export interface MobileApiDeviceRegistrationReport {
  endpointId: string;
  type: string;
  totalDevices: number;
  licensedDevices: number;
  registeredDevices: number;
}

export type NotificationTypes =
  | 'ca-routing-request'
  | 'ca-redirect'
  | 'inbound-cap'
  | 'scenario'
  | 'cucm-panic-button'
  | 'webex-calling'
  | 'zoom-callaware'
  | 'kontaktio-wearable-panic-button'
  | 'poly-panic-button'
  | 'dial-cast'
  | 'inbound-email-rule'
  | 'm2m'
  | 'inbound-rss'
  | 'scheduled-notification'
  | 'bell-schedule'
  | 'confirmation-request-escalation-rule'
  | 'area-of-interest-boundary-trigger'
  | 'cisco-webex'
  | 'night-bell'
  | 'tracking-event-notification'
  | 'conference-call'
  | 'twitter-misconfiguration'
  | 'cisco-webex-bot-eviction'
  | 'scenario-launcher-failure'
  | 'alarm'
  | 'api'
  | 'rostering-push';

export type LicenseType = 'Phones' | 'Speakers' | 'Idns' | 'Users' | 'Anon';

export interface MobileApiTelephonyNumbersReportEntry {
  phoneNumber: string;
  createdAt: string;
  type: 'local' | 'toll-free' | 'toll-free-unverified';
  primary: boolean;
  id: string;
  serviceProvider: 'twilio' | 'bandwidth';
  features: Array<'calling' | 'sms'>;
}

export interface MobileApiConfirmationReportEntry {
  name: string;
  email: string | null;
  userId: string;
  confirmationResponse: string;
  confirmationResponseTimestamp: string;
  notificationId: string;
  notificationSubject: string;
  confirmationRequestName: string;
}

export interface MobileApiNotificationHistoryReportEntry {
  createdAt: string;
  senderName: string;
  subject: string;
  recipientCount: number;
  smsQueued: number;
  smsQueuedFailed: number;
  callQueued: number;
  callFailed: number;
  emailQueued: number;
  emailFailed: number;
  pushQueued: number;
  pushFailed: number;
}

export type MobileApiTelephonyNumbersReport = MobileApiTelephonyNumbersReportEntry[];

export interface MobileApiExtensionsSmsAlphanumericSenderIdsSupported {
  country: string;
  countryCode: string;
  isAlphanumericSenderIdSupported: boolean;
  isPreRegistrationRequired: boolean;
  preRegistrationRequiredNotes?: string | null;
}

export interface MobileApiExtensionsSmsAlphanumericSenderIdsAvailable {
  countryCode: string;
  alphanumericSenderId: string;
}

export interface MobileApiExtensionsSmsAlphanumericSenderIds {
  supported: MobileApiExtensionsSmsAlphanumericSenderIdsSupported[];
  available: MobileApiExtensionsSmsAlphanumericSenderIdsAvailable[];
}

export interface MobileApiDomain extends MobileApiResourcePermissions, DomainedResource {
  createdAt?: string;
  id: string;
  name: string;
  namePath: string;
  parentId: string | null;
  path: string;
}

export interface MobileApiFacility extends MobileApiResourcePermissions {
  createdAt?: string;
  name: string;
  id: string;
  siteId: string | null;
}

/**
 * Enumerates the resources which we know to be domain-enabled.
 */
export type DomainedResourceSpecKey =
  | '/anonymous-users'
  | '/service-accounts'
  | '/bell-schedules'
  | '/conference-call'
  | '/cisco-webex-teams'
  | '/confirmation-requests'
  | '/device-groups'
  | '/desktop-users'
  | '/distribution-lists'
  | '/incident-plans'
  | '/incidents'
  | '/load-definitions'
  | '/message-templates'
  | '/msteams-channels'
  | '/notification-profiles'
  | '/notifications'
  | '/outbound-cap'
  | '/outbound-rss'
  | '/ring-lists'
  | '/rostered-users'
  | '/scenarios'
  | '/security-groups'
  | '/site-roles'
  | '/sites'
  | '/twitter'
  | '/users';

/**
 * Enumerates the domain-enabled resources that have subtypes.
 */
export type MobileApiDomainedResourceSpecKey = '/collaboration-groups' | '/users';

export interface MobileApiDomainEnabledResource {
  domainPath: DomainedResourceSpecKey;
  resourcePath: DomainedResourceSpecKey[];
}

/**
 * All Domain-enabled resources have a common set of pieces of information
 * which can be used to build the user interface for managing their Domain
 * assignments. This interface defines that information.
 */
export interface DomainedResourceSpec {
  /** The key used to build this spec. */
  resourceKey: DomainedResourceSpecKey;
  /** How to make an API request to find or manipulate all the resources of this type in a domain. */
  joinApiKey: keyof typeof API_RESOURCES;
  /** How to make an API request to find or manipulate the domains to which this resource belongs. */
  joinInverseApiKey: keyof typeof API_RESOURCES;
  /** How to describe multiple instances of this resource to the user. */
  pluralName: string;
  /** A subset of the routes used to render the interface for the resource. */
  routes: {
    /** How to build a route to the Domains tab in the resource UI for a particular domained resource. */
    DomainsTab: (resourceId: string) => string;
    /** How to build a route to the top-level Edit interface for a particular domained resource. */
    Edit: (resourceId: string) => string;
    /** The top-level list page for this resource, to bail out if the user has switched to a domain where it is no longer visible. */
    List: string;
    /** The interface for managing the domains associated with a particular resource. */
    ManageDomains: (resourceId: string) => string;
    /** The interface for managing the resources associated with a particular domain.  */
    ManageResource: (domainId: string) => string;
    /** How to build a route to the resource tab on the Domains UI for a particular domained resource. */
    ResourcesTab: (domainId: string) => string;
  };
  /** How to make an API request to find the domained resource itself. */
  resourceApiKey: keyof typeof API_RESOURCES;
  /** How to find the resource in the resolve route data on a Manage Resource Domains page. */
  resourceRouteDataKey: string;
  /** How to describe a single instance of this resource to the user. */
  singularName: string;
  /** String to use rather than applying toLowerCase() on singlularName. */
  singularNameLower?: string;
  /** Name to use rather than applying toLowerCase() on pluralName. */
  pluralNameLower?: string;
  /** How to pick the specific type of user we want. */
  filter?: { [filterKey: string]: string | boolean };
  /** Features that must be enabled to show this resource. */
  featuresRequired?: Feature[];
  /** Custom DomainResourceItem component */
  customDomainResourceItem?: (resource: any, spec: DomainedResourceSpec) => JSX.Element;
  /** Custom column label for DomainResourceItems */
  customResourceColName?: string;
  /** Base query to use with DomainResource api request */
  baseQuery?: string | { and: string[] };
  /** Whether to hide the edit pencil when viewing list of domain-enabled resources (e.g. notifications) */
  readOnly?: boolean;
}

/**
 * Instances of APIResource that get put in Domains when the Domains feature
 * is turned on extend this additional interface.
 */
export interface DomainedResource {
  id: string;
  name?: string;
  domains?: MobileApiDomain[];
  superGroup?: boolean;
  loadType?: loadType;
  application?: MobileApiUserApplication;
}

type ProviderManagerFeature =
  | 'cucmEnabled'
  | 'demo'
  | 'domains'
  | 'failover'
  | 'fusion'
  | 'gms'
  | 'maxPsaps';
type ProviderManagerExtensionFeature =
  | 'conferenceCall'
  | 'desktopPush'
  | 'email'
  | 'kontaktio'
  | 'mobilePush'
  | 'msteamsCall'
  | 'msteamsChannel'
  | 'msteamsChat'
  | 'outboundIpaws'
  | 'outboundIpawsAdmin'
  | 'phoneCall'
  | 'sms'
  | 'twitter'
  | 'webexTeams'
  | 'zoom'
  | 'zoomSites'
  | 'visitorAwareIntegration'
  | 'visitorAwareFamilyReunification'
  | 'sisLoader'
  | 'facilitiesEnabled';

type UnleashFeature =
  | 'fullScreenNotifications'
  | 'panicButtonMocking'
  | 'resourceCenter'
  | 'thirdPartyMarketplaceApplications'
  | 'facilitiesEnabled'
  | 'uiComponentRegistry'
  | 'cloudDialcast'
  | 'globalApiConnectorsList'
  | 'uiSpotlightSearch'
  | 'openApi'
  | 'ipCameras'
  | 'criticalAlertsAndroid'
  | 'adHocDeviceSelection'
  | 'newPolygonDrawing'
  | 'webexCalling'
  | 'copyFromSite'
  | 'siteRoutesRefactoring';

export type Feature = ProviderManagerFeature | ProviderManagerExtensionFeature | UnleashFeature;

export type FusionServerFeature =
  | typeof constant.REVERSE_SYNCING_FEATURE
  | typeof constant.CLOUD_BELL_SCHEDULE_FEATURE
  | typeof constant.CURRI_FEATURE
  | typeof constant.DIAL_TO_INTERCOM_FEATURE
  | typeof constant.DISTRIBUTED_ACTIVATION_FEATURE
  | typeof constant.FIPS_FEATURE
  | typeof constant.GENERIC_MULTICAST_STREAMING_FEATURE
  | typeof constant.NON_VALIDATED_ALERT_TONE_CODES_FEATURE
  | typeof constant.STYLE_SUPPORT_FEATURE
  | typeof constant.VDI_LOCATION_FEATURE
  | typeof constant.FUSION_SERVER_UPGRADE_FEATURE
  | typeof constant.REQUIRE_NUMERIC_SIP_SPEAKER_DN
  | typeof constant.ADVANCED_BROADCAST_PARAMETERS_FEATURE
  | typeof constant.ADHOC_QUEUEING_FEATURE
  | typeof constant.CTI_OVER_TLS_FEATURE
  | typeof constant.FAILOVER_FEATURE
  | typeof constant.CTI_MAX_FEATURE
  | typeof constant.DNS_SRV_FEATURE
  | typeof constant.CLOUD_CUCM_CLUSTERS_FEATURE
  | typeof constant.PROVIDER_KEY_ROTATION
  | typeof constant.COLLECT_LOGS_FEATURE
  | typeof constant.UCAAS_INTERCOM_FEATURE
  | typeof constant.SYSTEM_ALARMS_FEATURE
  | typeof constant.ON_PREM_BROADCAST_SUMMARY
  | typeof constant.DISTRIBUTED_GATHERING
  | typeof constant.MODELESS_INFORMACAST
  | typeof constant.REMOVE_CAN_LISTEN
  | typeof constant.SIP_ACCOUNTS_FEATURE
  | typeof constant.UNENCRYPTED_UCAAS_INTERCOM_FEATURE
  | typeof constant.IDN_LARGE_FONTS
  | typeof constant.WEBEX_CALLING;

export interface MobileApiSession {
  providerId: string;
  email: string;
  idleTimeoutCustom: boolean;
  name: string;
  isLicenseValid: boolean;
  idleTimeout?: number | null;
  userId: string;
  userIsGlobal?: boolean;
  expires?: Date;
  isDomainsAvailable: boolean;
  domain?: MobileApiDomain | MobileApiFacility;
  domainEnabledResources?: MobileApiDomainEnabledResource[];
  facilityEnabledResources?: MobileApiDomainEnabledResource[];
  usableDomains?: MobileApiDomain[];
  providerExpiration: string | null;
  providerName?: string;
  providerInternal?: boolean;
  serverSupportedFeatures: Array<FusionServerFeature | string>;
  features?: Feature[];
  demo?: boolean;
}

export interface MobileApiTrigger extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  name: string;
  spec: string;
  type: 'webhook' | 'sqs';
  url: string;
}

export interface MobileApiCallAwareRule extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  name: string;
  dialUser: string;
  dialHost: string | null;
  messageTemplateId: string;
  messageTemplate?: MobileApiMessageTemplate;
  deviceGroupIds: string[];
  deviceGroups?: MobileApiDeviceGroup[];
  distributionListIds: string[];
  distributionLists?: MobileApiDistributionList[];
}

export interface MobileApiMSTeamsBot {
  build: string;
  upToDate: boolean;
  tenantId: string | null;
  permissionsGranted: boolean;
  consentUrl: string | null;
}

export interface MobileApiBoundaryTrigger extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  name: string;
  action: 'entered' | 'exited' | 'both';
  distributionListIds: string[];
  distributionLists: MobileApiDistributionList[];
  userIds: string[];
  users: MobileApiUser[];
  notificationAction: 'send-to-none' | 'send-to-user' | 'send-to-message-template-recipients';
  perUserThrottlingInSecs: number;
  messageTemplateId?: string;
  messageTemplate?: MobileApiMessageTemplate;
  subject?: string;
  body?: string;
  areaOfInterestId?: string;
}

export interface MobileApiAreaOfInterestGeometryTypeCircle {
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
  properties: { radiusInM: number };
}

export interface MobileApiAreaOfInterest extends MobileApiResourcePermissions {
  id: string;
  name: string;
  geometryType: 'circle';
  geometryValue: MobileApiAreaOfInterestGeometryTypeCircle;
  createdAt: string;
  syncedDeviceTypes: string[];
}

export interface MobileApiBellScheduleException {
  id: string;
  description: string;
  startDate: string;
  endDate: string;
  ringListId: string | null;
  ringListName: string;
  ringListIsShared: boolean;
  ringListFacilityId: string;
}

export interface MobileApiBellScheduleRingListEntry {
  entryNum: number;
  ringListId: string;
  ringListName: string;
  ringListIsShared: boolean;
  ringListFacilityId: string;
}

export interface MobileApiBellSchedule extends MobileApiResourcePermissions {
  id: string;
  name: string;
  numWeeks: number;
  startDate: string;
  endDate: string;
  description: string;
  syncId: string;
  timeZone: string;
  isChecked?: boolean;
  bellScheduleExceptions: MobileApiBellScheduleException[] | null;
  bellScheduleEntries: MobileApiBellScheduleRingListEntry[] | null;
  syncState?: MobileApiSyncState[];
  endpointSyncState?: MobileApiSyncState | null;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface MobileApiApplicationSecret extends MobileApiResourcePermissions {
  id: string;
  applicationId: string;
  createdAt: string;
  expiresAt: string;
  secret: string;
}

export interface MobileApiApplicationScreenshot extends MobileApiResourcePermissions {
  id: string;
  applicationId: string;
  createdAt: string;
  screenshot: any;
  isHeroImage?: boolean;
}

export type ThirdPartyOauthApplicationType = 'GENERIC_CLIENT' | 'NATIVE_CLIENT' | 'SERVER';

export interface ThirdPartyOauthApplicationSecret {
  id: string;
  createdAt: string;
  expiresAt: string;
}

export interface MobileApiApplication extends MobileApiResourcePermissions {
  id: string;
  name: string;
  displayName: string;
  status: 'DRAFT' | 'PUBLISHED' | 'SUSPENDED' | 'SUBMITTED';
  type: ThirdPartyOauthApplicationType;
  createdAt?: string;
  updatedAt?: string;
  usePkce: boolean;
  primaryContact: {
    name?: string;
    email?: string;
    phoneNumber?: string;
  };
  version: string;
  icon?: any;
  scopes: string[];
  redirectUris?: string[] | null;
  testingRedirectUris?: string[] | null;
  deauthorizationUri?: string | null;
  testingDeauthorizationUri?: string | null;
  applicationInfo: {
    shortDescription: string;
    longDescription: string;
    privacyPolicyUrl?: string;
    termsOfUseUrl?: string;
    supportUrl?: string;
    supportEmail?: string;
    documentationUrl?: string;
    configurationUrl?: string;
  };
  secrets: ThirdPartyOauthApplicationSecret[];
  publisherInfo: {
    companyName?: string;
    companyCategories?: Array<
      | 'SURVEYS_AND_POLLS'
      | 'PRESENTATIONS'
      | 'MARKETING'
      | 'NOTE_TAKING'
      | 'PRODUCTIVITY'
      | 'EVENT_MANAGEMENT'
      | 'HUMAN_RESOURCES'
      | 'CRM'
      | 'WHITEBOARDS'
      | 'BUSINESS_SYSTEMS_INTEGRATOR'
      | 'CUSTOMER_SERVICE'
      | 'SCHEDULING'
      | 'SECURITY_AND_COMPLIANCE'
      | 'COLLABORATION'
      | 'TRANSCRIPTION_AND_TRANSLATION'
      | 'CARRIER_PROVIDER_EXCHANGE'
      | 'LEARNING_AND_DEVELOPMENT'
      | 'ANALYTICS'
      | 'BROADCASTING_AND_STREAMING'
      | 'VIRTUAL_BACKGROUNDS_AND_SCENES'
      | 'WORKFLOW_AUTOMATION'
      | 'SALES'
      | 'SOCIAL_ACTIVITIES'
      | 'GAMES'
      | 'PROJECT_MANAGEMENT'
      | 'RECORDINGS'
      | 'HEALTH_AND_WELLNESS'
      | 'ECOMMERCE'
    >;
    companyVertical?:
      | 'EDUCATION'
      | 'FINANCIAL_SERVICES'
      | 'GOVERNMENT'
      | 'HEALTHCARE'
      | 'KIDS'
      | 'OTHER';
    companyMarketSegments: Array<'CONSUMER' | 'SMALL_BUSINESS' | 'ENTERPRISE' | 'OTHER'>;
    companyLinesOfBusiness: Array<
      | 'PRODUCT'
      | 'HUMAN_RESOURCES'
      | 'STUDENT'
      | 'PROFESSOR'
      | 'ENGINEERING'
      | 'SALES'
      | 'FINANCE'
      | 'OTHER'
    >;
  };
  clientId?: string;
  forMarketplace: boolean;
  installationId?: string;
  screenshots?: any;
  parentApplicationId?: string;
  heroImageId?: string;
}

export interface MobileApiScenarioField {
  id?: string;
  name: string;
  variable: string;
  type: 'site' | 'text' | 'option';
  options?: string[] | null;
  rank: number;
  answer?: string;
}

export interface MobileApiScenarioInstruction {
  instruction: string;
  rank: number;
}

export interface MobileApiScenarioMessage {
  id: string;
  name: string;
  messageTemplateId: string;
  messageTemplate?: MobileApiMessageTemplate;
  siteRoleIds: string[];
  siteRoles?: MobileApiSiteRole[];
  subject?: string;
  body?: string;
  ttsCustomContent?: string;
  confirmationRequestId?: string | null;
  confirmationRequest?: MobileApiConfirmationRequest | null;
  incidentPlan?: MobileApiIncidentPlan | null;
  mtOverride?: boolean;
  displayScenarioQuestions?: boolean;
}

export type MobileApiCollaborationGroupType =
  | 'cisco-spark'
  | 'conference-call'
  | 'msteams-channel'
  | 'outbound-cap'
  | 'outbound-rss'
  | 'twitter';

export interface MobileApiCollaborationGroup extends MobileApiResourcePermissions {
  id: string;
  properties: { [key: string]: any };
  name: string;
  createdAt: string;
  type: MobileApiCollaborationGroupType;
  distributionListIds: string[];
  distributionLists: MobileApiDistributionList[];
  userIds: string[];
  users: MobileApiUser[];
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export interface MobileApiConfirmationRequestEscalationRule extends MobileApiResourcePermissions {
  id: string;
  comparison: 'GT' | 'LT' | 'EQ' | 'NE';
  collaborationGroups: MobileApiCollaborationGroup[];
  createdAt: string;
  distributionListIds: string[];
  deviceGroupIds: string[];
  comparisonValue: number;
  areasOfInterest: MobileApiAreaOfInterest[];
  confirmationRequestId: string;
  collaborationGroupIds: string[];
  audioSize?: number;
  imageHash?: string;
  audioHash?: string;
  optionLabel: string;
  distributionLists: MobileApiDistributionList[];
  imageSize?: number;
  body?: string;
  userIds: string[];
  deviceGroups: MobileApiDeviceGroup[];
  delay: number;
  areaOfInterestIds: string[];
  subject: string;
  users: MobileApiUser[];
}

export interface AssociatedSite {
  siteRecipientId: string;
  id: string;
  name: string;
  createdAt: string;
}

export interface MobileApiSiteRole extends MobileApiResourcePermissions {
  associatedSites: AssociatedSite[];
  id: string;
  name: string;
  createdAt: string;
  facilityId?: string;
  isShared?: boolean;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileApiRosteringSiteRole {
  id: string;
  name: string;
}

export interface MobileApiSiteExternalSource {
  externalId: string;
  type: 'kontaktio-wearable-panic-button' | 'zoom' | 'webex-calling';
}

export interface MobileApiSite extends MobileApiResourcePermissions {
  recipients?: MobileApiSiteRecipient[];
  sources: string[];
  externalSources?: MobileApiSiteExternalSource[];
  buildings?: MobileApiSiteBuilding[];
  buildingCount?: number;
  id: string;
  name: string;
  latitude?: number;
  longitude?: number;
  createdAt: string;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileApiSiteBuilding extends MobileApiResourcePermissions {
  id: string;
  name: string;
  siteId: string;
  siteName?: string;
  createdAt: string;
  floors?: MobileApiSiteBuildingFloor[];
  floorCount?: number;
  externalSources?: MobileApiSiteExternalSource[];
  latitude?: number;
  longitude?: number;
  addressLine1?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  emergency: boolean;
  contacts: Array<{ contactNumber: string; contactName?: string }>;
}

// TupleLngLat lists longitude before latitude because that's the way PostGIS
// does it. Because this is a huge footgun, use LatLng
// (google.maps.LatLngLiteral) any time you're not directly interacting
// directly with the Cirrus API.
export type TupleLngLat = [number, number];

export type GeoJsonPoint = {
  type: 'Point';
  coordinates: [number, number];
};

export type GeoJSONMultiPoint = {
  type: 'MultiPoint';
  coordinates: [TupleLngLat, TupleLngLat];
};

export type GeoJSONPolygon = {
  type: 'Polygon';
  coordinates: [TupleLngLat[]]; // note the nesting level -- GIS Polygon's
  // support more than one path, though floor boundaries never do
};

export interface MobileApiSiteBuildingFloor extends MobileApiResourcePermissions {
  id: string;
  name: string;
  createdAt: string;
  type: string;
  rank: number;
  siteId: string;
  siteName?: string;
  buildingId: string;
  buildingName?: string;
  zones?: MobileApiSiteBuildingFloorZone[];
  zoneCount?: number;
  externalSources?: MobileApiSiteExternalSource[];
  floorPlanImage?: File;
  floorPlanImageFiles: FileDetails[];
  imageOrigins?: GeoJSONMultiPoint;
  boundary?: GeoJSONPolygon;
}

export interface MobileApiSiteBuildingFloorZone extends MobileApiResourcePermissions {
  id: string;
  name: string;
  createdAt: string;
  type: string;
  siteId: string;
  siteName?: string;
  buildingId: string;
  buildingName?: string;
  floorId: string;
  floorName?: string;
  externalSources?: MobileApiSiteExternalSource[];
  boundary?: GeoJSONPolygon;
}

export interface MobileApiRingListEntry {
  deviceGroupIds: string[];
  deviceGroups: MobileApiDeviceGroup[] | null;
  distributionListIds: string[];
  distributionLists: MobileApiDistributionList[] | null;
  id: string;
  messageTemplate: MobileApiMessageTemplate;
  messageTemplateId: string;
  time: string;
}

export interface MobileApiRingList extends MobileApiResourcePermissions {
  id: string;
  name: string;
  description: string;
  entries: MobileApiRingListEntry[];
  syncId: string;
  syncState?: MobileApiSyncState[];
  endpointSyncState?: MobileApiSyncState | null;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export interface MobileApiSiteRecipient extends MobileApiResourcePermissions {
  id: string;
  siteRoleId: string;
  name: string;
  collaborationGroupIds: string[];
  deviceGroupIds: string[];
  distributionListIds: string[];
  areaOfInterestIds: string[];
  userIds: string[];
  collaborationGroups?: MobileApiCollaborationGroup[];
  deviceGroups?: MobileApiDeviceGroup[];
  distributionLists?: MobileApiDistributionList[];
  areasOfInterest?: MobileApiAreaOfInterest[];
  users?: MobileApiUser[];
  siteRole:
    | {
        name: string | undefined;
        id: string | undefined;
      }
    | undefined;
}

export type MobileApiSenarioNotificationRuleActionEvent = {
  scenarioNotification: MobileApiScenarioNotification;
};

export type MobileApiScenarioType = 'standard' | 'panic-button' | 'integration';
export type MobileApiScenarioSource =
  | 'cisco-enterprise'
  | 'webex-calling'
  | 'kontaktio-wearable-panic-button'
  | 'zoom'
  | 'custom'
  | null;

export type EncryptionValue = 'should-encrypt' | 'encrypted' | 'none';

export type MobileApiWebhookMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';

export interface MobileApiWebhookActionData {
  url: string;
  method: MobileApiWebhookMethod;
  headers: EncryptableRecord<DescribableKeyValueRecord>[];
  body: EncryptableRecord<ValueRecord>;
  queryParams: EncryptableRecord<DescribableKeyValueRecord>[];
  expireAfter: number;
  authorization?: MobileApiWebhookAuthorizationConfig;
}

export interface ValueRecord {
  value: string;
}

export interface KeyValueRecord extends ValueRecord {
  key: string;
}

export interface DescribableKeyValueRecord extends KeyValueRecord {
  description?: string;
}

export interface DescribableValueRecord extends ValueRecord {
  description?: string;
}

export interface EncryptionState {
  encryption: EncryptionValue;
}

export type EncryptableRecord<T extends ValueRecord> = T & EncryptionState;

export type MobileApiActionType = 'webhook';
export type MobileApiActionData = MobileApiWebhookActionData;
export interface MobileApiActionBase {
  type: MobileApiActionType;
}
export interface MobileApiWebhookAction extends MobileApiActionBase {
  type: 'webhook';
  webhook: MobileApiWebhookActionData;
}
export type MobileApiAction = MobileApiWebhookAction;

export type MobileApiRuleActionResourceRecord = {
  type: string;
  resource: {
    id: string;
    name: string;
    children?: null | MobileApiRuleActionResourceRecord[];
  };
};

export type MobileApiRuleAction = {
  name: string;
  rule: object;
  action: MobileApiAction;
  description?: string;
  id?: string;
  actionResources?: null | MobileApiRuleActionResourceRecord[];
  ruleResources?: null | MobileApiRuleActionResourceRecord[];
  createdAt?: string;
};

export type OauthGrantTypes = 'client_credentials' | 'account_credentials';
export type CredentialFormatTypes = 'basicAuthHeader' | 'body';
export type AuthorizationTypes = 'oauth2';
export interface MobileApiWebhookAuthorizationConfig {
  type: AuthorizationTypes;
  grantType: OauthGrantTypes;
  clientId: EncryptableRecord<ValueRecord>;
  clientSecret: EncryptableRecord<ValueRecord>;
  accountId?: EncryptableRecord<ValueRecord>;
  credentialFormat: CredentialFormatTypes;
  scope?: string;
  request: {
    url: string;
    headers: EncryptableRecord<KeyValueRecord>[];
    queryParams: EncryptableRecord<KeyValueRecord>[];
    body: EncryptableRecord<KeyValueRecord>[];
  };
}

export interface MobileApiScenario extends MobileApiResourcePermissions {
  id: string;
  name: string;
  icon: ZmdiIcon;
  backgroundColor: string;
  fields: MobileApiScenarioField[];
  messages: MobileApiScenarioMessage[];
  createdAt: string;
  incidentPlanId: string;
  incidentPlan?: {
    name: string;
    id: string;
    rosteringId: string | null;
    rosteringType: string | null;
    facilityId?: string;
    isShared?: boolean;
  };
  type: MobileApiScenarioType;
  source?: MobileApiScenarioSource;
  instructions: Map<string, string> | null;
  formattedInstructions: MobileApiScenarioInstruction[];
  phoneNumber?: string;
  secret?: string;
  confirmationType?: string;
  confirmationPin?: string;
  eventType?: string;
  siteId?: string;
  winHotkeys?: string;
  silentHotkey?: boolean;
  customConfirm?: string;
  locationEnabled: boolean;
  locationQuestionName?: string;
  locationVariable?: string;
  defaultSiteId?: string;
  defaultBuildingId?: string;
  defaultFloorId?: string;
  defaultZoneId?: string;
  defaultLocation?: {
    site: { id: string; name: string };
    building?: { id: string; name: string };
    floor?: { id: string; name: string };
    zone?: { id: string; name: string };
  };
  emergency?: boolean;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
  copyFromId?: string;
}

export type RosteringType = 'informacast' | 'visitor-aware';

export const rosteringTypeMap: { [key: string]: RosteringType } = {
  informacast: 'informacast',
  visitorAware: 'visitor-aware',
};

export interface MobileApiIncidentPlanRostering {
  type?: RosteringType;
  locations?: string[];
  statuses?: string[];
  incidentPlanRosteringId?: string;
  rosterManagerSiteRoleIds?: string[];
  rosterMemberSiteRoleIds?: string[];
  rosterManagerSiteRoles?: MobileApiRosteringSiteRole[];
  rosterMemberSiteRoles?: MobileApiRosteringSiteRole[];
}

export interface MobileApiIncidentPlanVAMappingStatus {
  sitesWithoutMappings: Array<{ id: string; name: string }>;
  areMappingsMissing: boolean;
}

export interface MobileApiIncidentPlan extends MobileApiResourcePermissions {
  id: string;
  name: string;
  description: string | null;
  createdAt: string;
  messageTemplates?: Array<{ id: string; name: string }>;
  scenarios?: Array<{ id: string; name: string }>;
  resources?: MobileApiIncidentPlanResource[];
  rostering?: MobileApiIncidentPlanRostering;
  visitorAwareBaseUrl?: string;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export interface MobileApiIncidentPlanResource extends MobileApiResourcePermissions {
  id: string;
  name: string;
  type: 'hyperlink' | 'file';
  hyperlink: string | null;
  fileName: string | null;
  contentType: string | null;
  size: number | null;
}

export interface MobileApiConfirmationRequest extends MobileApiResourcePermissions {
  createdAt: string;
  escalationRules: MobileApiConfirmationRequestEscalationRule[];
  dynamicReplies?: MobileApiDynamicReply[] | null;
  expirationPeriod: number | null;
  expiration: string | null;
  id: string;
  initiateTrackingOptions: boolean[];
  name: string;
  options: string[];
  trackingEventId?: string;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export type ConfirmationResponseCount = Pick<
  MobileApiDynamicReply,
  'first' | 'quota' | 'rest' | 'every'
> & {
  count: number;
};

export interface ConfirmationResponseCounts {
  [optionLabel: string]: ConfirmationResponseCount;
}

export interface MobileApiDynamicReply extends MobileApiResourcePermissions {
  id: string;
  confirmationRequestId: string;
  optionLabel: string;
  first?: string;
  rest?: string;
  every?: string;
  quota?: number;
}

export interface MobileApiDeviceGroupFilter {
  value: string;
  attribute: string;
  comparison: string;
  complement: boolean;
  caseSensitive: boolean;
}

export interface MobileApiSyncState {
  id: string;
  name: string;
  updatedAt?: string; // TODO: doesn't seem to do anything
  value?: any | null; // TODO: doesn't seem to do anything
  severity?: string; // TODO: doesn't seem to do anything
  errors: Array<{ message: string; code: string }> | null;
  status: 'complete' | 'pending' | 'validation-failed' | 'failed' | 'unknown' | null;
  recipientGroupId?: number;
  icopId?: number | null;
}

export type FilterType = 'OR' | 'AND' | 'ACCEPT' | 'REJECT' | 'LOGICAL_EXPRESSION';

export interface MobileApiDeviceGroup extends MobileApiResourcePermissions {
  additionIds: string[];
  additions: MobileApiDevice[];
  baseDeviceGroupIds: string[];
  baseDeviceGroups: any[]; // Can't do circular types
  createdAt: string;
  exclusionIds: string[];
  exclusions: MobileApiDevice[];
  filters: MobileApiDeviceGroupFilter[];
  filterType: FilterType;
  id: string;
  logicalExpression: string | null;
  name: string;
  syncId: string;
  numIdns?: number;
  numPhones?: number;
  numPolyPhones?: number;
  numPlugins?: number;
  numSpeakers?: number;
  syncState?: MobileApiSyncState[];
  endpointSyncState?: MobileApiSyncState | null;
  activationGroupIds?: string[];
  activationGroups?: MobileApiActivationGroup[];
  multicastConfigs?: Array<{ address: string; port: number; type: 'polycom' | 'none' | 'generic' }>;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export interface DeviceLocation {
  site?: MobileApiSite;
  building?: MobileApiSiteBuilding;
  floor?: MobileApiSiteBuildingFloor;
  zone?: MobileApiSiteBuildingFloorZone;
}

export const AllDeviceTypesList = [
  'api-device',
  'ip-speaker',
  'ip-camera',
  'webex-calling',
] as const;
export type AllDeviceTypes = (typeof AllDeviceTypesList)[number];
export const isDeviceType = (type: string): type is AllDeviceTypes =>
  AllDeviceTypesList.includes(type as AllDeviceTypes);

export type LocationEnabledDeviceTypes =
  | MobileApiApiDevice
  | MobileApiSpeakerV2
  | MobileApiIpCamera;

// shared base type that has s/b/f/z, facility, and pinned location -- not a full API object
// api-device and ip-speaker extend this type
export interface MobileApiLocation {
  siteId?: string;
  site?: { id: string; name: string };
  buildingId?: string;
  building?: { id: string; name: string };
  floorId?: string;
  floor?: { id: string; name: string };
  zoneId?: string;
  zone?: { id: string; name: string };
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  pinnedLocation?: GeoJsonPoint;
}

// minimum required to uniquely identify a resource from multiple potential types
export interface MobileApiResourceIdentifier {
  id: string;
  type: AllDeviceTypes;
}

export interface MobileApiSiteMappedDeviceLookup {
  lookupValue: string;
  type: string;
}

// response type from site-mapped-devices endpoint
export interface MobileApiSiteMappedDevice extends MobileApiResourceIdentifier, MobileApiLocation {
  name: string;
}

export interface MobileApiApiDevice extends MobileApiResourcePermissions, MobileApiLocation {
  id: string;
  name: string;
  pinnedLocation?: GeoJsonPoint;
  externalId: string;
  createdAt: string;
  // These are optional in MobileApiLocation so redefined here as they're required for ApiDevices
  siteId: string;
  site: { id: string; name: string };
  buildingId: string;
  building: { id: string; name: string };
}

export interface MobileApiDevice extends MobileApiResourcePermissions {
  description: string;
  deviceIdentifier: string;
  createdAt: string;
  type: string;
  extensionId: string;
  endpointId: string;
  id: string;
  icopId: string;
  attributes: { [key: string]: any };
  defunct: boolean;
  activationGroupEndpointId?: string;
  isLicensed?: boolean;
  activationGroupId?: string;
  // Applies to Activation Groups where phone falls back to the phone gatherer endpoint
  inPhoneGathererFallback?: boolean;
  // Applies to Activation Groups where phone matches on an activation group but is already included in a higher priority one
  inHigherPriority?: boolean;
}

export interface MobileApiInitiatorDevice {
  name: string;
  type?: string;
  location?: string;
  IPAddress?: string;
  directoryNumbers?: string;
  description?: string;
}

export interface MobileApiDistributionListCampaign {
  enrollmentEndDate: string;
  welcomeMessage: string | null;
  inactiveMessage: string | null;
  subscribeTag: string;
  defaultNotificationDays: number | null;
  url: string;
}

export interface MobileApiDistributionList extends MobileApiResourcePermissions, DomainedResource {
  campaign: MobileApiDistributionListCampaign | null;
  name: string;
  id: string;
  createdAt: string;
  syncState?: MobileApiSyncState[] | null;
  endpointSyncState?: MobileApiSyncState | null;
  isSubscribable: boolean;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export interface MobileApiDistributionListMember extends MobileApiResourcePermissions {
  createdAt: string;
  distributionListId: string;
  id: string;
  subscriptionEndDate?: string | null;
  userId: string;
  user: {
    createdAt?: string;
    email: string;
    id: string;
    name: string;
    type: MobileApiUserType | null;
    devices: null | Array<{
      deviceIdentifier: string;
      id: string;
      type: string;
    }>;
  };
  userInActingFacility?: boolean;
}

export interface DistributionListMemberSpec {
  /** Unique pager name for this mode. */
  pagerId: string;
  /** How to describe multiple users of this type. */
  pluralName: string;
  /** How to filter user subscriptions correctly in the API call. */
  query: string;
  /** A subset of the routes used to render the interface for the resource. */
  routes: {
    /** How to build a route to the Domains tab in the resource UI for a particular domained resource. */
    Tab: (resourceId: string) => string;
    /** The interface for managing the domains associated with a particular resource. */
    Manage: (resourceId: string) => string;
  };
  /** How to describe a single user of this type. */
  singularName: string;
  /** Title for tabs or detail pages */
  title: string;
}
export type DisplayOptions = 'show' | 'hide';

export type TtsType =
  | 'subject'
  | 'body'
  | 'subject-and-body'
  | 'custom-text'
  | 'custom-ssml'
  | 'none';

export type TtsSpeed = 'very-slow' | 'slow' | 'normal' | 'fast' | 'very-fast';

export interface FileDetails {
  fileName: string;
  size: number;
  contentType: string;
  metadata: any;
  url?: string;
}

export type CancelNotificationsType = 'none' | 'specific' | 'all';
export type AlertTone =
  | 'default'
  | 'silent'
  | 'accent'
  | 'bright'
  | 'chime'
  | 'chirp'
  | 'crystal'
  | 'fire-alarm'
  | 'industrial-alarm'
  | 'klaxon'
  | 'tone'
  | '911-dialed'
  | 'all-clear1'
  | 'all-clear2'
  | 'bell'
  | 'class-change1'
  | 'class-change2'
  | 'class-change3'
  | 'class-change4'
  | 'class-change5'
  | 'class-change6'
  | 'code-blue'
  | 'code-call'
  | 'evacuate1'
  | 'evacuate2'
  | 'evacuate3'
  | 'evacuate4'
  | 'internal-emergency1'
  | 'internal-emergency2'
  | 'internal-emergency3'
  | 'location-closing'
  | 'lockdown-1-pulse'
  | 'lockdown-2-pulses'
  | 'lockdown-3-pulses'
  | 'lockdown'
  | 'room-turn'
  | 'severe-weather-advisory'
  | 'shift-change1'
  | 'shift-change2'
  | 'shift-change3'
  | 'shift-change4'
  | 'take-shelter'
  | 'tornado-siren';
export type NotificationPriority = 'critical' | 'normal';

export interface MobileApiMessageTemplate extends MobileApiResourcePermissions {
  id: string;
  alertTone?: AlertTone;
  alertToneCustomizable?: boolean;
  alertToneDisplay?: DisplayOptions;
  areaOfInterestIds?: string[];
  areaOfInterestIdsCustomizable?: boolean;
  areaOfInterestIdsDisplay?: DisplayOptions;
  areasOfInterest?: MobileApiAreaOfInterest[];
  audioFiles: FileDetails[];
  audio?: any;
  audioCustomizable?: boolean;
  audioDisplay?: DisplayOptions;
  body?: string;
  bodyCustomizable?: boolean;
  bodyDisplay?: DisplayOptions;
  cancelNotificationsType?: CancelNotificationsType;
  cancelNotificationsTypeDisplay?: DisplayOptions;
  collaborationGroupIds?: string[];
  collaborationGroupIdsCustomizable?: boolean;
  collaborationGroupIdsDisplay?: DisplayOptions;
  collaborationGroups?: MobileApiCollaborationGroup[];
  confirmationRequest?: MobileApiConfirmationRequest | null;
  confirmationRequestId?: string;
  confirmationRequestIdCustomizable?: boolean;
  confirmationRequestIdDisplay?: DisplayOptions;
  createdAt?: string;
  deviceGroupIds?: string[] | null;
  deviceGroupIdsCustomizable?: boolean;
  deviceGroupIdsDisplay?: DisplayOptions;
  deviceGroups?: MobileApiDeviceGroup[];
  distributionListIds?: string[];
  distributionListIdsCustomizable?: boolean;
  distributionListIdsDisplay?: DisplayOptions;
  distributionLists?: MobileApiDistributionList[];
  expiration?: number;
  expirationCustomizable?: boolean;
  expirationDisplay?: DisplayOptions;
  followUp?: boolean;
  image?: any;
  imageFiles: FileDetails[];
  imageCustomizable?: boolean;
  imageDisplay?: DisplayOptions;
  imageMimeType?: 'image/png' | 'image/jpeg' | 'image/gif' | 'image/jpg' | null;
  incidentPlan?: { name: string; id: string; facilityId: string; isShared: boolean } | null;
  incidentPlanId?: string;
  incidentPlanIdCustomizable?: boolean;
  incidentPlanIdDisplay?: DisplayOptions;
  inferredMessageType?: string;
  messageTemplateCancellationIds?: string[];
  messageTemplateCancellations?: Partial<MobileApiMessageTemplate>[];
  metadata?: object;
  metadataCustomizable?: boolean;
  name: string;
  notificationProfile?: MobileApiNotificationProfile;
  notificationProfileId?: string;
  optOut?: boolean;
  subject?: string;
  subjectCustomizable?: boolean;
  subjectDisplay?: DisplayOptions;
  syncState?: MobileApiSyncState[];
  endpointSyncState?: MobileApiSyncState | null;
  ttsCustomContent?: string;
  ttsCustomContentCustomizable?: boolean;
  ttsType: TtsType;
  ttsVoiceId?: string;
  ttsVoiceIdCustomizable?: boolean;
  ttsSpeed?: TtsSpeed;
  userIds?: string[];
  userIdsCustomizable?: boolean;
  userIdsDisplay?: DisplayOptions;
  users?: MobileApiUser[];
  voice?: MobileApiTextToSpeechVoice;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
  priority?: NotificationPriority;
}

export interface MobileApiMetaData {
  id: string;
}

export interface MobileApiNotificationProfileSettingAttachment {
  contentType: string;
  id: string;
  metadata: object;
  name: string;
  url: string;
}

export interface MobileApiNotificationProfileSetting<T = any> {
  id: string;
  name: string;
  disabled: boolean;
  disabledAtSendTime?: boolean;
  value: T | null;
  extension: {
    disabled: boolean;
    globalDisabled: boolean;
    effectiveConfig: any;
  };
  attachments: MobileApiNotificationProfileSettingAttachment[];
}

export interface MobileApiNotificationProfile extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  name: string;
  default?: boolean;
  messageTemplates?: Array<{ id: string; name: string }>;
  settings: MobileApiNotificationProfileSetting[];
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  isShared?: boolean;
}

export type deletePolicy = 'retain' | 'delete';
export type updatePolicy = 'update' | 'skip';
export type loadType = 'manual' | 'azure' | 'sis';
export type type = 'simple' | 'anonymous' | 'cram' | 'digest';

export interface MobileApiSisUserTypes {
  teachers: boolean;
  parentsGuardians: boolean;
  students: boolean;
  staff: boolean;
}

export interface MobileApiLoadDefinition extends MobileApiResourcePermissions, DomainedResource {
  createdAt: string;
  loadType: loadType;
  applicationId: string;
  deletePolicy: deletePolicy;
  deviceUpdatePolicy: updatePolicy;
  distributionListUpdatePolicy: updatePolicy;
  domains: MobileApiDomain[];
  domainUpdatePolicy: updatePolicy | undefined;
  facilityUpdatePolicy: updatePolicy | undefined;
  id: string;
  name: string;
  securityGroupUpdatePolicy: updatePolicy;
  addPhoneCallDevices: string[];
  addSmsDevices: string[];
  licenseNewGroups: boolean;
  lastSyncStartedAt?: string;
  nextSyncAt?: string;
  sisAuthUrl?: string;
  sisBaseUrl?: string;
  sisClientId?: string;
  sisClientSecret?: string;
  sisIncludeUserTypes?: MobileApiSisUserTypes;
  sisFullSync?: boolean;
}

export interface MobileApiLoadDefinitionDomainMapping extends MobileApiResourcePermissions {
  id: string;
  loadDefinitionId: string;
  domainId: string;
  tagName: string;
  domain: MobileApiDomain;
  createdAt: string;
}

export interface MobileApiLoadDefinitionLoadRequest extends MobileApiResourcePermissions {
  createdAt: string;
  loadDefinitionId: string;
  id: string;
  tagsSeen: string[] | null;
  loadState: string;
  unmappedTags: string[] | null;
  containsLogs: boolean;
  createdUsers?: number;
  deletedUsers?: number;
  userUpdates?: number;
}

type configurationType = 'LDAP' | 'Manual';

export interface MobileApiLoadDefinitionLDAP extends MobileApiResourcePermissions {
  formConfiguration: configurationType;
  id: string;
  host: string;
  port: number;
  useSSL: boolean;
  connectTimeout: number;
  type: type;
  dn: string;
  realm: string;
  username: string;
  baseDn: string;
  userNameFormat: string;
  emailAttribute: string;
  baseFilter: string;
  subtree: boolean;
  disableNewEmails: boolean;
  disableNewSMS: boolean;
  disableNewPhoneCalls: boolean;
  pagingSize: number;
  emailAttributes: string;
  mobileAttributes: string;
  phoneCallAttributes: string;
  fields: MobileApiScenarioField[];
}

export interface MobileApiLDAPField {
  filter: string;
  tags: string;
}

export interface MobileApiLoadDefinitionSecurityGroupMapping extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  loadDefinitionId: string;
  securityGroup: MobileApiSecurityGroup;
  securityGroupId: string;
  tagName: string;
}

export interface MobileApiLoadDefinitionDistributionListMapping
  extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  loadDefinitionId: string;
  distributionList: MobileApiDistributionList;
  distributionListId: string;
  tagName: string;
}

export interface MobileApiLoadDefinitionUserTypeMapping extends MobileApiResourcePermissions {
  createdAt: string;
  id: string;
  loadDefinitionId: string;
  userType: ('regular' | 'rostered') & UserTypeKeyLabel;
  tagName: string;
}

export interface MobileApiScimUser extends MobileApiUser {
  error: string;
  internalId: string;
  createdUserId: string;
  scimUserId: string;
  userId: string;
  userName: string;
  externalId: string;
  scimMetadata: {
    emails: Array<{ type: string; value: string; primary: boolean }>;
    externalId: string;
    id: string;
    displayName: string;
    name: { formatted: string; givenName: string; familyName: string };
    schemas: string[];
    phoneNumbers?: Array<{ type: string; value: string; primary: boolean }>;
    addresses?: Array<{
      type: string;
      region: string;
      country: string;
      primary: boolean;
      locality: string;
      formatted: string;
      postalCode: string;
      streetAddress: string;
    }>;
    userName?: string;
    active?: boolean;
  };
}

export interface MobileApiScimUserGroup {
  id: string;
  name: string;
  externalId: string;
}

export interface MobileApiScimGroup {
  error: string;
  displayName: string;
  memberCount: number;
  externalId: string;
  id: string;
  createdAt: string;
  domainIds: string[];
  distributionListIds: string[];
  securityGroupIds: string[];
  licenseUsers: boolean;
  superGroup: boolean;
}

export interface MobileApiScimGroupMember {
  id: string;
  name: string;
  externalId: string;
  userId: string;
  isLicensed: boolean | null;
  scimMetadata: {
    emails: Array<{ type: string; value: string; primary: boolean }>;
    externalId: string;
    id: string;
    displayName: string;
    name: { formatted: string; givenName: string; familyName: string };
    schemas: string[];
  };
}

export interface MobileApiSecurityGroupMember extends MobileApiResourcePermissions {
  id: string;
  userId: string;
  securityGroupId: string;
  user: { name: string; email: string; type: string; id: string; domains?: MobileApiDomain[] };
}

export interface MobileApiUserSecurityGroup extends MobileApiResourcePermissions {
  id: string;
  securityGroupId: string;
  name: string;
  superGroup: boolean;
  securityGroup?: MobileApiSecurityGroup;
  manuallyAdded?: boolean;
}

export interface MobileApiSecurityGroup extends MobileApiResourcePermissions, DomainedResource {
  id: string;
  name: string;
  superGroup: boolean;
  createdAt: string;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileApiSecurityGroupUserLoaderGroup extends MobileApiResourcePermissions {
  id: string;
  name: string;
  createdAt: string;
  memberCount: number;
}

export interface MobileApiDistributionListsUserLoaderGroup extends MobileApiResourcePermissions {
  id: string;
  name: string;
  createdAt: string;
  memberCount: number;
}

export interface MobileApiTextToSpeechLexicon extends MobileApiResourcePermissions {
  id: string;
  language: string;
  find: string;
  replace: string;
}

export interface MobileApiTextToSpeechVoice extends MobileApiResourcePermissions {
  id: string;
  language: string;
  name: string;
  gender: string;
  engine: string;
}

export interface MobileApiTextToSpeechDefaultVoiceConfig extends MobileApiResourcePermissions {
  id: string;
  ttsVoiceId: string;
}

export interface MobileApiUserToken extends MobileApiResourcePermissions {
  name: string;
  client: string;
  createdAt: string;
  type: string;
  scope: string;
  expires: string | null;
  id: string;
  token: string;
  userId: string;
  subject: string;
}

export interface MobileApiUserLocation
  extends MobileApiResourcePermissions,
    MobileApiEditableResource {
  description: string;
  disabled: boolean;
  deviceId: string;
  name: string;
  createdAt: string;
  longitude: number;
  id: string;
  userId: string;
  latitude: number;
}

export interface MobileApiUserDevicePresence {
  areaOfInterestIds: string[];
  accuracyInM: number;
  areasOfInterestChecksum: string;
  updatedAt: string;
}

export type MobileApiDesktopType = 'apns-desktop' | 'apns-desktop-sandbox' | 'WNS';

export type MobileApiUserDeviceType =
  | MobileApiDesktopType
  | 'Android'
  | 'apns'
  | 'msteams-chat'
  | 'phone-call'
  | 'singlewire:CiscoIPPhone'
  | 'singlewire:email'
  | 'SMS';

export interface MobileApiUserDevice
  extends MobileApiResourcePermissions,
    MobileApiEditableResource {
  deviceIdentifier: string;
  disabled: boolean;
  verified: boolean;
  presence?: MobileApiUserDevicePresence;
  name: string;
  client?: string;
  createdAt: string;
  type: MobileApiUserDeviceType;
  build?: string;
  endpointId?: string;
  loadSourceId?: string;
  id: string;
  userId: string;
  user?: MobileApiUser;
  os?: string;
  updatedAt?: string;
  isBlocklistedInUserLoader: boolean;
  blocklistTimestamp: string | null;
}

export interface MobileApiIDNExtensionConfig {
  menuClearEnabled: 'allow' | 'deny';
  menuExitEnabled: 'allow' | 'deny';
  menuStopEnabled: 'allow' | 'deny';
  logoId: string | null;
}

// Always present, but completely empty if the user has not customized the extension
export interface MobileApiUserExtensionConfig {
  type?: string;
  simple?: {
    // Only present for 'simple' type
    end?: string | undefined; // DnD end time to resume notifications
    start?: string | undefined; // DnD start time to mute notifications
  };
}

export interface MobileApiUserExtension
  extends MobileApiResourcePermissions,
    MobileApiEditableResource {
  globalDisabled: boolean;
  configExports: any;
  disabled: boolean;
  config: MobileApiUserExtensionConfig | null;
  name: string;
  deviceLimit: number;
  createdAt: string;
  type: string;
  failureCode: string | null;
  proxyUrl: string | null;
  id: string;
  deviceType: string | null;
}

export interface MobileApiUserSubscription {
  id: string | null;
  subscriptionEndDate: string | null;
  distributionListId: string;
  distributionListName: string;
  distributionList?: MobileApiDistributionList;
}

export interface MobileApiUserScimSubscription extends MobileApiResourcePermissions {
  createdAt: string;
  distributionList: { id: string; name: string };
  id: string;
  scimGroupIds: string[];
  scimGroups: Array<{ id: string; displayName: string }>;
  userId: string;
}

export interface MobileApiSelfServiceSubscription extends MobileApiResourcePermissions {
  distributionListName: string;
  distributionListId: string;
  subscribed: boolean;
}

export interface MobileApiUserMessageTemplate extends MobileApiResourcePermissions {
  id: string; // MessageTemplate ID
  name: string; // MessageTemplate name
  optOut: boolean;
  optedOutAt: string | null;
  userId: string;
}

export type MobileApiUserType =
  | 'regular'
  | 'anonymous'
  | 'application'
  | 'endpoint'
  | 'rostered'
  | 'support';
export type MobileApiUserApplication =
  | 'mobile-plugin'
  | 'user-loader'
  | 'cloud-user-loader'
  | 'desktop-registration'
  | 'custom'
  | 'visitor-aware';

type MobileApiProviderIdpType = 'cirrus-idp' | 'google' | 'dummy' | 'azure' | 'saml2';

interface MobileApiProviderIdp {
  enabled: boolean;
  id: string;
  isDefault: boolean;
  name: string;
  selfRegistrationEnabled: boolean;
  type: MobileApiProviderIdpType;
}

export interface MobileApiUser extends MobileApiResourcePermissions, DomainedResource {
  email: string;
  name: string;
  createdAt: string;
  type: MobileApiUserType;
  application?: MobileApiUserApplication;
  idleTimeout: number | null;
  loadSource?: Array<{ id: string; name: string; type: loadType }>;
  loadSourceId?: string;
  id: string;
  isLocked: boolean;
  passwordResetRequired?: boolean;
  tfaRemovalRequested?: boolean;
  domains?: MobileApiDomain[];
  device?: MobileApiInitiatorDevice;
  selfRegistered: boolean;
  preferredName: string;
  preferredLanguage: string;
  preferredTimezone: string | null;
  providerIdpId?: string | null;
  providerIdp?: MobileApiProviderIdp;
  fromRegularUserLoader: boolean;
  fromCloudUserLoader: boolean;
  isLicensed: boolean;
  earliestTokenExpiration?: string;
}

export interface MobileApiNotificationProgress {
  recipientCount: number;
  emailSentCount: number;
  broadcastStartedCount: number;
  stopAudioCount: number;
  emailQueuedCount: number;
  callAnsweredCount: number;
  twitterTweetSplitCount: number;
  callPlacedCount: number;
  twitterTweetTruncatedCount: number;
  confirmableCount: number;
  deviceSkippedCount: number;
  smsReceivedCount: number;
  sendingStartedCount: number;
  trackingCompletedCount: number;
  initServicesCount: number;
  msteamsChatSentCount: number;
  inboundRssFailedCount: number;
  callSkippedCount: number;
  smsFailedCount: number;
  inboundCapFailedCount: number;
  callFailedCount: number;
  callQueuedCount: number;
  displayTextAndStartAudioCount: number;
  pushQueuedCount: number;
  twitterSentCount: number;
  trackingTerminatedCount: number;
  twitterTweetDuplicateCount: number;
  twitterFailedCount: number;
  expireCount: number;
  twitterImageOverLimitCount: number;
  ciscoSparkFailedCount: number;
  pushSentCount: number;
  conferenceCallLeftCount: number;
  trackingStartedCount: number;
  inboundEmailFailedCount: number;
  callCompletedCount: number;
  ciscoSparkCount: number;
  msteamsChatFailedCount: number;
  broadcastFinishedCount: number;
  extensions: {
    [key: string]: {
      type: string;
      total: number;
      failed: number;
      completed: number;
      deviceType: string;
    };
  };
  fusionQueuedCount: number;
  fusionSentCount: number;
  fusionFailedCount: number;
  fusionSyncStates: MobileApiSyncState[];
  ipPhonesQueuedCount: number;
  ipPhonesSentCount: number;
  ipPhonesFailedCount: number;
  speakersQueuedCount: number;
  speakersSentCount: number;
  speakersFailedCount: number;
  idnQueuedCount: number;
  idnSentCount: number;
  idnFailedCount: number;
  percentComplete: number;
  broadcastFailedCount: number;
  displayTextCount: number;
  infoCount: number;
  skipCount: number;
  escalationRuleSkippedCount: number;
  startAudioCount: number;
  smsSentCount: number;
  inboundEmailSentCount: number;
  smsQueuedCount: number;
  conferenceCallJoinedCount: number;
  inboundRssSentCount: number;
  emailFailedCount: number;
  recipientSkippedCount: number;
  abortCount: number;
  pushFailedCount: number;
  readCount: number;
  conferenceCallFailedCount: number;
  sendingFinishedCount: number;
  inboundCapSentCount: number;
}

export interface MobileApiNotificationRecipient extends MobileApiResourcePermissions {
  email: string;
  read: boolean;
  name: string;
  notificationId: string;
  createdAt: string;
  type: string;
  confirmationResponse: string | null;
  confirmationResponseTimestamp: string | null;
  id: string;
  devicesSummary: Array<any>;
}

export type MobileApiNotificationRecipientDeviceType =
  | 'IPSpeaker'
  | 'M2M_OutputPort'
  | 'Multicast Stream'
  | 'Paging Gateway 2'
  | 'QuickURL'
  | 'singlewire:CiscoIPPhone';
export interface MobileApiNotificationRecipientDevice extends MobileApiResourcePermissions {
  attributes: any;
  createdAt: string;
  deviceIdentifier: string;
  id: string;
  name: string | null;
  notificationId: string;
  provider: string;
  type: MobileApiNotificationRecipientDeviceType;
  userId: string;
  endpointId?: string;
  endpoint?: { id: string; name: string };
}

export interface MobileApiNotificationInitiationBroadcast {
  broadcastEventId: number;
  broadcastEventMarker: string;
  cancellationReason: null;
  concatenateText: boolean;
  confirmationPeriod: number;
  detailTemplate: string;
  details: string;
  id: string;
  initiatingUser: string;
  initiatingUserDomain: number;
  initiatingUserGuid: string;
  messageDescription: string;
  messageId: string;
  messageType: string;
  recipientSelectors: string;
  requireConfirmation: boolean;
  responses: [];
  sequenceNo: string;
  shortTemplate: string;
  shortText: string;
  skipUnresponsiveRecipients: boolean;
  sourceAddress: string;
  sourceType: string;
  timeInitiated: string;
}

export type AlarmType =
  | 'fusion-server-yellow'
  | 'fusion-server-red'
  | 'license-violation'
  | 'speakers-unregistered'
  | 'phone-cache-update-failed'
  | 'fusion-delta-sync-error-event'
  | 'scenario-device-resolution-failed'
  | 'potential-speaker-configuration-issue';

export interface MobileApiNotification extends MobileApiResourcePermissions {
  abortedAt: string | null;
  alertTone: string;
  areaOfInterestIds?: string[];
  areasOfInterest?: MobileApiAreaOfInterest[];
  audioFiles: FileDetails[];
  audio: number;
  body: string;
  collaborationGroupIds?: string[];
  collaborationGroups?: MobileApiCollaborationGroup[];
  confirmableCount: number;
  confirmationResponseCounts?: ConfirmationResponseCounts;
  confirmationResponse?: any;
  confirmationResponseTimestamp?: string;
  confirmationRequest: MobileApiConfirmationRequest | null;
  confirmationRequestId: string | null;
  confirmationResults?: any;
  createdAt: string;
  deviceGroupIds?: string[];
  deviceGroups?: MobileApiDeviceGroup[];
  distributionListIds?: string[];
  distributionLists?: MobileApiDistributionList[];
  expiresAt?: string | null;
  followUpAllowed: boolean;
  id: string;
  imageFiles: FileDetails[];
  image: number;
  imageMimeType: string;
  initiationDetails?: MobileApiInitiationDetails;
  initiator:
    | MobileApiUser
    | MobileApiNotificationInitiator
    | MobileApiNotificationApplicationInitiator
    | null;
  initiatorId: string;
  initiationSourceClientId:
    | 'iOS Device Secure'
    | 'Android Device Secure'
    | 'IcMobile Web'
    | 'Windows'
    | 'cirrus_admin'
    | undefined;
  initiationSourceDescription: AlarmType | string | undefined;
  initiationSourceId: string | undefined;
  initiationSourceType: NotificationTypes;
  initiationBroadcast: MobileApiNotificationInitiationBroadcast | null;
  messageTemplate: MobileApiMessageTemplate;
  messageTemplateId: string;
  metadata: MobileApiMetaData;
  notificationProfile: MobileApiNotificationProfile;
  progress: MobileApiNotificationProgress;
  readCount: number;
  recipientCount: number;
  subject: string;
  userIds?: string[];
  users?: MobileApiUser[];
  scenarioNotificationId?: string;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  additionalDeviceRecipients?: MobileApiSiteMappedDeviceLookup[];
  priority?: NotificationPriority;
  server?: { id: string; name: string };
}

export interface MobileApiScenarioNotification {
  scenario: MobileApiScenario;
}

export interface MobileApiNotificationApplicationInitiator {
  name: string;
  type: string;
  id?: string;
  device?: MobileApiInitiatorDevice;
}

export interface MobileApiNotificationInitiator {
  name: string;
  type: string;
  email: string;
  device: {
    name: string;
    type: string;
    location: string;
    IPAddress: string;
    description: string;
    directoryNumbers: string;
  };
  description: string;
  directoryNumbers: string;
  id?: string;
}

export interface MobileApiInitiationScenarioFields {
  id?: string;
  name: string;
  rank: number;
  type: 'site' | 'text' | 'option';
  answer: string;
  variable: string;
  answerSiteName?: string;
}

export interface MobileApiLocationObject {
  site: { id: string; name: string };
  building?: { id: string; name: string };
  floor?: {
    id: string;
    name: string;
    boundary?: GeoJSONPolygon;
    imageOrigins?: GeoJSONMultiPoint;
  };
  zone?: { id: string; name: string; boundary?: GeoJSONPolygon };
  floorPlanImageFiles?: [FileDetails];
  description: string;
}

export interface MobileApiInitiationDetails {
  fields?: MobileApiInitiationScenarioFields[];
  location?: MobileApiLocationObject;
  scenario?: MobileApiScenario;
  emergencyAction?:
    | 'open-dialer'
    | 'contacting-emergency-services'
    | 'none-need-emergency-building';
  conferenceCall?: { callingParty: string };
  nightBell?: { ctiPortNameAndDN: string };
  ciscoWebex?: { roomId: string };
  areaOfInterestBoundaryTrigger?: { action: 'entered' | 'exited' };
  escalationRule?: MobileApiConfirmationRequestEscalationRule;
  fireTime?: string;
  scheduledFireTime?: string;
  fromException?: boolean;
  ringListId?: string;
  type?: NotificationTypes;
  cloudRefIdKey?: string;
  messageTemplateId?: string;
  inboundRss?: {
    feedSrcUrl?: string;
    entryGuid?: string;
  };
  m2m?: {
    ipAddress: string;
    oid: string;
    oidValue: string;
  };
  inboundEmail?: {
    ruleSetId: string;
    srcEmailAddress: string;
    monitoredEmailAddress: string;
    subjectMatchRule: string;
    bodyMatchRule: string;
    emailValidationLevel: string;
  };
  initiationDevice?: MobileApiSiteMappedDevice;
  dialCast?: {
    recipientGroups: number[];
    messageId: string;
    dialcodes: string[];
    id: string;
    dialingPatternRegEx: string;
    messageDescription: string;
    recipientGroupName: string;
    dialed: string;
  };
  inboundCap?: {
    type: 'polled';
    alert: {
      event: string;
      status: 'Actual';
      expires: string;
      msgType: 'Alert';
      headline: string;
      language: null;
      identifier: string;
    };
    srcUrl: string;
    rulesetId: string;
    expression: string;
  };
  caRedirect?: {
    callType: 'CTI';
    callingDN: string;
    callingPhone: string;
    redirectedDN: string;
    routePointDN: string;
    callTime: string;
    callId: string;
    transformedDN: string;
  };
  caRoutingRequest?: {
    callType: 'CTI';
    callingDN: string;
    callingPhone: string;
    redirectedDN: string;
    routePointDN: string;
    callTime: string;
    callId: string;
    transformedDN: string;
  };
  event?: {
    location?: {
      room?: {
        id: number;
      };
      coordinates?: {
        x: number;
        y: number;
      };
    };
  };
}

export interface MobileApiActivation {
  attempts: number;
  failures: {
    networkError: string[] | null;
    unknownError: string[] | null;
    notReadyToPlay: string[] | null;
    refusedToRepeat: string[] | null;
    authenticationError: string[] | null;
  };
  successes: { total: number };
}

export interface MobileApiBroadCastSummaryResults {
  stats: {
    averageActivationTimeSeconds: number | undefined;
    averageDeactivationTimeSeconds: number | undefined;
    maximumActivationTimeSeconds: number | undefined;
    maximumDeactivationTimeSeconds: number | undefined;
    minimumActivationTimeSeconds: number | undefined;
    minimumDeactivationTimeSeconds: number | undefined;
  };
  devices: {
    total: number;
    identifiers: { idnDevices: string[]; phoneDevices: string[]; speakerDevices: [] };
    text: { activation: MobileApiActivation } | undefined;
    audio: { activation: MobileApiActivation } | undefined;
  };
  markers: {
    forcedStart: boolean;
    sendingStarted: string | null;
    sendingFinished: string | null;
    broadcastInitiated: string | null;
    lastDeviceActivatedAt: string | null;
    lastDeviceDeactivatedAt: string | null;
  };
}

export const BroadcastNotificationActivityTypes = [
  'abort',
  'broadcast-summary',
  'broadcast-finished',
  'broadcast-started',
  'broadcast-failed',
  'call-answered',
  'call-completed',
  'call-failed',
  'calling-statistics',
  'call-placed',
  'call-queued',
  'call-skipped',
  'callaware-recording',
  'cisco-spark',
  'cisco-spark-failed',
  'cisco-spark-skipped',
  'conference-call-failed',
  'conference-call-joined',
  'conference-call-left',
  'conference-call-skipped',
  'confirmation',
  'device-skipped',
  'display-text',
  'display-text-and-start-audio',
  'email-failed',
  'email-queued',
  'email-sent',
  'email-succeeded',
  'email-statistics',
  'expire',
  'msteams-call-failed',
  'msteams-call-invite-failed',
  'msteams-call-invite-succeeded',
  'msteams-call-placed',
  'msteams-call-skipped',
  'msteams-chat-failed',
  'msteams-chat-sent',
  'msteams-chat-skipped',
  'msteams-channel-failed',
  'msteams-channel-sent',
  'msteams-channel-skipped',
  'msteams-group-failed',
  'msteams-group-disabled',
  'twitter-sent',
  'twitter-failed',
  'twitter-skipped',
  'tracking-completed',
  'tracking-failed',
  'tracking-started',
  'tracking-terminated',
  'outbound-cap-failed-post',
  'outbound-rss-failed',
  'outbound-rss-feed-disabled',
  'outbound-rss-feed-failed',
  'outbound-rss-feed-updated',
  'outbound-rss-skipped',
  'push-failed',
  'push-queued',
  'push-sent',
  'push-statistics',
  'recipient-skipped',
  'sending-finished',
  'sending-initiated',
  'set-read-status',
  'sms-failed',
  'sms-queued',
  'sms-response',
  'sms-responses',
  'sms-sent',
  'sms-indeterminate',
  'sms-statistics',
  'sms-succeeded',
  'sms-total',
  'update-read-status',
  'escalation-rule-skipped',
  'info',
  'inbound-cap-sent',
  'inbound-cap-failed',
  'inbound-email-sent',
  'inbound-email-failed',
  'inbound-rss-sent',
  'inbound-rss-failed',
  'init-services',
  'start-audio',
  'skip',
  'sms-received',
  'stop-audio',
  'twitter-tweet-truncated',
  'twitter-tweet-split',
  'twitter-image-over-limit',
  'twitter-tweet-duplicate',
] as const;

export type BroadcastNotificationActivityType = (typeof BroadcastNotificationActivityTypes)[number];

export const ApiConnectorNotificationActivities = ['api-connector-result'] as const;

export type ApiConnectorNotificationActivityType =
  (typeof ApiConnectorNotificationActivities)[number];

export type NotificationActivityTypes =
  | ApiConnectorNotificationActivityType
  | BroadcastNotificationActivityType;

interface BaseMobileApiNotificationActivity extends MobileApiResourcePermissions {
  userId: string | null;
  user?: {
    name: string;
    id: string;
    email: string;
  };
  id: string;
  createdAt: string;
  notificationId: string;
  confirmationOption?: string;
  readStatus?: string;
  endpointName?: string;
}

interface ApiConnectorNotificationActivity extends BaseMobileApiNotificationActivity {
  type: ApiConnectorNotificationActivityType;
  //Details can be null for certain types
  details: {
    ruleActionId: string;
    result: string;
    scenarioId: string;
    ruleActionName: string;
  } | null;
}

export interface BroadcastNotificationActivity extends BaseMobileApiNotificationActivity {
  type: BroadcastNotificationActivityType;
  //Details can be null for certain types
  details: {
    deviceIdentifier: string;
    broadcastEventId: number;
    endpointId: string;
    id: string;
    results: MobileApiBroadCastSummaryResults;
  } | null;
}

export type MobileApiNotificationActivity =
  | ApiConnectorNotificationActivity
  | BroadcastNotificationActivity;

/**
 * Union of all different types of Events that an ActionResult can have.
 */
export type ActionEvent = {
  scenarioNotification: {
    scenario: { id: string; name: string };
    notificationIds?: string[];
  };
};

export interface MobileApiNotificationActionResult extends MobileApiResourcePermissions {
  id: string;
  name: string;
  description?: string;
  sourceType: string;
  event: ActionEvent;
  status: 'pending' | 'success' | 'failure' | 'skipped';
  reason?: string;
  ruleActionId: string;
  createdAt: string;
  webhookActivities: Array<{ outcome: string; type: string; response: any; createdAt: string }>;
}

export interface MobileApiUserNotification extends MobileApiResourcePermissions {
  alertTone: string;
  audioFiles: FileDetails[];
  audio: number;
  body: string;
  collaborationGroupIds?: string[];
  collaborationGroups?: MobileApiCollaborationGroup[];
  confirmationRequest: any;
  confirmationRequestId: string;
  confirmationResponse: any;
  confirmationResponseTimestamp?: string;
  createdAt: string;
  distributionListIds?: string[] | null;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
  id: string;
  imageFiles: FileDetails[];
  image: number;
  imageMimeType: any;
  initiator: MobileApiUser | MobileApiNotificationInitiator | null;
  initiatorId: string;
  messageTemplateId: string;
  metadata: {};
  read: boolean;
  readTimestamp: string | null;
  subject: string;
  userId: string;
}

export interface MobileApiIpawsAlert {
  alert: {
    addresses: string | null;
    any: any[];
    code: string[];
    identifier: string;
    incidents: string | null;
    info: [
      {
        area: [
          {
            altitude: number | null;
            areaDesc: string;
            ceiling: number | null;
            circle: string[];
            geocode: Array<{ valueName: string; value: string }>;
            polygon: string[];
          },
        ];
        audience: string | null;
        category: string[];
        certainty: string;
        contact: string | null;
        description: string | null;
        effective: string;
        event: string;
        eventCode: Array<{ valueName: string; value: string }>;
        expires: string;
        headline: string;
        instruction: string | null;
        language: string | null;
        onset: string;
        parameter: Array<{ valueName: string; value: string }>;
        resource: any[];
        responseType: string[];
        senderName: string;
        severity: string;
        urgency: string;
        web: string | null;
      },
    ];
    msgType: 'Alert' | 'Update' | 'Cancel';
    note: string | null;
    references: string | null;
    restriction: any;
    scope: string;
    sender: string;
    sent: string;
    source: string | null;
    status: string;
  };
  circleList: MobileApiIpawsCircle[] | null;
  cogId: number;
  currentlyActive: boolean;
  expiresAt: string;
  geocodeDataList: any[];
  identifier: string;
  channelList: string[];
  msgtype: 'Alert' | 'Update' | 'Cancel';
  parentIdentifier: string | null;
  polygonList: MobileApiIpawsPolygon[] | null;
  sentAt: string;
  sentBy: string;
  sentEmail: string | null;
  sentName: string;
  status: string;
}

export interface MobileApiIpawsAlertSummary {
  headline: string;
  sentName: string;
  weaShortText: string;
  cogId: number;
  disabled: boolean;
  expiresAt: string;
  sentBy: string;
  weaLongText: string;
  event: string;
  sentAt: string;
  status: string;
  msgtype: 'Alert' | 'Update' | 'Cancel';
  disabledBy: string | null;
  identifier: string;
  disabledAt: string | null;
  parentIdentifier: string | null;
  sentEmail: string;
}

export interface MobileApiIpawsAlertStatus {
  identifier: string;
  retryChannelStatusList: any[];
  successfullySubmitted: boolean;
  successfullyBroadcast: boolean;
}

export interface MobileApiIpawsConfigEventCode {
  defaultCategory: string;
  defaultResponseType: string;
  description: string;
  eventCode: string;
  ipawsChannelList: string[];
}

export interface MobileApiIpawsGeoCode {
  eastLon: number;
  ipawsChannelList: string[];
  name: string;
  northLat: number;
  recordType: string;
  sameCode: string;
  southLat: number;
  stateAbbrev: string;
  stateName: string;
  westLon: number;
}

export interface MobileApiIpawsConfig {
  details: {
    broadcastAuth: boolean;
    caeAuth: boolean;
    caeCmasAuth: boolean;
    cmasAlertAuth: boolean;
    cogEnabled: boolean;
    prodCogId: number;
    labCogId: number;
    easAuth: boolean;
    email: string;
    prodKeystoreExpiresAt: string;
    labKeystoreExpiresAt: string;
    name: string;
    providerId: string;
    publicAlertAuth: boolean;
    timezoneCode: string;
  };
  eventCodes: MobileApiIpawsConfigEventCode[];
  geoCodes: MobileApiIpawsGeoCode[];
}

export interface MobileApiIpawsArea {
  areaType: 'circle' | 'polygon';
  cogId: number;
  id: string;
  lat: number | null;
  latLonPairs: Array<{ lat: number; lon: number }> | null;
  lon: number | null;
  name: string;
  providerId: string;
  radius: number | null;
}

export interface MobileApiIpawsCircle {
  id: string;
  lat: number;
  lon: number;
  name: string;
  radius: number;
}

export interface MobileApiIpawsPolygon {
  id: string;
  cogId: number;
  latLonPairs: Array<{ lat: number; lon: number }> | null;
  name: string;
}

export interface RangeConflict {
  range: string;
}

export interface AddressConflict {
  conflictingResource: string;
}

export interface AddressConflict {
  executionError: string;
}

export interface ICOPCloudPhones extends MobileApiResourcePermissions {
  createdAt?: string;
  id: string;
  name: string;
  address: string;
  port: number;
  channel?: number;
  type: 'generic' | 'polycom';
  conflicts: any[];
  link?: string;
}

export type ICOPGenericCloudPhones = ICOPCloudPhones;

export interface ICOPPolycomPhones extends ICOPCloudPhones {
  channel: number;
}

export interface MobileApiFailoverEndpoint {
  id: string;
  name: string;
}

export interface MobileApiFailoverPair extends MobileApiResourcePermissions {
  backupEndpointId: string;
  backupEndpoint: MobileApiFailoverEndpoint;
  id: string;
  name: string;
  primaryEndpointId: string;
  primaryEndpoint: MobileApiFailoverEndpoint;
  timeout: number;
}

export type KontaktioWearablePanicButtonStatus = 'connected' | 'disconnected' | 'invalid-api-key';

export interface MobileApiKontaktioWearablePanicButtonStatus {
  id: string;
  status: 'connected' | 'disconnected' | 'invalid-api-key';
}

export interface MobileApiKontaktioWearablePanicButtonSite {
  name: string;
  source: string;
  externalId: string;
  matchedResource: { name: string; id: string } | null;
  importedResource: { name: string; id: string; externalSourceId: string } | null;
  buildings?: MobileApiSiteBuilding[];
  floors?: MobileApiSiteBuildingFloor[];
  zones?: MobileApiSiteBuildingFloorZone[];
}

export interface MobileApiWebexCallingStatus {
  isAuthorized: boolean;
  isInstalled: boolean;
  lastResync: { resyncStatus: boolean; resyncTime: string | null };
}

export interface MobileApiWebexCallingLocation {
  id: string;
  externalId: string;
  name: string;
  isDefunct: boolean;
  site: {
    id: string;
    name: string;
    externalSiteId: string;
  };
  building: {
    id: string;
    name: string;
    emergency: boolean;
  };
  importedDevicesCount: number;
}

export interface MobileApiWebexCallingDevice {
  id: string;
  externalId: string;
  webexLocationId: string;
  webexLocationName: string;
  name: string;
  serialNumber: string;
  ipAddress: string;
  macAddress: string;
  model: string;
  site: {
    id: string;
    name: string;
    externalSiteId: string;
  };
  building: {
    id: string;
    name: string;
    isEmergency: boolean;
    externalBuildingId: string;
  };
  zone?: {
    id: string;
    name: string;
  };
  floor?: {
    id: string;
    name: string;
  };
}

export interface MobileApiExtension<T = { [key: string]: any }>
  extends MobileApiResourcePermissions {
  id: string;
  name: string;
  type: 'device' | 'device-filter' | 'endpoint-proxy' | 'collaboration' | 'report';
  deviceType: string | null;
  globalDisabled: boolean;
  config: T | null;
  configExports: any | null;
  triggers: any | null;
  proxyUrl: any | null;
  createdAt?: string;
  disabled: boolean;
  deviceLimit: number | null;
  failureCode: string | null;
}

export interface MobileApiExtensionEndpointMapping extends MobileApiResourcePermissions {
  updatedAt: string;
  createdAt: string;
  resourceType: string;
  extensionId: string;
  resourceId: string;
  endpointId: string;
  id: string;
  icopId?: number | string | null;
  dependencies?: {
    plugins?: Array<any>;
    dialingConfigurations?: Array<any>;
  } | null;
}

export interface MobileApiFusionExtensionConfig {
  phoneResetStrategy?: 'immediately' | 'periodically' | 'never';
  phoneResetSchedule?: string;
  phoneResetTz?: string;
  phoneRebuildSchedule?: string;
  phoneRebuildTz?: string;
  phoneRefreshInterval?: number;
  defaultActivationGroupEndpointId?: string | null;
  disableAuthUrlUpdates?: boolean;
  phoneFilter?: Partial<MobileApiDeviceGroup>;
  speakerFilter?: Partial<MobileApiDeviceGroup>;
  idnFilter?: Partial<MobileApiDeviceGroup>;
  phoneGatherEndpointId?: string | null;
  phoneGatherEndpoint?: MobileApiSecondaryModelValue | null;
  disableMsteamsWelcomeCard?: boolean;
  userUCMPhoneAssociationEnabled?: boolean;
}

export type MobileApiFusionExtension = MobileApiExtension<MobileApiFusionExtensionConfig>;

export interface MobileApiCallingExtensionConfig {
  voice: string;
  callNumber: string | null;
  introduction: string;
  conferenceCallIntroduction: string;
}

export interface MobileApiEmailExtensionConfig {
  host: string;
  port: string;
  user: string;
  fromName: string;
  starttls: boolean;
  introText: string;
  fromAddress: string | null;
  headingText: string;
  replyToName: string;
  smtpEnabled: boolean;
  primaryColor: string | null;
  replyToAddress: string | null;
  primaryTextColor: string | null;
  'encrypted>password': string;
  secondaryTextColor: string | null;
}

export interface MobileApiMSTeamsChatExtensionConfig {
  disableMsteamsWelcomeCard: boolean;
}

export interface MobileApiSettingsConfig extends MobileApiResourcePermissions {
  preferredLocale: string;
  preferredTimezone: string;
}

export interface MobileApiSMSExtensionConfig {
  includeBody: boolean;
  introduction: string;
  alphanumericSenderIds: MobileApiExtensionsSmsAlphanumericSenderIdsAvailable[];
}

export interface ConnectionConfig {
  name: string;
  url: string;
  username: string;
  password?: string;
  'encrypt>password'?: string;
  'encrypted>password'?: string;
  profile: string;
}

export interface MobileApiCapExtension extends MobileApiExtension {
  config: {
    connectionConfigs: ConnectionConfig[];
  };
}

export interface MobileApiNetworkConfig extends MobileApiResourcePermissions {
  agentCommunityName?: string;
  generalSessionTimeout: number;
  phoneSessionTimeout: number;
  enableSLP: boolean;
  advertiseCFS: boolean;
  advertiseSOAP: boolean;
  slpScope: string;
  cfsURL: string;
  speakerAutoRegistration: boolean;
  speakerDefaultVolume: number;
  idnAutoRegistration: boolean;
  idnDefaultVolume: number;
  speakerRegistrationInterval: number;
  speakerCaptureKeepaliveInterval: number;
  removeExpiredDesktopAfterDays: 0 | 1 | 7 | 14 | 30 | 60 | 90;
}

export interface MobileApiSpeakerSettings extends MobileApiResourcePermissions {
  speakerAutoRegistration: boolean;
  speakerDefaultVolume: number;
  idnAutoRegistration: boolean;
  idnDefaultVolume: number;
  speakerRegistrationInterval: number;
  speakerCaptureKeepaliveInterval: number;
  removeExpiredDesktopAfterDays: 0 | 1 | 7 | 14 | 30 | 60 | 90;
}

export interface MobileApiSipSpeakerParameters extends MobileApiResourcePermissions {
  id?: string;
  telephonyProviders: string;
  transportProtocol: 'TCP';
  optionsPingInterval: number;
  registrationInterval: number;
  callRingTime: number;
  numericDnType?: boolean;
  lineSideConnection?: boolean;
  lineSideTransportProtocol?: 'UDP' | 'TCP' | 'TLS';
  lineSideSecureRtpRequired?: boolean;
  lineSideSipDomain?: string;
  lineSideOutboundProxy?: string;
  lineSideSipIdentity?: string;
  lineSideAuthId?: string;
  lineSideAuthPassword?: string;
}

export interface MobileApiSipSpeakerParametersV2 extends MobileApiResourcePermissions {
  id: string;
  endpointId: string;
  endpoint?: {
    id: string;
    name: string;
  };
  telephonyProviders: string;
  transportProtocol: 'TCP';
  optionsPingInterval: number;
  registrationInterval: number;
  callRingTime: number;
  numericDnType?: boolean;
  configureWithDnsSrv: boolean;
  lineSideConnection?: boolean;
  lineSideTransportProtocol?: 'UDP' | 'TCP' | 'TLS';
  lineSideSecureRtpRequired?: boolean;
  lineSideSipDomain?: string | null;
  lineSideOutboundProxy?: string | null;
  lineSideSipIdentity?: string | null;
  lineSideAuthId?: string | null;
  lineSideAuthPassword?: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface MobileApiSipAccess {
  allowDenyOrder: boolean;
  directives: MobileApiSipAccessDirective[];
}

export interface MobileApiSipAccessDirective {
  id: string;
  host: string;
  allowDirective: boolean;
  permissions?: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiSipAuthentication {
  authenticateRequests: boolean;
  authenticationPassword: string;
  authenticationUsername: string;
  nonceDuration: number;
  realm: string;
  secureRTPAllowed: boolean;
  secureSignalingRequired: boolean;
}

export interface MobileApiSipCredentials extends MobileApiResourcePermissions {
  id: string;
  realm: string;
  password: string;
  user: string;
}

export interface MobileApiSipCertificate {
  subjectCommonName: string;
  alias: string;
}

export interface MobileApiSipStack {
  cacheClientConnections: boolean;
  cacheServerConnections: boolean;
  debugSipStack: boolean;
  maxForwards: number;
  readTimeout: number;
}

export interface MobileApiSipServer {
  host: string;
  port: number;
}

export interface MobileApiSipServerGroup {
  configureWithDnsSrv: boolean;
  serviceDomain: string;
  id: string;
  name: string;
  transportProtocol: string;
  servers: MobileApiSipServer[];
}

export interface MobileApiSipAddressOfRecord {
  user: string;
  host?: string;
  displayName: string;
}

export interface MobileApiSipRegistration {
  id: string;
  name: string;
  enabled: boolean;
  serverGroup: { name: string; id: number };
  addressesOfRecord: MobileApiSipAddressOfRecord[];
  requireHost: boolean;
}

export interface MobileApiSipDnsSrvServiceRecords {
  name: string;
  ttl: number;
  dClass: string;
  type: string;
  priority: number;
  weight: number;
  port: number;
  target: string;
  targetAddress: string;
}

export interface MobileApiSipDnsSrvUsers {
  component: string;
  description: string;
  currentPriority: number;
}

export interface MobileApiSipDnsSrvRecords {
  serviceDescription: string;
  users: MobileApiSipDnsSrvUsers[];
  serviceRecords: MobileApiSipDnsSrvServiceRecords[];
}

export interface MobileApiSipAccountStatus {
  statusCode: 'REGISTERED' | 'REGISTERING' | 'FAILED' | 'DISABLED';
  failureCode?:
    | 'CONNECTION_FAILURE'
    | 'CONNECTION_CLOSED'
    | 'UNTRUSTED_CERT'
    | 'AUTH_FAILURE'
    | 'MISSING_CREDENTIALS'
    | 'TRANSACTION_TIMEOUT'
    | 'SECURITY_POLICY_REJECTION'
    | 'DNS_FAILURE'
    | 'UNKNOWN';
  configWarnings?: ['MISSING_AOR_HOST' | 'DUPLICATE_AOR'];
  message?: string;
}

export interface MobileApiSipAccount extends MobileApiResourcePermissions {
  id: string;
  name: string;
  transportProtocol: 'UDP' | 'TCP' | 'TLS';
  host: string;
  outboundProxies: string[];
  useDnsSrv: boolean;
  enableRegistration: boolean;
  user: string;
  authorizationId: string;
  password: string;
  sipService?: 'RING' | 'WEBEX' | 'ZOOM' | 'OTHER';
  status?: MobileApiSipAccountStatus;
  useRealmAuth?: boolean;
}

interface MobileApiBasePermission {
  id: string;
  verb: MobileApiResourcePermissionMethod;
  spec: string;
  domainId?: string;
  createdAt: string;
  domain?: MobileApiDomain;
}

export interface MobileApiUserPermission extends MobileApiBasePermission {
  userId: string;
}

export interface MobileApiSecurityGroupPermission extends MobileApiBasePermission {
  securityGroupId: string;
}

export type MobileApiPermission = MobileApiSecurityGroupPermission | MobileApiUserPermission;
export type StatefulMobileApiPermission = { loading?: boolean } & MobileApiPermission;

export interface MobileApiCallAwareCall extends MobileApiResourcePermissions {
  id: string;
  callId: string;
  callTime: string;
  callType: string;
  callingDN: string;
  calledDN: string;
  callingPhone: string;
  routePointDN: string;
  redirectedDN: string;
  hasAudioFile: boolean;
  recordingInProgress: boolean;
  audioPartsCount: number;
  audio: File;
}

export interface MobileApiLiveCallAwareCallActivity {
  endpointId: string;
}

export interface MobileApiCallAwareConfiguration {
  callHistoryRetentionPeriod: number;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiCallAwareRedirect {
  id: number;
  name: string;
  routePointDN: string;
  redirectDN: string;
  callMonitored: boolean;
  recipientGroupsJson: any[];
  messageJson: any;
  shortText: string;
  longText: string;
  monitorToneType: number;
  recipientIDs: string;
  messageID: number;
  usingPhoneCSS: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiCallAwareRoutingRequest {
  id: number;
  name: string;
  calledDN: string;
  callMonitored: boolean;
  recipientGroupsJson: any[];
  messageJson: any;
  shortText: string;
  longText: string;
  monitorToneType: number;
  recipientIDs: string;
  messageID: number;
  usingPhoneCSS: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiCallAwareCUCM {
  id: number;
  clusterRole: string;
  icIpAddress: string;
  applicationUser: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiCallAwareDiagnostics {
  ctiProvider: string;
  ctiProviderIsSecure: boolean;
  cucmVersion: string;
  applicationUser: string;
  jtapiVersion: string;
  ctiPorts: any[];
  routePoints: any[];
}

export interface MobileApiNetworkTimeout {
  axlConnectTimeoutMs: number;
  axlReadTimeoutMs: number;
  commandPusherConnectTimeoutMs: number;
  commandPusherReadTimeoutMs: number;
  ldapConnectTimeoutMs: number;
  ldapReadTimeoutMs: number;
  remotePortMax: number;
  remotePortMin: number;
  slpSocketTimeout: number;
  snmpAuthScheme: string;
  snmpEncryptScheme: string;
  snmpTimeoutMs: number;
}

export interface IcopApiBroadcastParameters {
  jtapiPhoneActivationEnabled?: boolean;
  jtapiStaticTerminalsEnabled?: boolean;
  multicastAddressMin: string;
  multicastAddressMax: string;
  multicastTtl: number;
  blendingEnabled: boolean;
  priorityLevels: number;
  historyMaxDays: number;
  recordingLimit: number;
  liveLimit: number;
  vibrateEnabled: boolean;
  vibrateDuration: number;
  vibrateSilenceDuration: number;
  vibrateCount: number;
  backlightPeriod: number;
  queuedBroadcastMaxWaitTime: number;
  queuedBroadcastRetryInterval: number;
  queuedBroadcastSendThreshold: number;
  silenceSentByDialCastIVR: boolean;
  dialCastOptionIVRSIPIdentity: string;
  cancelBroadcastsDn?: any;
  cancelBroadcastsAuthType: number;
  cancelBroadcastsAuthPin: string;
  maxConcurrentCommands: number;
  maxConcurrentBroadcasts: number;
  maxQueuedBroadcasts: number;
  dejitterBufferMS: number;
}

export interface FusionConfigurationCtiPort {
  name: string;
  state: string;
  registeredAddress: string;
  registeredPort: string;
  dn: string;
}

export type FusionConfigurationResiliencyRole = 'PUBLISHER' | 'SUBSCRIBER';
export type FusionConfigurationResiliencyStatus = 'Online' | 'Offline' | 'Active' | 'Standby';

export type FusionConfigurationFailoverRole = 'Primary' | 'Backup' | 'Standalone';
export type FusionConfigurationFailoverState = 'Online' | 'Active' | 'Standby';
export type FusionConfigurationFailoverCloudState =
  | 'PRIMARY_CONNECTED'
  | 'PRIMARY_DISCONNECTED'
  | 'CLOUD_UNAVAILABLE';

export interface FusionConfigurationParameters {
  userAgentStartException: string | null;
  sendCommandsByJtapi: boolean;
  multicastPortsUsedByTalkAndListenMessages: number;
  phoneRefreshSuccessTimeGMT: string;
  publishSOAPAPI: boolean;
  userAgentStatus: string;
  publishSpeakerConfigurationURL: boolean;
  resiliencyInfo?: Array<{
    isCurrentServer: boolean;
    role: FusionConfigurationResiliencyRole;
    fileQueue: number | null;
    ipAddress: string;
    queue: number | null;
    status: FusionConfigurationResiliencyStatus;
  }>;
  licensedPhones: number;
  userAgentStartFailure: boolean;
  licensedSpeakers: number;
  startTimeGMT: string;
  backupActivated: boolean;
  phoneRefreshInterval: number;
  phoneCount: number;
  fileQueueSizes: { [key: string]: number };
  sipRegistrationSnapshot: {
    snapshot: Array<SipAccountInfo>;
  };
  nextScheduledBackupGMT: any;
  jtapiVersion: string;
  nextScheduledBackupLocal: any;
  sipCallsSnapshot: {
    snapshot: Array<{
      startTimeLocal: string;
      from: string;
      to: string;
    }>;
  };
  routeAddresses: Array<{
    terminal: string;
    address: string;
    state: string;
  }>;
  currentServerTimeGMT: string;
  multicastPortsConfigured: number;
  phoneRefreshAttemptTimeLocal: string;
  phoneRebuildSuccessTimeLocal: string;
  phoneRebuildNextTimeGMT: string;
  phoneRebuildSuccessTimeGMT: string;
  ldapAuthenticationEnabled: boolean;
  phoneRefreshAttemptTimeGMT: string;
  applicationMode: 'Publisher' | 'Subscriber' | 'Stand-alone';
  numberOfRegisteredSpeakers: number;
  slpEnabled: boolean;
  startTimeLocal: string;
  multicastPortsUnused: number;
  basicPagingMode: boolean;
  currentServerTimeLocal: string;
  numberOfConfiguredSpeakers: number;
  multicastPortsUsedByAudioBroadcasts: number;
  failoverInfo: {
    role: FusionConfigurationFailoverRole;
    failoverState?: FusionConfigurationFailoverState;
    cloudState?: FusionConfigurationFailoverCloudState;
    ipAddress: string;
  };
  nextLDAPRefreshLocal: string;
  phoneRebuildAttemptTimeLocal: string;
  phoneRebuildAttemptTimeGMT: string;
  version: string;
  nextLDAPRefreshGMT: string;
  clusters: Array<{
    provider: string;
    description: string;
    providerIsSecure: boolean;
    version: any;
    primary: boolean;
  }>;
  phoneRebuildNextTimeLocal: string;
  phonesUsedCount: number;
  phoneRefreshSuccessTimeLocal: string;
  callingTerminals: FusionConfigurationCtiPort[];
  ldapGroupingEnabled: boolean;
}

export interface SipAccountInfo {
  registrationName: string;
  addressOfRecord: string;
  enabled: boolean | null;
  registered: boolean;
  registrar: string;
  registrationInterval: number;
  registrationFailureLog: {
    timestamp: string;
    code:
      | 'CONNECTION_FAILURE'
      | 'UNTRUSTED_CERT'
      | 'AUTH_FAILURE'
      | 'MISSING_CREDENTIALS'
      | 'TRANSACTION_TIMEOUT'
      | 'SECURITY_POLICY_REJECTION'
      | 'DNS_FAILURE'
      | 'UNKNOWN';
    message: string;
  } | null;
}

export interface MobileApiCTICertificate extends MobileApiResourcePermissions {
  id: string;
  applicationUserName: string;
  applicationInstanceId: string;
  authenticationString: string;
  capfAddress: string;
  capfPort: number;
  tftpAddress: string;
  tftpPort: number;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiInstalledCTICertificate extends MobileApiResourcePermissions {
  id: string;
  clusterDescription: string;
  applicationUserName: string;
  applicationInstanceId: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface CiscoSparkSpaceDataEnvelop<T> extends MobileApiPageEnvelope<T> {
  userLacksSparkSession: boolean;
  paginationData: any;
}

export interface CiscoSparkSpace {
  botIsMember: boolean;
  botWasEvicted: boolean;
  created: string;
  creatorId: string;
  id: string;
  isLocked: boolean;
  lastActivity: string;
  ownerId: string;
  title: string;
  type: string;
  userCanInvite: boolean;
}
export interface MobileApiCucm {
  id: string;
  clusterName: string;
  cmAddress: string;
  primary: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiCucmCluster {
  name: string;
  secureConnection: boolean;
  snmpAuthPassword: string | null;
  snmpUsername: string;
  tftpPort: number;
  capfAddress: string;
  snmpEncryptPassword: string | null;
  axlUser: string;
  id: string;
  tftpAddress: string;
  snmpCommunity: string;
  axlPassword: string | null;
  snmpVersion: string;
  nextAuthenticationUrl: string;
  capfPort: number;
  axlAddress: string;
  cucmClusterFusionServers: Array<{
    cucmClusterFusionServerId: string;
    endpointId: string;
    endpointName: string;
    makeCtiConnection: boolean;
    isPhoneGatherer: boolean;
  }>;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  syncState?: MobileApiSyncState[];
  endpointSyncState?: MobileApiSyncState;
}

export interface MobileApiCUCMFusionServerEndpoint {
  id: string;
  name: string;
  failoverType: MobileApiIcopServerFailoverTypeEnum;
}

export interface MobileApiCucmFusionServer {
  id: string;
  cucmClusterId: string;
  endpointId: string;
  endpoint: MobileApiCUCMFusionServerEndpoint;
  cmAddress: string;
  cmUsername: string;
  cmPassword?: string | null;
  jtapiPhoneActivationEnabled: boolean;
  jtapiStaticTerminalsEnabled: boolean;
  syncId: string;
  makeCtiConnection: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  syncState?: MobileApiSyncState[];
  isPhoneGatherer: boolean;
}

export interface MobileApiIcopCucmCluster {
  secureConnection: boolean;
  jtapiPhoneActivationEnabled: boolean;
  jtapiStaticTerminalsEnabled: boolean;
  clusterName: string;
  applicationUserName: string;
  snmpAuthPassword: string;
  primary: boolean;
  snmpUserName: string;
  xmlPushAuthUrl: string;
  tftpPort: number;
  capfAddress: string;
  cmAddress: string;
  axlUserName: string;
  snmpPrivacyPassword: string;
  id: string;
  tftpAddress: string;
  snmpCommunityName: string;
  nextAuthUrlDefault: string;
  axlUserPassword: string;
  snmpVersion: number;
  nextAuthUrl: string;
  capfPort: number;
  axlAddress: string;
  applicationUserHasAxlAccess: boolean;
  applicationUserPassword: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiWebUrl {
  primary: string;
  secondary?: string;
}

export interface MobileApiKeepAlive {
  date: string;
  remoteAddress: string;
}

export interface MobileApiPhoneUpdateOverview {
  lastAttemptedPhoneRebuild: string;
  lastAttemptedPhoneRefresh: string;
  lastSuccessfulPhoneRebuild: string;
  lastSuccessfulPhoneRefresh: string;
  nextPhoneRebuild: string;
  phoneCount: number;
  phoneRefreshInterval: number;
}

export interface MobileApiClusterTrustInfo {
  certAlias: string;
  certThumbprint: string;
  trusted: boolean;
}

export interface MobileApiAutoImportingCertificates {
  isAutoImportingCertificatesEnabled: boolean;
}

export interface MobileApiDialingConfig {
  id: number;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  recipientGroupsJson: Array<{ id: number; name: string; tag: string }>;
  recipientGroups: number[];
  messageId: number;
  authType: number;
  authPin: string;
  dialingPatternRegEx: string;
  messageDescription: string;
  recipientGroupName: string;
  messageJson: {
    description: string;
    id: number;
  };
}

export interface MobileApiActionResourceEntry {
  id: string;
  name: string;
}

export interface MobileApiActionResource {
  type: string;
  resource: MobileApiActionResourceEntry;
  children?: MobileApiActionResource[];
}

export interface MobileApiSendScenarioAction {
  scenarioId: string;
  scenario?: Partial<MobileApiScenario>;
  locationResolutionStrategy?: ['location' | 'device'];
  siteId?: string;
  buildingId?: string;
  floorId?: string;
  zoneId?: string;
  fields?: MobileApiScenarioLauncherField[];
}

export enum MobileApiActionV2Type {
  SendScenarioNotification = 'send-scenario-notification',
}

export interface MobileApiActionV2 {
  type: MobileApiActionV2Type;
  sendScenarioNotification: MobileApiSendScenarioAction;
}

export interface MobileApiActionV2Wrapper {
  action: MobileApiActionV2;
  actionResources: MobileApiActionResource[];
}

export enum MobileApiDialingConfigType {
  // dialcast classic
  Notification = 'NOTIFICATION',
  // dialcast that send scenarios
  ActionsWithFallbackNotification = 'ACTIONS_WITH_FALLBACK_NOTIFICATION',
}

export interface MobileApiAuthentication {
  type: 'PIN' | 'NONE';
  pin?: string;
}

export interface MobileApiDialcastFallbackNotification {
  messageTemplateId?: string;
  distributionListIds?: string[];
  distributionLists?: MobileApiDistributionList[];
  deviceGroupIds?: string[];
  deviceGroups?: MobileApiDeviceGroup[];
  messageTemplate?: { id: string; name: string }[];
}

export interface MobileApiDialcastNotification {
  messageTemplateNamePattern?: string;
  recipientNamePattern?: string;
  messageTemplateId?: string;
  distributionListIds?: string[];
  distributionLists?: { id: string; name: string }[];
  deviceGroupIds?: string[];
  deviceGroups?: { id: string; name: string }[];
  messageTemplate?: { id: string; name: string };
}

export interface MobileApiDialingConfigV2 {
  id: string;
  name?: string;
  dialingPatternRegex?: string;
  type: MobileApiDialingConfigType;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  actions?: MobileApiActionV2Wrapper[];
  notification?: MobileApiDialcastNotification;
  fallbackNotification?: MobileApiDialcastFallbackNotification;
  authentication?: MobileApiAuthentication | null;
  endpointIds?: string[];
  endpoints?: { id: string; name: string }[];
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileApiPhoneException {
  id: string;
  dnRegEx: string;
  authOverrideEnable: boolean;
  systemGreetingOverrideEnable: boolean;
  systemGreetingEnable: boolean;
  systemGreetingBreakKeyEnable: boolean;
  authType: number;
  authPin: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiPhoneExceptionV2 {
  id: string;
  name: string;
  callingPartyRegex?: string;
  endpointIds?: string[];
  endpoints: { id: string; name: string }[];
  authenticationOverrideEnabled: boolean;
  authentication?: MobileApiAuthentication | null;
  systemGreetingOverrideEnable: boolean;
  systemGreetingEnable: boolean;
  systemGreetingBreakKeyEnable: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export type MobileApiPolyViolationReportType =
  | 'OBI_PAGE_GROUPS_EXCEEDED'
  | 'UCS_PAGE_GROUPS_EXCEEDED'
  | 'UCS_MULTICAST_CONFLICT';

export interface MobileApiPolyViolationReportEntry {
  icopId: string;
  deviceGroupIds: string[];
  violations: MobileApiPolyViolationReportType[];
  deviceGroups: MobileApiDeviceGroup[];
  device: MobileApiDevice;
}

export interface MobileApiScenarioLauncher extends MobileApiResourcePermissions {
  id: string;
  name: string;
  scenarioId: string;
  source: 'cisco-enterprise' | 'zoom' | 'kontaktio-wearable-panic-button';
  scenario?: MobileApiScenario;
  secret: string;
  confirmationType: 'none' | 'pin' | 'prompt';
  confirmationPin?: string;
  createdAt: string;
  fields?: MobileApiScenarioLauncherField[];
  eventType: 'none' | 'zoom-emergency-call' | 'red-button-click' | 'blue-button-click';
}

export interface MobileApiScenarioLauncherField {
  fieldId: string;
  answer?: string;
  dynamic?: boolean;
}

export type MulticastType = 'none' | 'gms';

export interface PolyChannelInfo {
  activationGroupId: string;
  deviceGroupId: string;
  channel: number;
  deviceGroupName: string;
}

export interface MobileApiActivationGroup extends MobileApiResourcePermissions {
  id: string;
  name: string;
  endpointIds: string[];
  priority: number;
  filterType: FilterType;
  logicalExpression?: string;
  additionIds: string[];
  additions: MobileApiDevice[];
  exclusions: MobileApiDevice[];
  exclusionIds: string[];
  filters: MobileApiDeviceGroupFilter[];
  deviceSummary?: { total: number };
  endpoints: Array<{ name: string; id: string }>;
  multicastType?: MulticastType;
  multicastAddress?: string;
  multicastPort?: number;
  usedChannels?: PolyChannelInfo[];
}

export interface MobileApiBulkUpload {
  id: string;
  resourceType: 'message-templates' | 'speakers' | 'device-groups' | 'dialing-configurations';
  createdAt: string;
  status: 'PENDING' | 'RUNNING' | 'COMPLETE' | 'ERROR';
  endpointId?: string;
  testMode: boolean;
  directoryId: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiBulkUploadLog {
  id: string;
  bulkUploadId: string;
  createdAt: string;
  resourceCreated: boolean;
  resourceName: string;
  resourceResponse: string;
}

export interface MulticastConfig {
  multicastType: MulticastType;
  multicastAddress: string;
  multicastPort: number;
}

export interface MobileApiM2MSchedule {
  id: number;
  name: string;
  description: string;
  listeningFromTime: string;
  listeningToTime: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  times: string;
  daysOfWeek: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiInputPort {
  id: number | string;
  name: string;
  indicator: string;
  message: { description: string; id: number | string };
  recipientGroups: any[];
  oid: string;
  oidValue: string;
  messageId: number | string;
  deviceGroups: any[];
  deviceGroupsJson: any[];
  distributionLists: any[];
  distributionListsJson: any[];
  permissions: MobileApiResourceUpdatePermissionMethod[];
  schedule?: { name: string; id: number };
}

export interface MobileApiOutputPort {
  id: number;
  name: string;
  indicator: string;
  oid: string;
  oidValue: string;
  oidFieldType: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  schedule?: { name: string; id: number };
}

export interface MobileApiM2MContactClosure {
  id: number;
  name: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  description: string;
  snmpCommunity: string;
  nbrOfOutputPorts: number;
  ipAddress: string;
  nbrOfInputPorts: number;
  inputPorts: MobileApiInputPort[];
  outputPorts: MobileApiOutputPort[];
}

export interface MobileApiRSSFeed {
  id: string;
  name: string;
  url: string;
  messageTemplateId: string;
  distributionListIds: string[] | null;
  disabled: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiInboundEmail {
  createdAt: string;
  disabled: boolean;
  id: string;
  name: string;
  outboundRules: Array<Partial<MobileApiInboundEmailOutboundRule>>;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  securityLevel: string;
  sourceWhitelist: string[];
  tag: string;
}

export interface MobileApiInboundEmailOutboundRule {
  bodyCriteria: any[];
  disabled: boolean;
  distributionListIds: string[];
  id: string;
  messageTemplateId: string;
  name: string;
  subjectCriteria: any[];
  userIds: string[];
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiScheduledNotification {
  timeZone: string;
  schedule: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  messageTemplateId: string;
  nextFireTime: string;
  disabled: boolean;
  messageTemplate: Partial<MobileApiMessageTemplate>;
  name: string;
  createdAt: string;
  distributionListIds: string[];
  deviceGroupIds: string[];
  syncId: string;
  id: string;
  distributionLists: Array<Partial<MobileApiDistributionList>>;
  deviceGroups: Array<Partial<MobileApiDeviceGroup>>;
  syncState?: MobileApiSyncState[];
  endpointSyncState?: MobileApiSyncState | null;
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileApiLPIConfiguration {
  customTestToneDurationMs: number;
  macAddressPrefix: string;
  pingInterval: number;
  requestWaitPeriod: number;
  tcpConnectionTimeout: number;
  transportProtocol: string;
  useCustomTestTone: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiLPIActiveBroadcast {
  startDate: string;
  broadcastSenderUrl: string;
  audioStreamAddress: string;
  audioStreamPort: string;
  activePagingDevices: Array<{
    name: string;
    activePagingZones: Array<{ name: string; macAddress: string }>;
  }>;
}

export interface MobileApiLPIActiveTest {
  name: string;
  activePagingZones: Array<{ name: string; macAddress: string; id: number }>;
}

export interface MobileApiLPICurrentActivities {
  activeBroadcasts: MobileApiLPIActiveBroadcast[];
  activeTests: MobileApiLPIActiveTest[];
}

export interface MobileApiLPIServerGroup {
  id: number;
  name: string;
  scheme: string;
  sipServers: Array<{ id: number; host: string; port: number }>;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiLPIPagingDevice {
  dtmfDuration: number;
  dtmfPauseAfter: number;
  dtmfPauseBefore: number;
  dtmfPauseBetween: number;
  id: number;
  maxWaitTime: number;
  name: string;
  pagingZones: any[];
  sipServerGroup: { name: string; id: number };
  sipServerGroupId: number;
  user: any;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiNightBellConfiguration {
  maxDuration: number;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiNightBellDiagnostics {
  ctiProvider: string;
  ctiProviderIsSecure: boolean;
  cucmVersion: string;
  applicationUser: string;
  jtapiVersion: string;
  ctiPorts: any[];
}

export interface MobileApiNightBellTrigger {
  ctiPortDN: string;
  id: number;
  longText?: string;
  maxDuration: number;
  messageID?: number;
  messageId?: string;
  message?: string | { description: string; id: string };
  messageJson: { isCustomizable: boolean; description: string; id: number; type: string };
  name: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  recipientGroupsJson: any[];
  recipientGroups?: string[] | Array<{ name: string; id: string }>;
  recipientIDs: string;
  shortText?: string;
}

export interface MobileApiNightBellCUCM {
  id: number;
  clusterRole: string;
  icIpAddress: string;
  applicationUser: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiInboundCAPRule {
  createdAt: string;
  disabled: boolean;
  expression: string;
  id: string;
  languageCode: string;
  messageTemplate: MobileApiMessageTemplate;
  messageTemplateId: string;
  name: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiBranding {
  primaryColor: string | null;
  secondaryColor: string | null;
  companyName: string | null;
  companyPhone: string | null;
  companyEmail: string | null;
  logo: FileTypes;
  logoAltText: string;
  id: string;
  customUrl: string | null;
  logoImageFile?: {
    url: string;
  };
}

export interface MobileApiQuickURLDevice {
  uid: string;
  name: string;
  userName?: string;
  authPassword?: string;
  confirmPassword?: string;
  location: string;
  shortTextName: string;
  longTextName: string;
  reqMethod: 'POST' | 'GET';
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiSpeaker {
  id: string;
  name: string;
  description?: string;
  macAddress: string;
  volume: number;
  scrollSpeedAdjustment: 0 | 1 | 2 | -1;
  registrationStatusAsHtml: string;
  sipIntercomEnabled: boolean;
  sipSpeakerDn: string;
  sipSpeakerDnToDial?: string;
  sipSpeakerMicVolume?: number;
  sipSpeakerRingVolume?: number;
  sipSpeakerAutoAnswer?: boolean;
  sipSpeakerRegenAuthCredentials?: boolean;
  ipAddress: string | null;
  port: number | null;
  status: 'Not registered' | 'Registered';
  sipStatus: 'Not registered' | 'Registered';
  registrationStatus?: 'Not registered' | 'Registered';
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiSpeakerV2 extends MobileApiLocation, MobileAPISpeakerSIPConfiguration {
  id: string;
  name: string;
  description?: string;
  macAddress: string;
  volume: number;
  scrollSpeedAdjustment: number;
  ipAddress: string | null;
  port: number | null;
  sipIntercomEnabled: boolean;
  status: 'Not registered' | 'Registered';
  sipStatus: 'Not registered' | 'Registered';
  registrationStatus?: 'Not registered' | 'Registered';
  registrationData?: {
    canSendHQAudio: boolean;
    reportedIPv4Address: string;
    isConfigured: boolean;
    isRecordingSource: boolean;
    isRegistered: boolean;
    canPlayHQAudio: boolean;
    isRegisteredSip: boolean;
    endpointId: string;
    hasDisplay: boolean;
    isDesktopNotifier: boolean;
    isLicensed: boolean;
    ipAddress: string;
    deviceString: string;
    statusUrl: string;
    endpoint: {
      id: string;
      name: string;
    };
    activationGroupId: string | null;
    canListen: boolean;
  };
  permissions: MobileApiResourceUpdatePermissionMethod[];
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileAPISpeakkerV2Job {
  id: string;
  status: string;
  result: { errors: Record<string, { errorCode: string; errorMessage: string }> };
}

export interface MobileAPISpeakerSIPConfiguration {
  id: string;
  endpointId: string;
  endpoint: {
    id: string;
    name: string;
  };
  sipIntercomEnabled: boolean;
  sipSpeakerDn: string;
  sipSpeakerDnToDial?: string;
  sipSpeakerMicVolume?: number;
  sipSpeakerRingVolume?: number;
  sipSpeakerAutoAnswer?: boolean;
  sipSpeakerRegenAuthCredentials?: boolean;
}

export interface MobileApiDesktopNotifier {
  id: string;
  name: string;
  description?: string;
  macAddress: string;
  volume: number;
  scrollSpeedAdjustment: 0 | 1 | 2 | -1;
  registrationStatusAsHtml: string;
  registrationStatus: string;
  ipAddress: string | null;
  port: number | null;
  status: 'Not registered' | 'Registered';
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiSpeakerStatistics {
  numRegisteredDesktopNotifier: number;
  numNewDesktopNotifier: number;
  numRegistered: number;
  numNew: number;
  numExpiredDesktopNotifier: number;
  numNotRegisteredDesktopNotifier: number;
  numExpired: number;
  numNotRegistered: number;
}

export interface MobileApiRoutePoint {
  terminal: string;
  address: string;
  state: string;
  calls: Array<{ callId: number; callingAddress: string; calledAddress: string }>;
}

export interface MobileApiCTIPort {
  name: string;
  state: string;
  registeredAddress: string;
  registeredPort: string;
  delete: string;
  dn: string;
  calls: Array<{ callId: number; callingAddress: string; calledAddress: string }>;
  userDescription: string;
}

export interface MobileApiCallingTerminalDiagnostic {
  ctiPorts: MobileApiCTIPort[];
  routePoints: MobileApiRoutePoint[];
}

export interface MobileApiBroadcastVoiceMenuConfig {
  cancelBroadcastsFailAudioFileDurationMs: number;
  cancelBroadcastsSuccessAudioFileDurationMs: number;
  cancelBroadcastsSuccessEnable: boolean;
  dnDoesNotMapAudioFileDurationMs: number;
  dtiGoToneAudioFileDurationMs: number;
  dtiGoToneEnable: boolean;
  linkCancelBroadcastsFailAudioFile: string;
  linkCancelBroadcastsSuccessAudioFile: string;
  linkDnDoesNotMapAudioFile: string;
  linkDtiGoToneAudioFile: string;
  linkTriggerFailAudioFile: string;
  linkTriggerSuccessAudioFile: string;
  triggerFailAudioFileDurationMs: number;
  triggerSuccessAudioFileDurationMs: number;
  triggerSuccessEnable: boolean;
}

export interface MobileApiGeneralVoiceMenuConfig {
  adHocAcceptCharacter: string;
  adHocCancelCharacter: string;
  audioGoToneDurationMs: number;
  audioNoRecipientsDurationMs: number;
  audioRecordingPromptDurationMs: number;
  audioStallToneDurationMs: number;
  linkAudioGoTone: string;
  linkAudioNoRecipients: string;
  linkAudioRecordingPrompt: string;
  linkAudioStallTone: string;
  liveStallToneInterval: number;
}

export interface MobileApiInboundVoiceMenuConfig {
  authFailAudioFileDurationMs: number;
  authFixedLengthInput: number;
  authGreetingAudioFileDurationMs: number;
  authGreetingEnable: boolean;
  authInputMethod: 1 | 0;
  authLoginPromptAudioFileDurationMs: number;
  authMaxFails: number;
  authMaxPrompts: number;
  authPasswordPromptAudioFileDurationMs: number;
  authPinPromptAudioFileDurationMs: number;
  authSuccessAudioFileDurationMs: number;
  authSuccessEnable: true;
  authVariableLengthInput: string;
  dialCastOptionIVRFixedLengthInput: number;
  dialCastOptionIVRInputMethod: 1 | 0;
  dialCastOptionIVRMaxFails: number;
  dialCastOptionIVRMaxPrompts: number;
  dialCastOptionIVRNotRecognizedAudioFileDurationMs: number;
  dialCastOptionIVRPromptAudioFileDurationMs: number;
  dialCastOptionIVRVariableLengthInput: string;
  dnNotConfiguredAudioFileDurationMs: number;
  dtmfBreakKey: string;
  dtmfInterDigitTimeout: number;
  dtmfTimeout: number;
  invalidLicenseAudioFileDurationMs: number;
  linkAuthFailAudioFile: string;
  linkAuthGreetingAudioFile: string;
  linkAuthLoginPromptAudioFile: string;
  linkAuthPasswordPromptAudioFile: string;
  linkAuthPinPromptAudioFile: string;
  linkAuthSuccessAudioFile: string;
  linkDialCastOptionIVRNotRecognizedAudioFile: string;
  linkDialCastOptionIVRPromptAudioFile: string;
  linkDnNotConfiguredAudioFile: string;
  linkInvalidLicenseAudioFile: string;
  linkSystemGreetingAudioFile: string;
  linkUcaasIntercomDnNotRecognizedAudioFile: string;
  linkUcaasIntercomDnPromptAudioFile: string;
  systemGreetingAudioFileDurationMs: number;
  systemGreetingBreakKeyEnable: boolean;
  systemGreetingEnable: boolean;
  ucaasIntercomDnNotRecognizedAudioFileDurationMs: number;
  ucaasIntercomDnPromptAudioFileDurationMs: number;
  ucaasIntercomMaxFails: number;
}

export interface MobileApiPagingGateway {
  consoleLoginEnabled: boolean;
  consoleLoginRequired: boolean;
  encryptTransmittedLogs: boolean;
  encryptUnicastAudio: boolean;
  id: string;
  name: string;
  ipAddress: string;
  macAddress: string;
  slpEnabled: boolean;
  sshEnabled: boolean;
  status: MobileApiPagingGatewayStatus;
  statusDetail: string;
  useGlobalConfig: boolean;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiPagingGatewayPassword {
  password: string;
}

export interface MobileApiPagingGatewayGlobalStatus {
  activated: boolean;
}

export interface MobileApiPagingGatewayMulticastAddresses {
  gmsAddresses: string[];
}

export enum MobileApiPagingGatewayStatus {
  Unregistered = 'Unregistered',
  Registered = 'Registered',
  Pending = 'Pending',
  Resetting = 'Resetting',
  ResetPending = 'Reset Pending',
}

export enum MobileApiPagingGatewayEventStatus {
  Processing = 'PROCESSING',
  Complete = 'COMPLETE',
  Failure = 'FAILURE',
}

export interface MobileApiPagingGatewayEvent {
  id: string;
  pgName: string;
  pgMac: string;
  requestType: 'LOGS';
  initiator: string;
  status: MobileApiPagingGatewayEventStatus;
  details: string;
  timeRequested: string;
  lastUpdated: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
}

export interface MobileApiTrackingEventUserActivity {
  deviceId: string;
  lat: number;
  lng: number;
  mostRecent: string;
  state: 'started' | 'updated' | 'completed' | 'permission-denied' | 'terminated' | 'logged-out';
  userId: string;
  userName: string;
  phoneNumber: string | null;
}

export interface MobileApiTrackingEvent {
  id: string;
  completedAt: string | null;
  updatedAt: string | null;
  name: string;
  state: 'active' | 'completed';
  createdAt: string;
  notificationId: string;
  initiator: {
    id: string | null;
    name: string | null;
    type: MobileApiUserType | null;
    email: string | null;
  };
  initiatorId: string | null;
  scenario: {
    id: string;
    name: string;
  } | null;
  confirmationRequest: {
    id: string;
    name: string;
  } | null;
  deviceId: string | null;
  summary: MobileApiTrackingEventUserActivity[] | null;
}

export interface MobileApiTrackingEventActivity {
  id: string;
  deviceId: string;
  createdAt: string;
  state: string;
  trackingEvent: {
    id: string;
    state: string;
  };
  geometryValue: {
    geometry: {
      coordinates: [number, number];
    };
    properties: {
      accuracyInM: number;
    };
  };
}

export interface MobileApiTrackingEventNotification {
  createdAt: string;
  distributionListIds: string[];
  distributionLists: MobileApiDistributionList[];
  geometryType: string;
  geometryValue: { type: string; geometry: { type: string; coordinates: [number, number] } };
  id: string;
  latLonIgnore: boolean;
  messageTemplate: MobileApiMessageTemplate;
  messageTemplateId: string;
  name: string;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  userIds: string[];
  users: MobileApiUser[];
}

export interface MobileApiIdp extends MobileApiResourcePermissions {
  id: string;
  name: string;
  isDefault: boolean;
  selfRegistrationEnabled: boolean;
  securityGroupId: string | null;
  securityGroup: { name: string; id: string } | null;
  distributionListId: string | null;
  distributionList: { name: string; id: string } | null;
  type: string;
  enabled: boolean;
  createdAt: string;
}

export interface MobileApiIncidentResource extends MobileApiResourcePermissions {
  contentType: string | null;
  fileName: string | null;
  hyperlink: string | null;
  id: string;
  name: string;
  size: number | null;
  type: 'hyperlink' | 'file';
}

// TODO: consider refactoring to have VA and IC rostering data under their own keys instead of using 'type'
export interface MobileApiIncidentRostering {
  locations: string[];
  statuses: string[];
  visitorAwareUrl?: string | null;
  visitorAwareBaseUrl?: string;
  type?: RosteringType;
}

export type MobileApiIncidentEmergencyCallingStatus =
  | 'rapid-sos-initiated'
  | 'rapid-sos-no-response'
  | 'rapid-sos-confirmed'
  | 'rapid-sos-declined'
  | 'rapid-sos-contacting-monitoring'
  | 'rapid-sos-cancelled'
  | 'rapid-sos-completed'
  | 'rapid-sos-unrecognized-status'
  | 'rapid-sos-failed';

export interface MobileApiIncident {
  id: string;
  incidentPlan: MobileApiIncidentPlan;
  createdAt: string;
  endedAt: string | null;
  resources?: MobileApiIncidentResource[];
  messageTemplateId: string | null;
  messageTemplate: { name: string } | null;
  notificationId: string | null;
  notificationCreatedAt: string | null;
  notificationCount?: number;
  scenario: MobileApiScenario | null;
  site?: MobileApiSite;
  permissions: MobileApiResourceUpdatePermissionMethod[];
  rostering?: MobileApiIncidentRostering | null;
  emergencyCallingStatus?: {
    type: MobileApiIncidentEmergencyCallingStatus;
    details: {
      detail: string;
      timestamp: number;
    };
    createdAt: string;
  };
  facilityId?: string;
  facility?: { id: string; name: string; siteId: string };
}

export interface MobileApiIncidentRosteringReport {
  statusCounts: Array<{ status: string; count: number }>;
}

export interface ZoomStandardIntegrationScenarios {
  emergencyCall?: string;
}

export interface ZoomFeatureReauthorization {
  zoomSites: boolean;
}

export interface ZoomAppStatus {
  disconnected: boolean;
  installed: boolean;
  installLink: string;
  reauthorize: ZoomFeatureReauthorization;
  standardIntegrationScenarios: ZoomStandardIntegrationScenarios;
}

export interface ExternalSite {
  name: string;
  source: string;
  siteId?: string;
  externalId?: string;
  imported: boolean;
  existing: boolean;
  importing?: boolean;
}

export interface SitesManagementStatus {
  import: {
    zoom: { imported: boolean; importDate?: string };
    webexCalling: { imported: boolean; importDate?: string };
  };
}

export interface MobileApiAlarmThresholds {
  id: string;
  alarmId: string;
  endpointId?: string;
  threshold: number;
}

export type MobileApiAlarmType =
  | 'fusion_server_red'
  | 'fusion_server_yellow'
  | 'speakers_unregistered'
  | 'license_violation'
  | 'phone_cache_update_failed'
  | 'sis_loader_failed'
  | 'fusion_delta_sync_error_event'
  | 'webhook_disabled'
  | 'token_expiration'
  | 'scenario_device_resolution_failed'
  | 'potential_speaker_configuration_issue'
  | 'ip_cameras_service_issue'
  | 'webex_calling_integration_failure';

export type MobileApiAlarmStatus = 'OK' | 'ACTIVE';

export type AlarmId = (typeof constant.AllAlarmIds)[number];

export interface MobileApiAlarm {
  id: AlarmId;
  alarmActionIds?: string[];
  alarmThresholds?: MobileApiAlarmThresholds[];
  status: MobileApiAlarmStatus;
  muted: boolean;
  type: MobileApiAlarmType;
  updatedAt: string;
}

export interface MobileApiAlarmAction {
  id: string;
  triggerOnStatus?: string;
  messageTemplateId?: string;
  areaOfInterestIds?: string;
  collaborationGroupIds?: string;
  deviceGroupIds?: string;
  distributionListIds?: string;
  userIds?: string;
}

export interface MobileApiSystemHealthAPIDeviceFailureEvent {
  externalId?: string;
  scenarioId?: string;
  scenarioName?: string;
  timestamp?: string;
  reasons?: string[];
}

export interface MobileApiPotentialSpeakerConfigurationIssueEvent {
  id: string;
  name: string;
  deviceType: 'speaker' | 'idn';
  macAddress: string;
  reportedNames?: null | string[];
  reportedEndpoints?: null | Array<{ id: string; name?: string | null }>;
  timestamp?: string;
}

export interface MobileApiSystemHealthEvent<T = any> {
  id: string;
  alarmId: AlarmId;
  createdAt: string;
  status: MobileApiAlarmStatus;
  reasons?: string[];
  endpointId?: string;
  endpointName?: string;
  event?: T;
}

export interface MobileApiMemberUser {
  id: MobileApiUser['id'];
  name: MobileApiUser['name'];
  email: MobileApiUser['email'];
}

export interface MobileApiRosterMember {
  memberUser: MobileApiMemberUser;
  status: string | null;
  location: string | null;
  incidentId: MobileApiIncident['id'];
}

export interface MobileApiClearDevicesSchedule extends MobileApiResourcePermissions {
  id: string;
  name: string;
  createdAt: string;
  cronExpression: string;
}

// Menu element types
type Icons =
  | { imageSrc: string }
  | { icon: ZmdiIcon | 'brightness-2' | 'tap-and-play' | 'zoom' | 'group' | 'leak' | 'ungroup' };

type Permission =
  | ApiRequest
  | [ApiRequestPath, [], ApiRequestMethod, SinglewireHttpRequestOptions?];

type MenuElementChild = Icons & {
  title: string;
  routerLink?: string;
  id?: string;
  permission?: Permission | Permission[];
  permissionQuery?: (store: Store<RootState>) => Observable<boolean>;
  children?: MenuElementChild[];
  spotlight?: {
    title: string;
    subtitle: string;
  };
  meta?: {
    featuresRequired?: Feature[];
    hideWhenFeaturesEnabled?: Feature[];
    icopFeaturesRequired?: FusionServerFeature[];
    hideWhenIcopFeatures?: FusionServerFeature[];
    fusionRequired?: boolean;
    featuresOr?: boolean;
    isICOPLink?: boolean;
    hideWhenCucmDisabled?: boolean;
    multipleServersRequired?: boolean;
    hideInSubdomains?: boolean;
    hideOutsideGlobalFacility?: boolean;
    localFacilityTitle?: string;
    hideWhenModelessNotSupportedCucmEnabled?: boolean;
    bulkSpeakerManagementControlled?: 'show' | 'hide';
    cloudDialcastControlled?: 'show' | 'hide';
    domainsMustBeConfigurable?: boolean;
    permissionsOr?: boolean;
  };
};

export type MenuElement = {
  title: string;
  routerLink?: string;
  id?: string;
  icon: ZmdiIcon;
  children?: MenuElementChild[];
  spotlight?: {
    title: string;
    subtitle: string;
  };
};

export interface MobileApiIpCamera extends MobileApiResourcePermissions, MobileApiLocation {
  id: string;
  externalId: string;
  name: string;
  createdAt: string;
  vendorConfigId: string;
  externalDetails: {
    location: string;
    mac: string;
    site: string;
    model: string;
  };
}

export interface MobileApiIpCameraFeed {
  urlType: 'HLS';
  url: string;
}

export type MobileApiVerkadaConfigScope = 'read-only' | 'live-streaming';
export type MobileApiVerkadaConfigRegion = 'US';
export type MobileApiVerkadaApiKey = { key?: string; scopes: MobileApiVerkadaConfigScope[] };

export type VerkadaConfig = {
  region: MobileApiVerkadaConfigRegion;
  apiKeys?: MobileApiVerkadaApiKey[];
  organizationId: string;
};

type CameraVendorConfig = VerkadaConfig;

export type MobileApiIpCameraVendorConfig = {
  id?: string;
  cameraVendorId: string;
  config: CameraVendorConfig;
};

export type Existing<T> = T & { id: string };

export type MobileApiIpCameraJob = {
  id?: string;
  vendorConfigId: string;
  type: 'SYNC';
  status: 'SUCCESS' | 'QUEUED' | 'RUNNING' | 'FAILED';
  createdAt: string;
  updatedAt: string;
};
