// This file contains the data structure we use to generate the left side-bar

import concat from 'lodash/concat';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import some from 'lodash/some';
import { Store } from 'redux';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiRequest, ZmdiIcon } from '../../common-types';
import {
  CURRI_FEATURE,
  DIAL_TO_INTERCOM_FEATURE,
  DISTRIBUTED_ACTIVATION_FEATURE,
  DNS_SRV_FEATURE,
  GENERAL_COUNTS_REPORT_API_ID,
  GENERIC_MULTICAST_STREAMING_FEATURE,
  GLOBAL_FACILITY_ID,
  MODELESS_INFORMACAST,
  PROVIDER_KEY_ROTATION,
  ROOT_DOMAIN_ID,
  SIP_ACCOUNTS_FEATURE,
  STYLE_SUPPORT_FEATURE,
} from '../../constants';
import {
  Feature,
  MenuElement,
  MobileApiIcopServerFailoverTypeEnum,
  MobileApiMessageTemplate,
  MobileApiPageEnvelope,
} from '../../mobile-api-types';
import { core_t } from '../CoreLocale';
import { RootState } from '../RootReducer';
import { spotlight_t } from '../SpotlightLocale';
import Routes from '../route/Routes';
import { BackgroundRef, req } from './api';
import { isFeatureSupportedForServer } from './icop-server';
import log from './log';
import { cucmCloudClustersFeatureEnabled, hasPermission } from './permissions';
import { isFeatureEnabled, isServerFeatureSupported } from './session';

export interface ElementMetaData {
  fusionRequired?: boolean;
  isICOPLink?: boolean;
  multipleServersRequired?: boolean;
  hideWhenCucmDisabled?: boolean;
  showInGms?: boolean;
  domainsMustBeEnabled?: boolean;
  featuresRequired?: Feature[];
  hideWhenFeaturesEnabled?: Feature[];
  icopFeaturesRequired?: string[];
  hideWhenIcopFeatures?: string[];
  domainsMustBeConfigurable?: boolean;
  hideInSubdomains?: boolean;
  hideOutsideGlobalFacility?: boolean;
  localFacilityTitle?: string;
  permissionsOr?: boolean;
  featuresOr?: boolean;
  hideWhenModelessNotSupportedCucmEnabled?: boolean;
  bulkSpeakerManagementControlled?: 'show' | 'hide';
  cloudDialcastControlled?: 'show' | 'hide';
}

export interface ElementLink {
  title: string;
  routerLink: string;
  icon: ZmdiIcon;
  imageSrc?: string;
  isActive?: boolean;
  permission?: ApiRequest | ApiRequest[];
  permissionQuery?: (store: Store<RootState>) => Observable<boolean>;
  meta?: ElementMetaData;
}

export interface ElementMenu {
  title: string;
  id: string;
  icon: ZmdiIcon;
  imageSrc?: string;
  meta?: ElementMetaData;
  isExpanded?: boolean;
  children: any[]; // TS Can't deal with recursive types unfortunately. Should be an array of ElementLink | ElementMenu
}

/**
 * Given an element link, check to see if it should be shown. If we are in the wrong modes, don't have the correct features,
 * or don't have the correct permissions, this will return a promise resolving false.
 * @param {ElementLink} element the element link
 * @param {Store<RootState>} store the state observable
 * @return {Promise<boolean>} a promise which will indicate if the element link should be shown
 */
export function shouldShowElementLink(
  element: ElementLink,
  store: Store<RootState>
): Promise<boolean> {
  const state = store.getState();
  const currentSession = state.session;

  const selectedIcopServer = state.icopServers.selectedICOPServer;
  const servers = state.icopServers.servers;

  if (!currentSession) {
    return Promise.resolve(false);
  }

  const fusionMode = isFeatureEnabled('fusion', currentSession);
  const cucmEnabled = isFeatureEnabled('cucmEnabled', currentSession);
  const multipleServersAvailable =
    size(
      servers.filter(
        s =>
          s.failoverType === MobileApiIcopServerFailoverTypeEnum.Primary ||
          s.failoverType === MobileApiIcopServerFailoverTypeEnum.Standalone
      )
    ) > 1;
  const domainsAvailable = currentSession.isDomainsAvailable;
  const actingDomainId = state.domains.actingDomainId;
  const actingFacilityId = state.facilities.actingFacilityId;

  if (element.meta) {
    const {
      fusionRequired,
      isICOPLink,
      multipleServersRequired,
      featuresRequired,
      hideWhenFeaturesEnabled,
      icopFeaturesRequired,
      hideWhenIcopFeatures,
      hideWhenCucmDisabled,
      domainsMustBeConfigurable,
      hideInSubdomains,
      hideOutsideGlobalFacility,
      featuresOr,
      hideWhenModelessNotSupportedCucmEnabled,
      bulkSpeakerManagementControlled,
      cloudDialcastControlled,
    } = element.meta;

    if (
      bulkSpeakerManagementControlled &&
      (state.icopServers.speakerMigrationStatus === 'some' ||
        (bulkSpeakerManagementControlled === 'show' &&
          state.icopServers.speakerMigrationStatus !== 'all') ||
        (bulkSpeakerManagementControlled === 'hide' &&
          state.icopServers.speakerMigrationStatus === 'all'))
    ) {
      return Promise.resolve(false);
    }

    if (
      cloudDialcastControlled &&
      (state.icopServers.dialcastMigrationStatus === 'some' ||
        (cloudDialcastControlled === 'show' &&
          state.icopServers.dialcastMigrationStatus !== 'all') ||
        (cloudDialcastControlled === 'hide' && state.icopServers.dialcastMigrationStatus === 'all'))
    ) {
      return Promise.resolve(false);
    }

    if (
      hideWhenModelessNotSupportedCucmEnabled &&
      !isFeatureSupportedForServer(MODELESS_INFORMACAST, selectedIcopServer) &&
      cucmEnabled
    ) {
      return Promise.resolve(false);
    }

    // One or more required features is missing from the session object
    if (featuresRequired) {
      const showFeature = featuresOr
        ? featuresRequired.some(feature => isFeatureEnabled(feature, currentSession))
        : featuresRequired.every(feature => isFeatureEnabled(feature, currentSession));

      if (!showFeature) {
        return Promise.resolve(false);
      }
    }

    // left nav element requires a feature to be unavailable
    // (i.e. element is inapplicable/supplanted when a certain new feature is available)
    if (hideWhenFeaturesEnabled?.some(feature => isFeatureEnabled(feature, currentSession))) {
      return Promise.resolve(false);
    }

    // Domains not yet available or not acting in root domain
    if (
      domainsMustBeConfigurable &&
      (!domainsAvailable || (actingDomainId && actingDomainId !== ROOT_DOMAIN_ID))
    ) {
      return Promise.resolve(false);
    }

    // hide in subdomains
    if (hideInSubdomains && actingDomainId && actingDomainId !== ROOT_DOMAIN_ID) {
      return Promise.resolve(false);
    }

    // hide outside global
    if (hideOutsideGlobalFacility && actingFacilityId && actingFacilityId !== GLOBAL_FACILITY_ID) {
      return Promise.resolve(false);
    }

    // wrong mode
    if (fusionRequired && !fusionMode) {
      return Promise.resolve(false);
    }

    // wrong mode
    if (isICOPLink && !selectedIcopServer) {
      return Promise.resolve(false);
    }

    // left nav element requires more than one fusion server
    if (multipleServersRequired && !multipleServersAvailable) {
      return Promise.resolve(false);
    }

    // left nav element requires a feature to be available on the fusion server
    if (
      icopFeaturesRequired &&
      !icopFeaturesRequired.every(feature =>
        isFeatureSupportedForServer(feature, selectedIcopServer)
      )
    ) {
      return Promise.resolve(false);
    }

    // left nav element requires a feature to be unavailable on the fusion server
    // (i.e. element is inapplicable/supplanted when a certain new feature is available)
    if (
      hideWhenIcopFeatures &&
      hideWhenIcopFeatures.some(feature => isFeatureSupportedForServer(feature, selectedIcopServer))
    ) {
      return Promise.resolve(false);
    }

    // element should be hidden when CUCM is dsabled
    if (hideWhenCucmDisabled && !cucmEnabled) {
      return Promise.resolve(false);
    }
  }

  //Facilities are enabled, the user is not in Global, and the element has a local title override
  if (
    actingFacilityId &&
    actingFacilityId !== GLOBAL_FACILITY_ID &&
    element.meta?.localFacilityTitle
  ) {
    element.title = element.meta?.localFacilityTitle;
  }

  if (!element.permission) {
    return Promise.resolve(true);
  } else {
    const permissionsToCheck = (
      isArray(element.permission[0]) ? element.permission : [element.permission]
    ) as ApiRequest[];
    const permissionPromises = permissionsToCheck.map(permission => {
      try {
        return hasPermission(permission, store)
          .pipe(
            catchError(e => {
              log.error('Permission check failed', permissionsToCheck, e);
              return of(false);
            })
          )
          .toPromise();
      } catch (e) {
        log.error('Permission check failed', permissionsToCheck, e);
        return Promise.resolve(false);
      }
    });
    // handle the optional extra query after first checking base permission access
    if (element.meta && element.meta.permissionsOr) {
      // if permissionsOr is true, and any of the promises return, allow access
      return Promise.all(
        element.permissionQuery
          ? concat(permissionPromises, element.permissionQuery(store).toPromise())
          : permissionPromises
      ).then(results => some(results) as boolean);
    } else {
      // if permissionsOr is false, or meta is undefined, require every permission
      return Promise.all(
        element.permissionQuery
          ? concat(permissionPromises, element.permissionQuery(store).toPromise())
          : permissionPromises
      ).then(results => every(results) as boolean);
    }
  }
}

/**
 * Returns a new representation of all our possible menu elements
 * @returns all of possible menu elements
 */

export function getMenuElements(): MenuElement[] {
  return [
    {
      title: core_t(['navigation', 'home']),
      id: 'sidebar-home',
      routerLink: Routes.Home,
      icon: 'home',
      spotlight: {
        title: spotlight_t(['home', 'title']),
        subtitle: spotlight_t(['home', 'subtitle']),
      },
    },
    {
      title: core_t(['navigation', 'recipients', 'title']),
      id: 'sub-menu-recipients',
      icon: 'account-add',
      children: [
        {
          title: core_t(['navigation', 'recipients', 'manageUsers']),
          id: 'sidebar-users',
          routerLink: Routes.Users.List,
          icon: 'accounts-alt',
          permission: ['users', [], 'GET'],
          spotlight: {
            title: spotlight_t(['users', 'title']),
            subtitle: spotlight_t(['users', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'distributionLists']),
          id: 'sidebar-distribution-lists',
          routerLink: Routes.DistributionLists.List,
          icon: 'folder-person',
          permission: ['distributionLists', [], 'GET'],
          spotlight: {
            title: spotlight_t(['distributionLists', 'title']),
            subtitle: spotlight_t(['distributionLists', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'desktopUsers', 'title']),
          id: 'sidebar-desktop-users',
          icon: 'laptop',
          routerLink: Routes.DesktopUsers.Index,
          permission: ['userDevicesBulk', [], 'GET'],
          meta: {
            featuresRequired: ['desktopPush'],
          },
          spotlight: {
            title: spotlight_t(['desktopDevices', 'title']),
            subtitle: spotlight_t(['desktopDevices', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'deviceGroups']),
          id: 'sidebar-device-groups',
          routerLink: Routes.DeviceGroups.List,
          icon: 'layers',
          permission: ['deviceGroups', [], 'GET'],
          meta: { fusionRequired: true },
          spotlight: {
            title: spotlight_t(['deviceGroups', 'title']),
            subtitle: spotlight_t(['deviceGroups', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'areasOfInterest']),
          id: 'sidebar-areas-of-interest',
          routerLink: Routes.AreasOfInterest.List,
          icon: 'pin-drop',
          permission: ['areasOfInterest', [], 'GET'],
          spotlight: {
            title: spotlight_t(['aois', 'title']),
            subtitle: spotlight_t(['aois', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'trackingEvents']),
          id: 'sidebar-tracking-events',
          routerLink: Routes.TrackingEvents.Index,
          icon: 'gps-dot',
          permission: ['trackingEvents', [], 'GET'],
          spotlight: {
            title: spotlight_t(['trackingEvents', 'title']),
            subtitle: spotlight_t(['trackingEvents', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'outboundSystems', 'title']),
          id: 'sub-menu-collaboration-groups',
          icon: 'leak',
          children: [
            {
              title: core_t(['navigation', 'recipients', 'outboundSystems', 'conferenceCall']),
              id: 'sidebar-outbound-conference-call',
              routerLink: '/conference-call',
              icon: 'smartphone-ring',
              permission: ['collaborationGroups', [], 'GET'],
              meta: {
                featuresRequired: ['conferenceCall'],
              },
              spotlight: {
                title: spotlight_t(['conferenceCalls', 'title']),
                subtitle: spotlight_t(['conferenceCalls', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'outboundSystems', 'webexTeams']),
              id: 'sidebar-outbound-ms-teams',
              routerLink: Routes.CiscoWebexTeams.List,
              icon: 'collection-text',
              permission: ['collaborationGroups', [], 'GET'],
              meta: {
                featuresRequired: ['webexTeams'],
              },
              spotlight: {
                title: spotlight_t(['webex', 'title']),
                subtitle: spotlight_t(['webex', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'outboundSystems', 'twitter']),
              id: 'sidebar-twitter',
              routerLink: '/twitter',
              icon: 'twitter',
              permission: ['collaborationGroups', [], 'GET'],
              meta: {
                featuresRequired: ['twitter'],
              },
              spotlight: {
                title: spotlight_t(['twitter', 'title']),
                subtitle: spotlight_t(['twitter', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'outboundSystems', 'outboundCap']),
              id: 'sidebar-outbound-cap',
              routerLink: Routes.OutboundCap.List,
              icon: 'remote-control',
              permission: ['collaborationGroups', [], 'GET'],
              spotlight: {
                title: spotlight_t(['outboundCap', 'title']),
                subtitle: spotlight_t(['outboundCap', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'outboundSystems', 'outboundRss']),
              id: 'sidebar-outbound-rss',
              routerLink: Routes.OutboundRss.List,
              icon: 'tap-and-play',
              permission: ['collaborationGroups', [], 'GET'],
              spotlight: {
                title: spotlight_t(['outboundRss', 'title']),
                subtitle: spotlight_t(['outboundRss', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'outboundSystems', 'teamsGroup']),
              id: 'sidebar-outbound-teams-groups',
              routerLink: Routes.TeamsGroup.Index,
              icon: 'ms-teams',
              permission: [
                ['collaborationGroups', [], 'GET'],
                ['extensionsTeamsStatus', [], 'GET'],
              ],
              meta: {
                featuresRequired: ['msteamsCall', 'msteamsChannel'],
                featuresOr: true,
              },
              spotlight: {
                title: spotlight_t(['msteams', 'title']),
                subtitle: spotlight_t(['msteams', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'recipients', 'speakers']),
          id: 'sidebar-ip-speakers',
          routerLink: Routes.IPSpeaker.List,
          icon: 'collection-speaker',
          permission: ['ipSpeakers', [], 'GET'],
          meta: {
            bulkSpeakerManagementControlled: 'hide',
            isICOPLink: true,
          },
          spotlight: {
            title: spotlight_t(['speakers', 'title']),
            subtitle: spotlight_t(['speakers', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'speakers']),
          id: 'sidebar-ip-speakers-v2',
          routerLink: Routes.IPSpeakerV2.List,
          icon: 'collection-speaker',
          permission: [['speakers', [], 'GET']],
          meta: {
            bulkSpeakerManagementControlled: 'show',
          },
          spotlight: {
            title: spotlight_t(['speakers', 'title']),
            subtitle: spotlight_t(['speakers', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'idns']),
          id: 'sidebar-idns-v2',
          routerLink: Routes.DesktopNotifierV2.List,
          icon: 'laptop',
          permission: [['speakers', [], 'GET']],
          meta: {
            bulkSpeakerManagementControlled: 'show',
            isICOPLink: true,
          },
          spotlight: {
            title: spotlight_t(['desktopNotifiers', 'title']),
            subtitle: spotlight_t(['desktopNotifiers', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'idns']),
          id: 'sidebar-idns',
          routerLink: Routes.DesktopNotifier.List,
          icon: 'laptop',
          permission: ['ipSpeakers', [], 'GET'],
          meta: {
            bulkSpeakerManagementControlled: 'hide',
            isICOPLink: true,
            icopFeaturesRequired: [DISTRIBUTED_ACTIVATION_FEATURE],
          },
          spotlight: {
            title: spotlight_t(['desktopNotifiers', 'title']),
            subtitle: spotlight_t(['desktopNotifiers', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'lpi', 'title']),
          id: 'sub-menu-lpi',
          icon: 'router',
          children: [
            {
              title: core_t(['navigation', 'recipients', 'lpi', 'config']),
              id: 'sidebar-lpi-configuration',
              routerLink: Routes.LPI.Configuration,
              icon: 'settings',
              permission: ['pluginLpiConfiguration', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['lpiConfiguration', 'title']),
                subtitle: spotlight_t(['lpiConfiguration', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'lpi', 'sipServerGroups']),
              id: 'sidebar-lpi-sip-server-groups',
              routerLink: Routes.LPI.ServerGroups.Index,
              icon: 'phone-sip',
              permission: ['pluginLpiServerGroups', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['lpiServerGroups', 'title']),
                subtitle: spotlight_t(['lpiServerGroups', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'lpi', 'pagingDevices']),
              id: 'sidebar-lpi-paging-devices',
              routerLink: Routes.LPI.PagingDevices.Index,
              icon: 'collection-speaker',
              permission: ['pluginLpiPagingDevices', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['lpiPagingDevices', 'title']),
                subtitle: spotlight_t(['lpiPagingDevices', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'recipients', 'lpi', 'currentActivity']),
              id: 'sidebar-lpi-current-activities',
              routerLink: Routes.LPI.CurrentActivity,
              icon: 'eye',
              permission: ['pluginLpiPagingDeviceCurrentActivity', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['lpiCurrentActivities', 'title']),
                subtitle: spotlight_t(['lpiCurrentActivities', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'recipients', 'pagingGateways']),
          id: 'sidebar-paging-gateways',
          routerLink: Routes.PagingGateway.Index,
          icon: 'arrow-right',
          permission: ['pagingGateways', [], 'GET'],
          meta: {
            isICOPLink: true,
          },
          spotlight: {
            title: spotlight_t(['pagingGateways', 'title']),
            subtitle: spotlight_t(['pagingGateways', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'm2m', 'title']),
          id: 'sub-menu-m2m',
          icon: 'alert-circle-o',
          children: [
            {
              title: core_t(['navigation', 'recipients', 'm2m', 'contactClosures']),
              id: 'sidebar-m2m-contact-closures',
              routerLink: '/m2m/contact-closures',
              icon: 'device-hub',
              permission: [
                ['pluginM2MContactClosures', [], 'GET'],
                ['pluginFusionM2MContactClosures', [], 'GET'],
              ],
              meta: { isICOPLink: true, permissionsOr: true },
              spotlight: {
                title: spotlight_t(['m2mContactClosures', 'title']),
                subtitle: spotlight_t(['m2mContactClosures', 'subtitle']),
              },
            },
            {
              routerLink: '/m2m/schedules',
              title: core_t(['navigation', 'recipients', 'm2m', 'schedules']),
              id: 'sidebar-m2m-schedules',
              icon: 'calendar',
              permission: ['pluginM2MSchedules', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['m2mSchedules', 'title']),
                subtitle: spotlight_t(['m2mSchedules', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'recipients', 'quickUrl']),
          id: 'sidebar-quick-url',
          routerLink: Routes.QuickURL.Index,
          icon: 'flare',
          permission: ['pluginQuickUrlDevices', [], 'GET'],
          meta: {
            isICOPLink: true,
          },
          spotlight: {
            title: spotlight_t(['quickUrl', 'title']),
            subtitle: spotlight_t(['quickUrl', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'recipients', 'devices', 'title']),
          id: 'sub-menu-api-devices',
          icon: 'devices',
          permission: [['apiDevices', [], 'GET']],
          routerLink: Routes.Devices.Index,
          spotlight: {
            title: spotlight_t(['apiDevices', 'title']),
            subtitle: spotlight_t(['apiDevices', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'integrations', 'ipCameras']),
          id: 'sidebar-ip-cameras',
          routerLink: Routes.IpCameras.Index,
          icon: 'videocam',
          permission: ['ipCameras', [], 'GET'],
          meta: {
            featuresRequired: ['ipCameras'],
          },
          spotlight: {
            title: spotlight_t(['ipCameras', 'title']),
            subtitle: spotlight_t(['ipCameras', 'subtitle']),
          },
        },
      ],
    },
    {
      title: core_t(['navigation', 'notifications', 'title']),
      id: 'sub-menu-notifications',
      icon: 'mail-reply-all',
      children: [
        {
          title: core_t(['navigation', 'notifications', 'history']),
          id: 'sidebar-notification-history',
          routerLink: Routes.Notifications.List,
          icon: 'mail-send',
          permission: ['notifications', [], 'GET'],
          spotlight: {
            title: spotlight_t(['notificationHistory', 'title']),
            subtitle: spotlight_t(['notificationHistory', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'notifications', 'messageTemplates']),
          id: 'sidebar-message-templates',
          routerLink: Routes.MessageTemplates.List,
          icon: 'bookmark',
          permission: ['messageTemplates', [], 'GET'],
          spotlight: {
            title: spotlight_t(['messageTemplates', 'title']),
            subtitle: spotlight_t(['messageTemplates', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'notifications', 'confirmationRequests']),
          id: 'sidebar-cconfirmation-requests',
          routerLink: Routes.ConfirmationRequests.List,
          icon: 'assignment-check',
          permission: ['confirmationRequests', [], 'GET'],
          spotlight: {
            title: spotlight_t(['confirmationRequests', 'title']),
            subtitle: spotlight_t(['confirmationRequests', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'notifications', 'commandCenter', 'title']),
          id: 'sub-menu-command-center',
          icon: 'collection-text',
          children: [
            {
              title: core_t(['navigation', 'notifications', 'commandCenter', 'siteRoles']),
              id: 'sidebar-command-center-site-roles',
              routerLink: Routes.CommandCenter.SiteRoles.List,
              icon: 'accounts-list-alt',
              permission: ['siteRoles', [], 'GET'],
              spotlight: {
                title: spotlight_t(['siteRoles', 'title']),
                subtitle: spotlight_t(['siteRoles', 'subtitle']),
              },
              meta: { hideOutsideGlobalFacility: true },
            },
            {
              title: core_t(['navigation', 'notifications', 'commandCenter', 'sites']),
              id: 'sidebar-command-center-sites',
              routerLink: Routes.CommandCenter.Sites.List,
              icon: 'city',
              permission: [
                ['sites', [], 'GET'],
                ['siteRoles', [], 'GET'],
              ],
              spotlight: {
                title: spotlight_t(['sites', 'title']),
                subtitle: spotlight_t(['sites', 'subtitle']),
              },
              meta: {
                hideWhenFeaturesEnabled: ['siteRoutesRefactoring'],
                localFacilityTitle: core_t([
                  'navigation',
                  'notifications',
                  'commandCenter',
                  'site',
                ]),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'commandCenter', 'sites']),
              id: 'sidebar-command-center-sites',
              routerLink: Routes.CommandCenter.SitesV2.Sites,
              icon: 'city',
              permission: [
                ['sites', [], 'GET'],
                ['siteRoles', [], 'GET'],
              ],
              spotlight: {
                title: spotlight_t(['sites', 'title']),
                subtitle: spotlight_t(['sites', 'subtitle']),
              },
              meta: {
                featuresRequired: ['siteRoutesRefactoring'],
                localFacilityTitle: core_t([
                  'navigation',
                  'notifications',
                  'commandCenter',
                  'site',
                ]),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'commandCenter', 'scenarios']),
              id: 'sidebar-command-center-scenarios',
              routerLink: Routes.CommandCenter.Scenarios.List,
              icon: 'view-carousel',
              permission: ['scenarios', [], 'GET'],
              spotlight: {
                title: spotlight_t(['scenarios', 'title']),
                subtitle: spotlight_t(['scenarios', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'commandCenter', 'incidentPlans']),
              id: 'sidebar-command-center-incident-plans',
              routerLink: Routes.CommandCenter.IncidentPlans.Index,
              icon: 'file-text',
              permission: ['incidentPlans', [], 'GET'],
              spotlight: {
                title: spotlight_t(['incidentPlans', 'title']),
                subtitle: spotlight_t(['incidentPlans', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'commandCenter', 'incidents']),
              id: 'sidebar-command-center-incidents',
              routerLink: Routes.CommandCenter.Incidents.Index,
              icon: 'info',
              permission: ['incidents', [], 'GET'],
              spotlight: {
                title: spotlight_t(['incidents', 'title']),
                subtitle: spotlight_t(['incidents', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'notifications', 'trackingEventNotifications']),
          id: 'sidebar-tracking-event-notifications',
          routerLink: Routes.TrackingEventNotifications.Index,
          icon: 'map',
          permission: ['trackingEventNotifications', [], 'GET'],
          spotlight: {
            title: spotlight_t(['trackingEventNotifications', 'title']),
            subtitle: spotlight_t(['trackingEventNotifications', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'notifications', 'notificationProfiles']),
          id: 'sidebar-notification-profiles',
          routerLink: Routes.NotificationProfiles.List,
          icon: 'notifications-add',
          permission: ['notificationProfiles', [], 'GET'],
          spotlight: {
            title: spotlight_t(['notificationProfiles', 'title']),
            subtitle: spotlight_t(['notificationProfiles', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'notifications', 'followUpMessageTemplate']),
          id: 'sidebar-follow-up-message-template',
          routerLink: Routes.FollowUpMessageTemplate.Index,
          icon: 'mail-reply',
          permission: ['messageTemplates', [], 'GET'],
          permissionQuery: (store: Store<RootState>): Observable<boolean> => {
            const session = store.getState().session!;

            if (session.domain) {
              const o$ = req<MobileApiPageEnvelope<MobileApiMessageTemplate>>(
                ['messageTemplates', [], 'GET', { params: { q: 'follow-up:true' } }],
                store,
                BackgroundRef
              );
              // Currently, one template per-provider is available (non-static UUID)
              // If domains are enabled, must be in the root domain.
              return o$.pipe(
                map(
                  response => response.data.data.length > 0 && session.domain?.id === ROOT_DOMAIN_ID
                ),
                catchError(() => of(false))
              );
            }
            // Domains are not enabled, so skip request and return true
            return of(true);
          },
          spotlight: {
            title: spotlight_t(['followUpMessageTemplate', 'title']),
            subtitle: spotlight_t(['followUpMessageTemplate', 'subtitle']),
          },
        },
        // Hiding from SideBar since the feature is not quite ready ( see SW-12347)
        // {
        //   title: i18next.t('core:navigation.notifications.callAwareRules'),
        //   routerLink: Routes.CallAwareRules.List,
        //   icon: 'phone-forwarded',
        //   permission: ['callAwareRules', [], 'GET'],
        // },
        {
          title: core_t(['navigation', 'notifications', 'dialCast', 'title']),
          id: 'sub-menu-dialcast',
          icon: 'dialpad',
          children: [
            {
              title: core_t(['navigation', 'notifications', 'dialCast', 'dialingConfigs']),
              id: 'sidebar-callaware-dialing-configurations',
              routerLink: Routes.DialCast.DialingConfigurations.Index,
              icon: 'key',
              permission: [
                ['adminDialCastDialingConfigurations', [], 'GET'],
                ['fusionAdminDialCastDialingConfigurations', [], 'GET'],
              ],
              meta: {
                isICOPLink: true,
                permissionsOr: true,
                cloudDialcastControlled: 'hide',
              },
              spotlight: {
                title: spotlight_t(['dialingConfiguration', 'title']),
                subtitle: spotlight_t(['dialingConfiguration', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'dialCast', 'dialingConfigs']),
              id: 'sidebar-callaware-dialing-configurations-v2',
              routerLink: Routes.DialCastV2.DialingConfigurations.Index,
              icon: 'key',
              permission: ['dialcastDialingConfigurations', [], 'GET'],
              meta: {
                cloudDialcastControlled: 'show',
                isICOPLink: false,
                featuresRequired: ['cloudDialcast'],
              },
              spotlight: {
                title: spotlight_t(['dialingConfiguration', 'title']),
                subtitle: spotlight_t(['dialingConfiguration', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'dialCast', 'phoneExceptions']),
              id: 'sidebar-callaware-phone-exceptions',
              routerLink: Routes.DialCast.PhoneExceptions.Index,
              icon: 'scissors',
              permission: ['adminDialCastPhoneExceptions', [], 'GET'],
              meta: { isICOPLink: true, cloudDialcastControlled: 'hide' },
              spotlight: {
                title: spotlight_t(['phoneExceptions', 'title']),
                subtitle: spotlight_t(['phoneExceptions', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'dialCast', 'phoneExceptions']),
              id: 'sidebar-dialcast-phone-exceptions-v2',
              routerLink: Routes.DialCastV2.PhoneExceptions.Index,
              icon: 'scissors',
              permission: ['dialcastPhoneExceptions', [], 'GET'],
              meta: { isICOPLink: false, cloudDialcastControlled: 'show' },
              spotlight: {
                title: spotlight_t(['phoneExceptions', 'title']),
                subtitle: spotlight_t(['phoneExceptions', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'notifications', 'callAware', 'title']),
          id: 'sub-menu-callaware',
          icon: 'phone-forwarded',
          children: [
            {
              title: core_t(['navigation', 'notifications', 'callAware', 'config']),
              id: 'sidebar-callaware-configuration',
              routerLink: '/callaware/configuration',
              icon: 'settings',
              permission: ['pluginCallAwareConfiguration', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['callawareConfiguration', 'title']),
                subtitle: spotlight_t(['callawareConfiguration', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'callAware', 'cucms']),
              id: 'sidebar-callaware-cucms',
              routerLink: '/callaware/cucms',
              icon: 'collection-item',
              permission: ['pluginCallAwareConfigurationCucms', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['callawareCUCM', 'title']),
                subtitle: spotlight_t(['callawareCUCM', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'callAware', 'callRedirects']),
              id: 'sidebar-callaware-call-redirects',
              routerLink: '/callaware/call-redirects',
              icon: 'phone-forwarded',
              permission: [
                ['pluginCallAwareCallRedirects', [], 'GET'],
                ['pluginFusionCallAwareCallRedirects', [], 'GET'],
              ],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
                permissionsOr: true,
              },
              spotlight: {
                title: spotlight_t(['callawareCallRedirects', 'title']),
                subtitle: spotlight_t(['callawareCallRedirects', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'callAware', 'routingRequests']),
              id: 'sidebar-callaware-routing-requests',
              routerLink: '/callaware/routing-requests',
              icon: 'phone-forwarded',
              permission: [
                ['pluginCallAwareRoutingRequests', [], 'GET'],
                ['pluginFusionCallAwareRoutingRequests', [], 'GET'],
              ],
              meta: {
                isICOPLink: true,
                icopFeaturesRequired: [CURRI_FEATURE],
                hideWhenCucmDisabled: true,
                permissionsOr: true,
              },
              spotlight: {
                title: spotlight_t(['callawareRoutingRequests', 'title']),
                subtitle: spotlight_t(['callawareRoutingRequests', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'callAware', 'callHistory']),
              id: 'sidebar-callaware-call-history',
              routerLink: '/callaware/call-history',
              icon: 'book',
              permission: ['pluginCallAwareCallHistoryList', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['callawareCallHistory', 'title']),
                subtitle: spotlight_t(['callawareCallHistory', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'callAware', 'diagnostics']),
              id: 'sidebar-callaware-diagnostics',
              routerLink: '/callaware/diagnostics',
              icon: 'eye',
              permission: ['pluginCallAwareDiagnostics', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['callawareDiagnostics', 'title']),
                subtitle: spotlight_t(['callawareDiagnostics', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'notifications', 'scheduledNotifications']),
          id: 'sidebar-scheduled-notifications',
          routerLink: Routes.ScheduledNotifications.Index,
          icon: 'refresh-sync-alert',
          permission: ['scheduledNotifications', [], 'GET'],
          spotlight: {
            title: spotlight_t(['scheduledNotifications', 'title']),
            subtitle: spotlight_t(['scheduledNotifications', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'notifications', 'inboundEvents', 'title']),
          id: 'sub-menu-inbound-events',
          icon: 'portable-wifi',
          children: [
            {
              title: core_t(['navigation', 'notifications', 'inboundEvents', 'icap']),
              id: 'sidebar-inbound-rss-icap',
              routerLink: Routes.InboundCap.Index,
              icon: 'alert-polygon',
              permission: ['inboundCapRules', [], 'GET'],
              spotlight: {
                title: spotlight_t(['inboundCap', 'title']),
                subtitle: spotlight_t(['inboundCap', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'inboundEvents', 'email']),
              id: 'sidebar-inbound-rss-email',
              routerLink: Routes.InboundEmail.Index,
              icon: 'email',
              permission: ['inboundEmail', [], 'GET'],
              spotlight: {
                title: spotlight_t(['email', 'title']),
                subtitle: spotlight_t(['email', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'inboundEvents', 'rss']),
              id: 'sidebar-inbound-rss-feeds',
              routerLink: '/inbound-rss',
              icon: 'rss',
              permission: ['inboundRssFeeds', [], 'GET'],
              spotlight: {
                title: spotlight_t(['inboundRss', 'title']),
                subtitle: spotlight_t(['inboundRss', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'notifications', 'ipaws', 'title']),
          id: 'sub-menu-ipaws',
          icon: 'smartphone-info',
          children: [
            {
              title: core_t(['navigation', 'notifications', 'ipaws', 'alerts']),
              id: 'sidebar-ipaws-alerts',
              routerLink: Routes.IPAWSAlerts.List,
              icon: 'notifications-active',
              permission: ['outboundIpawsAlerts', ['lab'], 'GET'],
              meta: {
                featuresRequired: ['outboundIpaws'],
              },
              spotlight: {
                title: spotlight_t(['ipawsAlerts', 'title']),
                subtitle: spotlight_t(['ipawsAlerts', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'ipaws', 'customAreas']),
              id: 'sidebar-ipaws-custom-areas',
              routerLink: Routes.IPAWSAreas.List,
              icon: 'my-location',
              permission: ['outboundIpawsAreas', [], 'GET'],
              meta: {
                featuresRequired: ['outboundIpaws'],
              },
              spotlight: {
                title: spotlight_t(['ipawsCustomAreas', 'title']),
                subtitle: spotlight_t(['ipawsCustomAreas', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'notifications', 'bells', 'title']),
          id: 'sub-menu-bells',
          icon: 'alarm-check',
          meta: { featuresRequired: ['fusion'] },
          children: [
            {
              title: core_t(['navigation', 'notifications', 'bells', 'overview']),
              id: 'sidebar-bell-schedules-overview',
              routerLink: Routes.Bells.Overview,
              icon: 'calendar-alt',
              permission: ['bellSchedules', [], 'GET'],
              meta: { featuresRequired: ['fusion'] },
              spotlight: {
                title: spotlight_t(['bellScheduleOverview', 'title']),
                subtitle: spotlight_t(['bellScheduleOverview', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'bells', 'ringLists']),
              id: 'sidebar-ring-lists',
              routerLink: Routes.Bells.RingLists.List,
              icon: 'audio',
              permission: ['ringLists', [], 'GET'],
              meta: { featuresRequired: ['fusion'] },
              spotlight: {
                title: spotlight_t(['ringLists', 'title']),
                subtitle: spotlight_t(['ringLists', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'bells', 'schedules']),
              id: 'sidebar-bell-schedules',
              routerLink: Routes.Bells.Schedules.List,
              icon: 'calendar-note',
              permission: ['bellSchedules', [], 'GET'],
              meta: { featuresRequired: ['fusion'] },
              spotlight: {
                title: spotlight_t(['bellSchedules', 'title']),
                subtitle: spotlight_t(['bellSchedules', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'bells', 'exceptions']),
              id: 'sidebar-bell-schedules-exceptions',
              routerLink: Routes.Bells.Exceptions,
              icon: 'notifications-off',
              permission: [
                ['bellSchedulesBulkExceptions', [], 'POST'],
                ['bellSchedules', [], 'GET'],
              ],
              meta: { featuresRequired: ['fusion'] },
              spotlight: {
                title: spotlight_t(['bellScheduleExceptions', 'title']),
                subtitle: spotlight_t(['bellScheduleExceptions', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'notifications', 'nightBell', 'title']),
          id: 'sub-menu-nightbell',
          icon: 'brightness-2',
          children: [
            {
              title: core_t(['navigation', 'notifications', 'nightBell', 'config']),
              id: 'sidebar-nightbell-config',
              routerLink: Routes.NightBell.Configuration,
              icon: 'settings',
              permission: ['pluginNightBellConfiguration', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['nightBellConfiguration', 'title']),
                subtitle: spotlight_t(['nightBellConfiguration', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'nightBell', 'cucms']),
              id: 'sidebar-nightbell-cucm',
              routerLink: Routes.NightBell.CUCM.Index,
              icon: 'collection-item',
              permission: ['pluginNightBellConfigurationCucms', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['nightBellCUCM', 'title']),
                subtitle: spotlight_t(['nightBellCUCM', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'nightBell', 'triggers']),
              id: 'sidebar-nightbell-triggers',
              routerLink: Routes.NightBell.Triggers.Index,
              icon: 'arrow-split',
              permission: [
                ['pluginNightBellTriggers', [], 'GET'],
                ['pluginFusionNightBellTriggers', [], 'GET'],
              ],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
                permissionsOr: true,
              },
              spotlight: {
                title: spotlight_t(['nightBellTriggers', 'title']),
                subtitle: spotlight_t(['nightBellTriggers', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'notifications', 'nightBell', 'diagnostics']),
              id: 'sidebar-nightbell-diagnostics',
              routerLink: Routes.NightBell.Diagnostics,
              icon: 'eye',
              permission: ['pluginNightBellDiagnostics', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['nightBellDiagnostics', 'title']),
                subtitle: spotlight_t(['nightBellDiagnostics', 'subtitle']),
              },
            },
          ],
        },
      ],
    },
    {
      title: core_t(['navigation', 'admin', 'title']),
      id: 'sub-menu-admin',
      icon: 'city-alt',
      children: [
        {
          title: core_t(['navigation', 'admin', 'overview']),
          id: 'sidebar-admin-overview',
          routerLink: '/icop/admin/overview',
          icon: 'eye',
          permission: ['adminOverview', [], 'GET'],
          meta: {
            isICOPLink: true,
          },
          spotlight: {
            title: spotlight_t(['fusionConfiguration', 'title']),
            subtitle: spotlight_t(['fusionConfiguration', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'systemHealth']),
          id: 'sidebar-system-health',
          routerLink: Routes.SystemHealth.Dashboard,
          icon: 'alarm',
          permission: [
            ['reports', [], 'GET'] || ['extensionsICOPEndpoints', [], 'GET'],
            ['systemHealth', ['*'], 'GET'],
          ],
          spotlight: {
            title: spotlight_t(['systemHealth', 'title']),
            subtitle: spotlight_t(['systemHealth', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'dataImport']),
          id: 'sub-menu-data-import',
          icon: 'code',
          children: [
            {
              title: core_t(['navigation', 'admin', 'bulkUpload']),
              id: 'sidebar-bulk-upload',
              routerLink: Routes.BulkUpload.Index,
              icon: 'collection-plus',
              permission: [
                ['bulkUpload', [], 'GET'],
                ['bulkUpload', [], 'POST'],
              ],
              spotlight: {
                title: spotlight_t(['bulkUploads', 'title']),
                subtitle: spotlight_t(['bulkUploads', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'schoolInformation']),
              id: 'sidebar-school-information',
              routerLink: Routes.SISLoaders.List,
              icon: 'graduation-cap',
              permission: ['loadDefinitions', [], 'GET'],
              meta: {
                featuresRequired: ['sisLoader'],
                hideOutsideGlobalFacility: true,
              },
              spotlight: {
                title: spotlight_t(['schoolInformation', 'title']),
                subtitle: spotlight_t(['schoolInformation', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'userLoaders']),
              id: 'sidebar-user-loaders',
              routerLink: Routes.UserLoaders.List,
              icon: 'accounts-add',
              permission: ['loadDefinitions', [], 'GET'],
              meta: {
                hideOutsideGlobalFacility: true,
              },
              spotlight: {
                title: spotlight_t(['userLoaders', 'title']),
                subtitle: spotlight_t(['userLoaders', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'admin', 'selfRegistration']),
          id: 'sidebar-self-registration',
          routerLink: Routes.SelfRegistration,
          icon: 'assignment-account',
          permission: [
            ['brandings', [], 'GET'],
            ['idps', [], 'GET'],
          ],
          meta: {
            hideOutsideGlobalFacility: true,
          },
          spotlight: {
            title: spotlight_t(['selfService', 'title']),
            subtitle: spotlight_t(['selfService', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'securityGroups']),
          id: 'sidebar-security-groups',
          routerLink: Routes.SecurityGroups.List,
          icon: 'globe-lock',
          permission: ['securityGroups', [], 'GET'],
          spotlight: {
            title: spotlight_t(['securityGroups', 'title']),
            subtitle: spotlight_t(['securityGroups', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'domains']),
          id: 'sidebar-domains',
          routerLink: Routes.Domains.List,
          icon: 'group-work',
          permission: ['domains', [], 'GET'],
          meta: { featuresRequired: ['domains'] },
          spotlight: {
            title: spotlight_t(['domains', 'title']),
            subtitle: spotlight_t(['domains', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'integrations', 'title']),
          id: 'sub-menu-integrations',
          icon: 'widgets',
          children: [
            {
              title: core_t(['navigation', 'admin', 'settings', 'applications']),
              id: 'sidebar-settings-applications',
              routerLink: Routes.Applications.Index,
              icon: 'apps',
              permission: [['applications', [], 'GET']],
              meta: {
                hideOutsideGlobalFacility: true,
              },
              spotlight: {
                title: spotlight_t(['applications', 'title']),
                subtitle: spotlight_t(['applications', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'publicApplications']),
              id: 'sidebar-public-applications',
              routerLink: Routes.PublicApplications.Index,
              icon: 'local-store',
              permission: [['publicApplications', [], 'GET']],
              meta: {
                featuresRequired: ['thirdPartyMarketplaceApplications'],
              },
              spotlight: {
                title: spotlight_t(['publicApplications', 'title']),
                subtitle: spotlight_t(['publicApplications', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'oauthAppsEnabled', 'serviceAccounts']),
              id: 'sidebar-oauth-service-accounts',
              routerLink: Routes.ServiceAccounts.List,
              icon: 'window-restore',
              permission: ['users', [], 'GET'],
              meta: {
                hideOutsideGlobalFacility: true,
              },
              spotlight: {
                title: spotlight_t(['serviceAccounts', 'title']),
                subtitle: spotlight_t(['serviceAccounts', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'kontaktioWearablePanicButton']),
              id: 'sidebar-kontaktio-panic-button',
              routerLink: Routes.KontaktioWearablePanicButton.Index,
              imageSrc: 'assets/images/kontakt.svg',
              meta: {
                featuresRequired: ['kontaktio'],
                hideOutsideGlobalFacility: true,
              },
              permission: [
                ['extensionsKontaktioWearablePanicButtonStatus', [], 'GET'],
                ['extensionsKontaktioWearablePanicButtonActions', [], 'POST'],
                ['extensionsKontaktioWearablePanicButtonSites', [], 'GET'],
              ],
              spotlight: {
                title: spotlight_t(['kontakt', 'title']),
                subtitle: spotlight_t(['kontakt', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'integrations', 'msteams']),
              id: 'sidebar-ms-teams',
              routerLink: Routes.MSTeamsConfig,
              icon: 'ms-teams',
              permission: [
                ['extensionsTeams', [], 'GET'],
                ['extensionsTeamsStatus', [], 'GET'],
                ['extensionsTeamsActions', [], 'POST'],
              ],
              meta: {
                featuresRequired: ['msteamsChat', 'msteamsChannel'],
                featuresOr: true,
              },
              spotlight: {
                title: spotlight_t(['msteamsSettings', 'title']),
                subtitle: spotlight_t(['msteamsSettings', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'integrations', 'webexCalling']),
              id: 'sidebar-webex-calling',
              routerLink: Routes.WebexCallingConfig.Index,
              icon: 'phone',
              permission: [
                ['extensionsWebexCalling', [], 'GET'],
                ['extensionsWebexCalling', [], 'PUT'],
                ['extensionsWebexCalling', [], 'POST'],
              ],
              meta: {
                featuresRequired: ['webexCalling'],
                featuresOr: true,
              },
              spotlight: {
                title: spotlight_t(['webexCalling', 'title']),
                subtitle: spotlight_t(['webexCalling', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'integrations', 'zoom']),
              id: 'sidebar-zoom',
              routerLink: Routes.Zoom.Index,
              icon: 'zoom',
              permission: ['extensionsZoomStatus', [], 'GET'],
              meta: {
                featuresRequired: ['zoom'],
                hideInSubdomains: true,
                hideOutsideGlobalFacility: true,
              },
              spotlight: {
                title: spotlight_t(['zoom', 'title']),
                subtitle: spotlight_t(['zoom', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'integrations', 'verkada']),
              id: 'sidebar-verkada',
              routerLink: Routes.IpCameras.Integration,
              icon: 'videocam',
              permission: [['ipCameraVendorConfigs', [], 'GET']],
              spotlight: {
                title: spotlight_t(['verkada', 'title']),
                subtitle: spotlight_t(['verkada', 'subtitle']),
              },
              meta: { featuresRequired: ['ipCameras'] },
            },
            {
              title: core_t(['navigation', 'admin', 'integrations', 'ruleActions']),
              id: 'sidebar-rule-actions',
              routerLink: Routes.ApiConnectors.Index,
              icon: 'code',
              permission: ['ruleActions', [], 'GET'],
              spotlight: {
                title: spotlight_t(['apiConnectors', 'title']),
                subtitle: spotlight_t(['apiConnectors', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'admin', 'triggers']),
          id: 'sidebar-triggers',
          routerLink: Routes.Triggers.List,
          icon: 'http',
          permission: ['triggers', [], 'GET'],
          spotlight: {
            title: spotlight_t(['triggers', 'title']),
            subtitle: spotlight_t(['triggers', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'servers']),
          id: 'sidebar-servers',
          routerLink: Routes.Servers.Index,
          icon: 'storage',
          permission: ['extensionsICOPEndpoints', [], 'GET'],
          meta: { featuresRequired: ['fusion'] },
          spotlight: {
            title: spotlight_t(['servers', 'title']),
            subtitle: spotlight_t(['servers', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'distributedActivation']),
          id: 'sidebar-distributed-activation',
          routerLink: Routes.ActivationGroups.List,
          icon: 'toys',
          permission: [
            ['activationGroups', [], 'GET'],
            ['devices', [], 'GET'],
          ],
          meta: {
            featuresRequired: ['fusion'],
            multipleServersRequired: true,
            icopFeaturesRequired: [DISTRIBUTED_ACTIVATION_FEATURE],
          },
          spotlight: {
            title: spotlight_t(['distributedActivation', 'title']),
            subtitle: spotlight_t(['distributedActivation', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'fusionFailover']),
          id: 'sidebar-fusion-failover',
          routerLink: '/fusion-failover',
          icon: 'ungroup',
          permission: ['failoverPairs', [], 'GET'],
          meta: {
            featuresRequired: ['fusion', 'failover'],
          },
          spotlight: {
            title: spotlight_t(['fusionFailover', 'title']),
            subtitle: spotlight_t(['fusionFailover', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'telephony', 'title']),
          id: 'sub-menu-telephony',
          icon: 'phone-setting',
          children: [
            {
              title: core_t(['navigation', 'admin', 'telephony', 'cucmClusters']),
              id: 'sidebar-telephony-cucm-clusters',
              routerLink: Routes.Admin.Telephony.CUCM.List,
              icon: 'phone',
              permission: ['adminTelephonyCucmClusters', [], 'GET'],
              permissionQuery: (store: Store<RootState>) =>
                of(!cucmCloudClustersFeatureEnabled(store.getState())),
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['cucmClusters', 'title']),
                subtitle: spotlight_t(['cucmClusters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'telephony', 'cucmClusters']),
              id: 'sidebar-cucm-clusters',
              routerLink: Routes.CucmClusters.List,
              icon: 'phone',
              permission: ['cucmClusters', [], 'GET'],
              permissionQuery: (store: Store<RootState>) =>
                of(cucmCloudClustersFeatureEnabled(store.getState())),
              meta: {
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['cucmClusters', 'title']),
                subtitle: spotlight_t(['cucmClusters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'telephony', 'ctiCertificates']),
              id: 'sidebar-cti-certificates',
              routerLink: Routes.CTICertificates.List,
              icon: 'phone',
              permission: ['ListCTICertificates', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['ctiCertificates', 'title']),
                subtitle: spotlight_t(['ctiCertificates', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'telephony', 'cucmExternalCallControl']),
              id: 'sidebar-cucm-external-call-control',
              routerLink: Routes.Admin.Telephony.ExternalCallControl,
              icon: 'time-restore-setting',
              permission: [
                ['adminTelephonyCucmExternalCallControlKeepalive', [], 'GET'],
                ['adminTelephonyCucmExternalCallControlSecureWebServiceUrLs', [], 'GET'],
                ['adminTelephonyCucmExternalCallControlWebServiceUrLChooseAnotherToken', [], 'GET'],
                ['adminTelephonyCucmExternalCallControlWebServiceUrLs', [], 'GET'],
              ],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
                icopFeaturesRequired: [CURRI_FEATURE],
              },
              spotlight: {
                title: spotlight_t(['externalCallControl', 'title']),
                subtitle: spotlight_t(['externalCallControl', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'telephony', 'cucmPhoneRefreshOverview']),
              id: 'sidebar-cucm-phone-refresh',
              routerLink: Routes.Admin.Telephony.PhoneRefreshOverview,
              icon: 'time-interval',
              permission: ['extensionsICOP', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['phoneRefreshOverview', 'title']),
                subtitle: spotlight_t(['phoneRefreshOverview', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'telephony', 'cucmPhoneParameters']),
              id: 'sidebar-cucm-phone-parameters',
              routerLink: Routes.Admin.Telephony.PhoneParameters,
              icon: 'time-restore-setting',
              permission: ['extensionsICOP', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenCucmDisabled: true,
              },
              spotlight: {
                title: spotlight_t(['phoneParameters', 'title']),
                subtitle: spotlight_t(['phoneParameters', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'recipients', 'gms']),
          id: 'sidebar-gms-recipients',
          routerLink: Routes.GenericMulticastStreaming.List,
          icon: 'phone-in-talk',
          permission: ['pluginCloudPhones', [], 'GET'],
          meta: {
            isICOPLink: false,
            featuresRequired: ['fusion', 'gms'],
            icopFeaturesRequired: [GENERIC_MULTICAST_STREAMING_FEATURE],
          },
          spotlight: {
            title: spotlight_t(['multicastStreams', 'title']),
            subtitle: spotlight_t(['multicastStreams', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'clearDevices']),
          id: 'sidebar-clear-devices',
          routerLink: Routes.Admin.System.ClearDevices,
          icon: 'rotate-right',
          permission: ['adminSystemRecipientgroupClearPhones', ['*'], 'PUT'],
          meta: {
            isICOPLink: true,
          },
          spotlight: {
            title: spotlight_t(['clearDevices', 'title']),
            subtitle: spotlight_t(['clearDevices', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'sip', 'title']),
          id: 'sub-menu-sip',
          icon: 'phone-sip',
          children: [
            {
              title: core_t(['navigation', 'admin', 'sip', 'access']),
              id: 'sidebar-sip-access',
              routerLink: Routes.Admin.SIP.Access,
              icon: 'redo',
              permission: ['adminSipAccess', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['sipAccess', 'title']),
                subtitle: spotlight_t(['sipAccess', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'accounts']),
              id: 'sidebar-sip-accounts',
              routerLink: Routes.Admin.SIP.Accounts.List,
              icon: 'code-setting',
              permission: ['adminSipAccounts', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenModelessNotSupportedCucmEnabled: true,
              },
              spotlight: {
                title: spotlight_t(['sipAccounts', 'title']),
                subtitle: spotlight_t(['sipAccounts', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'callSecurity']),
              id: 'sidebar-sip-call-security',
              routerLink: Routes.Admin.SIP.Security,
              icon: 'phone-locked',
              permission: ['adminSipAuthentication', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['callSecurity', 'title']),
                subtitle: spotlight_t(['callSecurity', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'certificates']),
              id: 'sidebar-sip-certificates',
              routerLink: Routes.Admin.SIP.Certificates,
              icon: 'receipt',
              permission: ['adminSipCertificates', ['*'], 'GET'],
              meta: {
                isICOPLink: true,
              },
              spotlight: {
                title: spotlight_t(['sipCertificates', 'title']),
                subtitle: spotlight_t(['sipCertificates', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'registrations']),
              id: 'sidebar-sip-registrations',
              routerLink: Routes.Admin.SIP.Registrations.List,
              icon: 'code-setting',
              permission: ['adminSipRegistrations', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenIcopFeatures: [SIP_ACCOUNTS_FEATURE],
                hideWhenModelessNotSupportedCucmEnabled: true,
              },
              spotlight: {
                title: spotlight_t(['sipRegistrations', 'title']),
                subtitle: spotlight_t(['sipRegistrations', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'restart']),
              id: 'sidebar-sip-restart',
              routerLink: Routes.Admin.SIP.Restart,
              icon: 'rotate-left',
              permission: ['adminSipRestart', [], 'PUT'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['sipRestart', 'title']),
                subtitle: spotlight_t(['sipRestart', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'serverGroups']),
              id: 'sidebar-sip-server-groups',
              routerLink: Routes.Admin.SIP.ServerGroups.List,
              icon: 'group',
              permission: ['adminSipServerGroups', [], 'GET'],
              meta: {
                isICOPLink: true,
                hideWhenIcopFeatures: [SIP_ACCOUNTS_FEATURE],
                hideWhenModelessNotSupportedCucmEnabled: true,
              },
              spotlight: {
                title: spotlight_t(['sipServerGroups', 'title']),
                subtitle: spotlight_t(['sipServerGroups', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'speakerParameters']),
              id: 'sidebar-sip-speaker-parameters',
              routerLink: Routes.Admin.SIP.SpeakerParameters.Index,
              icon: 'collection-speaker',
              permission: ['adminSipDnsSrvRecords', [], 'GET'],
              meta: {
                bulkSpeakerManagementControlled: 'hide',
                isICOPLink: true,
              },
              spotlight: {
                title: spotlight_t(['sipSpeakerParameters', 'title']),
                subtitle: spotlight_t(['sipSpeakerParameters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'speakerParameters']),
              id: 'sidebar-sip-speaker-parameters-v2',
              routerLink: Routes.Admin.SIP.SpeakerParametersV2.Index,
              icon: 'collection-speaker',
              permission: ['ipSpeakerSipParameters', [], 'GET'],
              meta: {
                bulkSpeakerManagementControlled: 'show',
              },
              spotlight: {
                title: spotlight_t(['sipSpeakerParameters', 'title']),
                subtitle: spotlight_t(['sipSpeakerParameters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'stack']),
              id: 'sidebar-sip-stack',
              routerLink: Routes.Admin.SIP.Stack,
              icon: 'card',
              permission: ['adminSipStack', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['sipStack', 'title']),
                subtitle: spotlight_t(['sipStack', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'userCredentials']),
              id: 'sidebar-user-credentials',
              routerLink: Routes.Admin.SIP.Credentials.List,
              icon: 'account-circle',
              permission: ['adminSipUserCredentials', [], 'GET'],
              meta: { isICOPLink: true },
              spotlight: {
                title: spotlight_t(['userCredentials', 'title']),
                subtitle: spotlight_t(['userCredentials', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'sip', 'dnsSrvRecords']),
              id: 'sidebar-dns-srv-records',
              routerLink: Routes.Admin.SIP.DnsSrv,
              icon: 'view-list-alt',
              permission: ['adminSipDnsSrvRecords', [], 'GET'],
              meta: {
                isICOPLink: true,
                icopFeaturesRequired: [DNS_SRV_FEATURE],
              },
              spotlight: {
                title: spotlight_t(['dnsRecords', 'title']),
                subtitle: spotlight_t(['dnsRecords', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'admin', 'dialToIntercom']),
          routerLink: Routes.DialToIntercom,
          id: 'dial-to-intercom',
          icon: 'phone-in-talk',
          permission: ['adminDialToIntercomConfiguration', [], 'GET'],
          meta: {
            isICOPLink: true,
            hideWhenCucmDisabled: true,
            icopFeaturesRequired: [DIAL_TO_INTERCOM_FEATURE],
          },
          spotlight: {
            title: spotlight_t(['dialToIntercom', 'title']),
            subtitle: spotlight_t(['dialToIntercom', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'licenses', 'title']),
          id: 'sub-menu-licenses',
          icon: 'ticket-star',
          permission: [['reports', [GENERAL_COUNTS_REPORT_API_ID], 'GET']],
          routerLink: Routes.Admin.Licenses.Index,
          spotlight: {
            title: spotlight_t(['licenses', 'title']),
            subtitle: spotlight_t(['licenses', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'admin', 'security', 'title']),
          id: 'sub-menu-security',
          icon: 'shield-security',
          children: [
            {
              title: core_t(['navigation', 'admin', 'security', 'encryptionKeyManagement']),
              id: 'sidebar-encryption-key-management',
              routerLink: Routes.Admin.Security.EncryptionKeyManagement,
              icon: 'key',
              permission: [['providerKeyRotation', [], 'DELETE']],
              permissionQuery: (store: Store<RootState>) =>
                of(isServerFeatureSupported(PROVIDER_KEY_ROTATION, store.getState().session)),
              meta: {
                featuresRequired: ['fusion'],
              },
              spotlight: {
                title: spotlight_t(['encryptionKey', 'title']),
                subtitle: spotlight_t(['encryptionKey', 'subtitle']),
              },
            },
          ],
        },
        {
          title: core_t(['navigation', 'admin', 'settings', 'title']),
          id: 'sub-menu-settings',
          icon: 'settings',
          children: [
            {
              title: core_t(['navigation', 'admin', 'settings', 'appearance']),
              id: 'sidebar-appearance-settings',
              routerLink: Routes.Appearance,
              icon: 'palette',
              permission: [
                ['brandings', [], 'GET'],
                ['idps', [], 'GET'],
              ],
              spotlight: {
                title: spotlight_t(['appearance', 'title']),
                subtitle: spotlight_t(['appearance', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'calling']),
              id: 'sidebar-calling-settings',
              routerLink: Routes.Calling,
              icon: 'phone',
              permission: ['extensionsPhoneCall', [], 'GET'],
              spotlight: {
                title: spotlight_t(['calling', 'title']),
                subtitle: spotlight_t(['calling', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'idn']),
              id: 'sidebar-idn-settings',
              routerLink: Routes.IDNSettings.Index,
              icon: 'tv-list',
              permission: ['extensionsIdn', [], 'GET'],
              meta: { icopFeaturesRequired: [STYLE_SUPPORT_FEATURE] },
              spotlight: {
                title: spotlight_t(['idns', 'title']),
                subtitle: spotlight_t(['idns', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'dnd']),
              id: 'sidebar-dnd-settings',
              routerLink: Routes.DND,
              icon: 'alarm-check',
              permission: ['extensionsDND', [], 'GET'],
              spotlight: {
                title: spotlight_t(['dnd', 'title']),
                subtitle: spotlight_t(['dnd', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'dominsConfig']),
              id: 'sidebar-domains-config',
              routerLink: Routes.DomainsConfig,
              icon: 'group-work',
              permission: ['domains', [], 'POST'],
              meta: { domainsMustBeConfigurable: true },
              spotlight: {
                title: spotlight_t(['domainsConfig', 'title']),
                subtitle: spotlight_t(['domainsConfig', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'email']),
              id: 'sidebar-email-settings',
              routerLink: Routes.Email,
              icon: 'email',
              permission: ['extensionsEmail', [], 'GET'],
              spotlight: {
                title: spotlight_t(['emailSettings', 'title']),
                subtitle: spotlight_t(['emailSettings', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'support']),
              id: 'sub-menu-support',
              icon: 'smartphone-info',
              routerLink: Routes.Help,
              permission: [
                ['brandings', [], 'GET'],
                ['idps', [], 'GET'],
              ],
              spotlight: {
                title: spotlight_t(['support', 'title']),
                subtitle: spotlight_t(['support', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'informaCastApps']),
              id: 'sidebar-informacast-apps',
              routerLink: Routes.InformaCastApps,
              icon: 'smartphone-android',
              permission: [
                ['extensionsIOS', [], 'GET'],
                ['extensionsMacDesktop', [], 'GET'],
              ],
              meta: {
                permissionsOr: true,
              },
              spotlight: {
                title: spotlight_t(['informacastApps', 'title']),
                subtitle: spotlight_t(['informacastApps', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'sms']),
              id: 'sidebar-sms-settings',
              routerLink: Routes.SMS,
              icon: 'comment-text-alt',
              permission: ['extensionsSMS', [], 'GET'],
              spotlight: {
                title: spotlight_t(['sms', 'title']),
                subtitle: spotlight_t(['sms', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'timeZoneAndLocale']),
              id: 'sidebar-timezone-locale',
              routerLink: Routes.Settings,
              icon: 'alarm-check',
              permission: ['settings', [], 'GET'],
              spotlight: {
                title: spotlight_t(['timeZone', 'title']),
                subtitle: spotlight_t(['timeZone', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'ipaws']),
              id: 'sidebar-ipaws-settings',
              routerLink: Routes.IPAWSConfig.Index,
              icon: 'smartphone-info',
              permission: ['outboundIpawsConfig', [], 'GET'],
              meta: {
                featuresRequired: ['outboundIpaws'],
              },
              spotlight: {
                title: spotlight_t(['ipaws', 'title']),
                subtitle: spotlight_t(['ipaws', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'tts']),
              id: 'sidebar-tts-settings',
              permission: ['ttsLexicons', [], 'GET'],
              routerLink: '/tts-config',
              icon: 'text-format',
              spotlight: {
                title: spotlight_t(['tts', 'title']),
                subtitle: spotlight_t(['tts', 'subtitle']),
              },
            },
            {
              title: core_t([
                'navigation',
                'admin',
                'settings',
                'networkParameters',
                'broadcastParameters',
              ]),
              id: 'sub-menu-broadcast-parameters-menus',
              icon: 'phone-end',
              meta: { isICOPLink: true },
              routerLink: Routes.BroadcastParameters.Index,
              spotlight: {
                title: spotlight_t(['broadcastParameters', 'title']),
                subtitle: spotlight_t(['broadcastParameters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'certificates', 'title']),
              id: 'sub-menu-certificates-menus',
              icon: 'collection-image',
              meta: { isICOPLink: true },
              routerLink: Routes.Admin.System.Certificates,
              spotlight: {
                title: spotlight_t(['certificates', 'title']),
                subtitle: spotlight_t(['certificates', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'networkParameters', 'title']),
              id: 'sidebar-network-parameters',
              routerLink: Routes.NetworkParameters.Index,
              icon: 'input-antenna',
              permission: ['adminNetwork', [], 'GET'],
              meta: {
                bulkSpeakerManagementControlled: 'hide',
                isICOPLink: true,
              },
              spotlight: {
                title: spotlight_t(['networkParameters', 'title']),
                subtitle: spotlight_t(['networkParameters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'slpParameters', 'title']),
              id: 'sidebar-slp-parameters',
              routerLink: Routes.SlpParameters.Index,
              icon: 'input-antenna',
              permission: ['fusionAdminNetwork', [], 'GET'],
              meta: {
                bulkSpeakerManagementControlled: 'show',
                isICOPLink: true,
              },
              spotlight: {
                title: spotlight_t(['slpParameters', 'title']),
                subtitle: spotlight_t(['slpParameters', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'speakerSettings', 'title']),
              id: 'sidebar-speaker-settings',
              routerLink: Routes.SpeakerSettings.Index,
              icon: 'input-antenna',
              meta: {
                bulkSpeakerManagementControlled: 'show',
              },
              permission: ['speakerSettings', [], 'GET'],
              spotlight: {
                title: spotlight_t(['ipSpeakerSettings', 'title']),
                subtitle: spotlight_t(['ipSpeakerSettings', 'subtitle']),
              },
            },
            {
              title: core_t(['navigation', 'admin', 'settings', 'voiceMenus', 'title']),
              id: 'sub-menu-voice-menus',
              icon: 'phone-end',
              meta: { isICOPLink: true, permissionsOr: true },
              routerLink: Routes.VoiceMenus.Index,
              permission: [
                ['adminVoiceInbound', [], 'GET'],
                ['adminVoiceBroadcast', [], 'GET'],
                ['adminVoiceGeneral', [], 'GET'],
              ],
              spotlight: {
                title: spotlight_t(['voiceMenus', 'title']),
                subtitle: spotlight_t(['voiceMenus', 'subtitle']),
              },
            },
          ],
        },
      ],
    },
    {
      title: core_t(['navigation', 'reports', 'title']),
      id: 'sub-menu-reports',
      icon: 'assignment',
      children: [
        {
          title: core_t(['navigation', 'reports', 'user']),
          id: 'sidebar-users-report',
          routerLink: Routes.Reports.User.Index,
          icon: 'account-box',
          permission: ['usersReport', [], 'GET'],
          spotlight: {
            title: spotlight_t(['userReports', 'title']),
            subtitle: spotlight_t(['userReports', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'reports', 'anonymousUser']),
          id: 'sidebar-anonymous-user-report',
          routerLink: Routes.Reports.AnonymousUser.Index,
          icon: 'account-box-o',
          permission: ['anonymousUsersReport', [], 'GET'],
          spotlight: {
            title: spotlight_t(['anonUserReports', 'title']),
            subtitle: spotlight_t(['anonUserReports', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'reports', 'notificationHistory']),
          id: 'sidebar-notification-history',
          routerLink: Routes.Reports.NotificationHistory.Index,
          icon: 'notifications',
          permission: ['notificationHistoryReport', [], 'GET'],
          spotlight: {
            title: spotlight_t(['notificationHistoryReports', 'title']),
            subtitle: spotlight_t(['notificationHistoryReports', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'reports', 'confirmation']),
          id: 'sidebar-confirmation-reports',
          routerLink: Routes.Reports.Confirmation.Index,
          icon: 'dot-circle',
          permission: ['confirmationReport', [], 'GET'],
          spotlight: {
            title: spotlight_t(['confirmationReports', 'title']),
            subtitle: spotlight_t(['confirmationReports', 'subtitle']),
          },
        },
        {
          title: core_t(['navigation', 'reports', 'auditEvents']),
          id: 'sidebar-audit-events',
          routerLink: Routes.Reports.AuditEvents.Index,
          icon: 'assignment-check',
          permission: ['auditEventsReport', [], 'GET'],
          spotlight: {
            title: spotlight_t(['changeHistoryReports', 'title']),
            subtitle: spotlight_t(['changeHistoryReports', 'subtitle']),
          },
        },
      ],
    },
  ];
}
