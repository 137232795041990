import truncate from 'lodash/truncate';
import { PureComponent } from 'react';
import { Combobox, Icon } from 'singlewire-components';
import Routes from '../../core/route/Routes';
import { MobileApiIcopServer } from '../../mobile-api-types';
import { Button } from './components/Button';
import { FusionEndpointSelectProps } from './FusionEndpointSelectContainer';
import { ResolveDataSafely } from './ResolveDataSafely';
import { shared_t } from './SharedLocale';

export class FusionEndpointSelect extends PureComponent<FusionEndpointSelectProps> {
  render() {
    const { selectedICOPServer, confirmFusionServerChange, navigateTo } = this.props;

    return selectedICOPServer ? (
      <>
        <ResolveDataSafely<{ servers: Array<MobileApiIcopServer> }>
          resolve={{
            servers: {
              request: [
                'fusionExtensionEndpoints',
                [],
                'GET',
                { params: { skipEndpointHealthAssessment: true, limit: 500 } },
              ],
              reqAll: true,
            },
          }}
          render={({ servers }) => {
            return (
              <Combobox
                trigger={isOpen => (
                  <Button
                    id="fusion-server-select-dropdown-button"
                    color="gray"
                    variant="contained"
                    label={truncate(selectedICOPServer.name)}
                    startIcon={<Icon.ServerSelect color="inherit" />}
                    disabled={servers.length === 1}
                    endIcon={
                      servers.length === 1 ? null : isOpen ? (
                        <Icon.Collapse size="sm" color="inherit" />
                      ) : (
                        <Icon.Expand size="sm" color="inherit" />
                      )
                    }
                  />
                )}
                options={servers.map(server => ({
                  label: server.name,
                  value: server.id,
                  subtitle:
                    (server.config && server.config.description) ||
                    shared_t(['fusionServerSelect', 'label', 'noDescription']),
                  onSelect: () => confirmFusionServerChange(server.id),
                  icon: selectedICOPServer.id === server.id ? <Icon.Save size="sm" /> : <></>,
                }))}
                searchPlaceholder={shared_t(['label', 'search'])}
                customLabel={shared_t(['fusionServerSelect', 'label', 'manageServers'])}
                onCustomSelection={() => navigateTo(Routes.Servers.Index)}
                showCheckbox={false}
              />
            );
          }}
        />
      </>
    ) : null;
  }
}
