import {
  APNS_DESKTOP_EXTENSION_ID,
  CISCO_SPARK_EXTENSION_ID,
  DND_EXTENSION_ID,
  EMAIL_EXTENSION_ID,
  EVENT_SERVICE_EXTENSION_ID,
  ICOP_EXTENSION_ID,
  INBOUND_CAP_EXTENSION_ID,
  IOS_EXTENSION_ID,
  IP_CAMERAS_SERVICE_EXTENSION_ID,
  KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID,
  PHONE_CALL_EXTENSION_ID,
  REVERSE_SYNCING_VERSION,
  SMS_EXTENSION_ID,
  TEAMS_CHANNEL_EXTENSION_ID,
  TEAMS_CHAT_EXTENSION_ID,
  TWITTER_EXTENSION_ID,
  WEBEX_CALLING_EXTENSION_ID,
  ZOOM_EXTENSION_ID,
  ZOOM_SITES_EXTENSION_ID,
} from '../../constants';
import { registerResource } from '../../core/utils/localization';

export function buildPermissionsLabelKey(
  path: string,
  { version = 'V1', endpointId = '*' } = {}
): string {
  return `/extensions/${ICOP_EXTENSION_ID}/endpoints/${endpointId}/proxy/proxy/${version}${path}`;
}

export const SharedLocale = {
  en: {
    alert: {
      infoDefaultTitle: 'Note',
      warningDefaultTitle: 'Warning',
      errorDefaultTitle: 'Error',
      successDefaultTitle: 'Success',
    },
    table: {
      loading: 'Table is loading',
      filterAdded: 'Added Filter',
      filterRemoved: 'Removed Filter',
      searchUpdated: 'Search Updated',
      selection: 'Selection',
      selectAll: 'Select all {{count}} matching resources.',
      allSelectedOnPage: '{{count}} Resource(s) are selected.',
      allSelected: 'All {{count}} matching resources are selected.',
      noResults: "Your search or filter didn't return any results",
      error:
        'There was a problem fetching this information. Please try again. If problems continue, contact your system administrator.  {{error}}',
      numSelected: '{{count}} Selected',
      clearSelection: 'Clear Selection',
      showPrefix: 'Show {{count}}',
      showMoreResults: 'Show more results',
      firstPage: 'First Page',
      previousPage: 'Previous Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
      pageSize: '{{start}}-{{end}} of {{total}}',
      filter: 'Filter',
      itemCount: 'Table updated showing {{count}} out of {{total}} items',
    },
    deviceTypes: {
      app: 'App',
      desktop: 'Desktop',
      email: 'Email',
      m2mOutputPort: 'M2M Output Port',
      mobile: 'Mobile',
      msteamsChat: 'Microsoft Teams',
      multicastStream: 'Multicast Stream',
      multicastStreamDevice: 'Multicast Stream: {{name}}',
      other: 'Other*',
      otherDevice: 'Other',
      pagingGateway: 'Paging Gateway',
      pagingGateway2: 'Paging Gateway 2',
      phoneCall: 'Phone Call',
      phones: 'IP Phone',
      quickUrl: 'Quick URL',
      sms: 'SMS',
      speaker: 'IP Speaker',
      idn: 'Desktop Notifier',
    },
    label: {
      error: 'Error: ',
      seconds: 'Second(s)',
      secondsAbbreviation: 'Sec(s)',
      minutes: 'Minute(s)',
      minutesAbbreviation: 'Min(s)',
      hours: 'Hour(s)',
      hoursAbbreviation: 'Hr(s)',
      days: 'Day(s)',
      singlewireLogo: 'Singlewire Logo',
      chooseImage: 'Choose Image',
      dragAndDrop: 'Click here to upload a file or drag a file to automatically upload.',
      noUserCreated: 'No User Created',
      members: `Members`,
      or: 'or',
      expandSection: 'Expand Section',
      collapseSection: 'Collapse Section',
      phones:
        '{{tollFree}} (toll-free; U.S. only) or {{local}} (local; also valid internationally)',
      phonesLocal: '{{local}} (local; also valid internationally)',
      recipientName: 'Recipient Name',
      recipientType: 'Recipient Type',
      accountEmail: 'Account Email',
      aoiMarker: 'Area of Interest Marker, {{numRecipients}}',
      getPermission: 'Get Permission',
      putPermission: 'Put Permission',
      deletePermission: 'Delete Permission',
      allPermission: 'All Permission',
      pagingGateway2: 'Paging Gateway 2: {{macAddress}}',
      insertVariables: 'Insert Variable',
      search: 'Search...',
    },
    tooltip: {
      help: 'Learn more',
      downloadQRCode: 'Download QR Code',
      play: 'Play',
      paused: 'Paused',
      nonCustomizable: 'Contains non-customizable fields',
    },
    visibility: {
      auto: 'Auto',
      show: 'Show',
      hide: 'Hide',
    },
    notificationSummary: {
      deviceList: '{{name}} Device List',
    },
    notifications: {
      sendDetails: 'Sent {{dateTime}} by ',
      defaultInitiatorName: 'Server',
      defaultInitiationSourceDescription: 'Message initiated by system',
      cannotUpdateAttachments: 'Need additional permissions to modify media attachments.',
      button: {
        initiatorDevice: 'Initiator Device Details',
      },
      initiatorDevice: {
        modalTitle: 'Initiator Device Attributes',
        attributes: {
          name: 'Name',
          description: 'Description',
          IPAddress: 'IP Address',
          type: 'Type',
          location: 'Location',
          directoryNumbers: 'Directory Numbers',
        },
      },
    },
    initiationType: {
      ios_secure: { title: 'Mobile App' },
      ios_secure_v2: { title: 'Mobile App' },
      ios: { title: 'Mobile App' },
      android_secure: { title: 'Mobile App' },
      android_secure_v2: { title: 'Mobile App' },
      android: { title: 'Mobile App' },
      'iOS Device Secure': { title: 'Mobile App' },
      'Android Device Secure': { title: 'Mobile App' },
      'IcMobile Web': { title: 'Administration Console' },
      Windows: { title: 'Desktop Devices from Windows' },
      windows: { title: 'Desktop Devices from Windows' },
      windows_v2: { title: 'Desktop Devices from Windows' },
      cirrus_admin: { title: 'Administration Console' },
      cirrus_admin_v2: { title: 'Administration Console' },
      scenario: {
        standard: { title: 'Scenario' },
        'panic-button': { title: 'Panic Button Scenario' },
        integration: { title: 'Integration Scenario' },
      },
      'ca-redirect': 'CallAware Call Redirect',
      'ca-routing-request': 'CallAware Routing Request',
      'inbound-cap': 'Inbound CAP',
      'cucm-panic-button': 'Cisco IP Phones for Unified CM Integration Scenario',
      'webex-calling': 'Webex Calling',
      'poly-panic-button': 'Poly Panic Button',
      'zoom-callaware': 'CallAware for Zoom Integration Scenario',
      'dial-cast': 'DialCast',
      'inbound-email': 'Inbound Email',
      m2m: 'M2M',
      api: 'API',
      'inbound-rss': 'Inbound RSS',
      'scheduled-notification': 'Scheduled Notification',
      'bell-schedule': 'Bell Schedule',
      'area-of-interest-boundary-trigger': 'Area Of Interest Boundary Trigger',
      'cisco-webex-bot-eviction': 'Cisco Webex Bot Eviction',
      'cisco-webex': 'Cisco Webex',
      'night-bell': 'Night Bell',
      'tracking-event-notification': 'Tracking Event Notification',
      'conference-call': 'Conference Call',
      'scenario-launcher-failure': 'Integration Scenario Failure',
      'twitter-misconfiguration': 'Twitter Misconfiguration',
      'rostering-push': 'Roll Call',
      alarm: 'System Health Alarm',
      'confirmation-request-escalation-rule': 'Confirmation Request Escalation Rule',
      'area-of-interest': 'Area of Interest',
      'speakers-unregistered': 'System Health Speakers Unregistered Alarm',
      'fusion-server-yellow': 'System Health Yellow Server Alarm',
      'fusion-server-red': 'System Health Red Server Alarm',
      'license-violation': 'System Health License Violation Alarm',
      'sis-loader-failed': 'System Health School Information Import Alarm',
      'phone-cache-update-failed': 'System Health Phone Cache Alarm',
      'fusion-delta-sync-error-event': 'Server Sync Error State Alarm',
      'token-expiration': 'System Health Credential Expirations Alarm',
      'inbound-email-rule': 'Inbound Email Rule',
      'server-broadcast-unknown-type': 'On-Prem Server Broadcast',
      'kontaktio-wearable-panic-button': 'Kontakt Wearable Panic Button',
      'scenario-device-resolution-failed': 'API Device Failure',
      'potential-speaker-configuration-issue': 'Potential Speaker Configuration Issue Alarm',
    },
    incidents: {
      triggeredManually: 'Triggered Manually',
      completed: 'Completed',
      ongoing: 'Ongoing',
      editIncidentPlan: 'Edit Incident Plan',
      endIncident: 'End Incident',
    },
    incidentDetails: {
      viewConfirmationReport: 'View Confirmation Report',
      viewNotificationHistoryReport: 'View Notification History Report',
      viewNotificationDetails: 'View Notification Details',
    },
    recipients: {
      recipientDevicesSubtitle: 'View available device information for all recipients.',
      deviceName: 'Device Name',
      deviceIdentifier: 'Device Identifier',
      followUp: 'Follow-up',
      recipientsNoResponseSubtitle: 'View all recipients who have not responded{{sendFollowUp}}.',
      recipientsNoResponseFollowUpPortion: ' and send individual follow-up notifications',
      recipientsSubtitle:
        'View all recipients who responded with {{response}}{{dynamicReplyPart}}, and send individual follow-up notifications.',
      recipientDynamicReplySubtitle: ' and who received a reply of "{{dynamicReplyText}}"',
      subtitle: 'View all recipients and send individual follow-up notifications.',
      download: 'Download Recipients',
      andMore: '...and {{count}} more',
      followUpRecipientsNoConfirmation: 'Recipients who have not responded',
      followUpRecipientsWithConfirmation:
        'Recipients who responded with "{{confirmationResponse}}"{{dynamicReplyPart}}',
      followUpRecipientsDynamicReply: ' and received a reply of "{{dynamicReplyText}}"',
      followUpRecipients: 'Follow-up Recipients',
      allRecipients: 'All Recipients',
      devices: 'Recipient Devices',
      historicalEndpointId: 'Historical Endpoint Id: {{id}}',
      showAll: 'Show all {{total}} {{resourceName}}',
    },
    message: {
      validator: {
        invalid: 'Invalid value',
      },
    },
    exception: {
      title: 'An error was encountered',
      message:
        'InformaCast has encountered and logged an error. If problems continue, contact your system administrator.',
      homeButton: 'Home',
    },
    customizable: 'Customizable',
    nonCustomizable: 'Non-Customizable',
    ciscoSparkOutboundSystemManagement: 'Cisco Webex Outbound System Management',
    twitterOutboundSystemManagement: 'Twitter Outbound System Management',
    msTeamsOutboundSystemManagement: 'Microsoft Teams Outbound System Management',
    page: {
      title: 'InformaCast',
    },
    pager: {
      failedSearch:
        'Your search results have failed to load. Please refresh the page. If problems continue, contact your system administrator.',
      search: 'Search',
      noSearchResults: 'No {{resource}} found',
      noResults: 'No {{resource}}',
      queriedAttribute: 'Searched Attribute(s): {{attribute}}',
    },
    pagination: {
      firstPage: 'First Page',
      previousPage: 'Previous Page',
      lastPage: 'Last Page',
      nextPage: 'Next Page',
      totalEntries: 'Total Entries',
    },
    syncProgress: {
      title: 'Synchronization Progress',
      fullTitle: 'Synchronization Progress For {{resourceName}}',
      clickToViewDetails: '{{status}} Click to view details.',
      syncSuccess: 'Synchronization successful.',
      syncInProgress: 'Synchronization in progress.',
      syncValidationFailed: 'Synchronization failed.',
      syncFailed: 'Synchronization failed.',
      successValue: 'SUCCESS',
      pendingValue: 'PENDING',
      failedValue: 'FAILED',
      success: 'Successful',
      failed: 'Failed.',
      inProgress: 'In Progress.',
    },
    variable: {
      invalidVariable:
        'Variable must start with a letter and be 3-32 alphanumeric characters (including underscores) long.',
    },

    audioRecorder: {
      recordAudio: 'Record Audio',
      record: 'Record',
      uploadFile: 'Upload File',
      unsupportedBrowser:
        'Your current browser does not support audio recording, please switch to Chrome or Firefox',
      titleUnsupported: 'Unsupported Browser',
      titleMicAccessDenied: 'Microphone permission required',
      micAccessDenied:
        "In order to record an audio message, you need to grant 'Microphone' access permission in your browser for this website.",
    },

    characterCounter: {
      templateVarWarning:
        'Using template variables may cause the {{fieldDescription}} to exceed the allotted {{maxCount}} characters.',
      defaultFieldDesc: 'character count',
    },

    permissions: {
      globalPermissions: 'Global Permissions',
      commonPermissions: 'Common Permissions',
      commonPermissionTitle: '{{resource}}: {{name}}',
      expandAll: 'Expand All',
      collapseAll: 'Collapse All',
      post: 'Create',
      get: 'View',
      put: 'Update',
      delete: 'Delete',
      failedToFetch:
        'There was a problem retrieving the permissions. Please contact your system administrator if problems continue.',
      viewResourcePerms: 'View {{resource}} Permissions',
      shouldGrantFusionExtensionReadPermission:
        'For many on-premises server permissions it is also necessary to grant View permissions to Admin > Extensions > Extension Endpoints.',
      shouldGrantSitesReadPermission:
        'To correctly send scenarios, it may be necessary to grant View permissions to Notifications > Command Center > Sites.',
      shouldGrantSiteRolesReadPermission:
        'To correctly edit and create sites, it is also necessary to grant View permissions to Notifications > Command Center > Site Roles.',
      shouldShowIncidentManagementReadPermissionWarning:
        'In order to observe the activities associated with incident management, e.g. its message templates, scenarios, incidents, ' +
        'incident plans, reports, etc., it is necessary to grant view permissions to additional resources.',
      shouldShowApiDevicesPermissionWarning:
        'To correctly edit and create API devices, it is also necessary to grant View permission to Notifications > Command Center > Sites',
      shouldShowLoadRequestGetPermissionWarning:
        'To correctly view Cloud User Loaders and School Information Imports, it may be necessary to grant View permissions to Admin > Data Imports > Load Definitions.',
      shouldShowLoadRequestPostPermissionWarning:
        'To correctly create Cloud User Loaders and School Information Imports, it may be necessary to grant Create permissions to Admin > Data Imports > Load Definitions.',
      shouldShowSystemHealthPermissionWarning:
        'In order to observe the activities associated with system health, e.g. reports in order to view licenses, it is necessary to grant view permissions to additional resources.',
      grantNow: 'Grant Now',
      legendButton: 'View Legend',
      legend: {
        none: 'This permission (and its child permissions, if applicable) is not set. Clicking this permission will set it (and any child permissions).',
        all: 'This permission (or all of its child permissions, if applicable) is set. Clicking this permission will clear it (and any child permissions).',
        completable:
          'This permission could be further completed. Some, but not all, child permissions are set. Clicking this permission will set all missing child permissions.',
        promotable:
          'This permission could be promoted. This permission (or some, and possibly all, child permissions) is set in a child domain. Click this permission will set all child permissions and promote them to the current domain.',
        allDisabled:
          'This permission is set in the parent (or an ancestor) domain. You have this permission but it cannot be changed while working in the current domain.',
        mixed:
          'This permission has some child permissions set in various domains, including this domain and its parent and ancestor domains. Clicking this will complete or promote all child permissions.',
        mixedDisabled:
          'This permission has some child permissions set in various domains, including this domain and its parent and ancestor domains. Not all child permissions can be changed, but some changes may be available by working with those child permissions directly or by working in a parent domain.',
      },
      scenarioType: {
        standard: 'Standard',
        'panic-button': 'Panic Button',
        integration: 'Integration',
        unknown: 'Unknown',
      },
    },
    resourceCommonPermissions: '{{resource}} Permissions',
    commonPermsSubtitleSecurityGroup: 'a security group',
    commonPermsSubtitleUser: 'an application or user',
    resourceCommonPermissionsSubtitle:
      'View/manage common permissions, which are a more refined way of adding permissions to {{resource}}.',
    sitePermissionsSubtitle:
      'View/manage common permissions, which are a more refined way of adding permissions to {{resource}}. In order to fully utilize Sites update functionality, it is recommended that the Notifications > Command Center > Site Roles > View global permission is also granted.',
    scenarioPermissionsNote:
      "If you're trying to grant send permissions to the scenarios listed on this page, return to the global permissions for this {{resource}}, navigate to Notifications > Command Center > Scenario Notifications, and select the Create checkbox.",
    activationGroups: {
      deviceInHigherPriority: 'This device is activated by a higher priority activation group.',
      phoneFallbackToGatherer:
        "This Cisco IP phone for Unified CM is activated by a different on-premises server acting as the Cisco Unified CM cluster's phone gatherer.",
    },
    eula: {
      rapidSOSEULA: 'RapidSOS EULA',
      accept: 'Accept',
      title: 'End User License Agreement',
      subtitle: 'Please review the following and accept the following terms.',
    },
    permissionGroups: {
      '/users': 'Users',
      '/user-devices': 'User Desktop Devices',
      '/users/*/notifications': 'User Notifications',
      '/users/*/sent-notifications': 'User Sent Notifications',
      '/users/*/locations': 'User Locations',
      '/users/*/facilities': 'User Sites',
      '/users/*/extensions': 'User Extensions',
      '/users/*/notifications/*/activities': 'User Notification Activities',
      '/users/*/subscriptions': 'User Subscriptions',
      '/users/*/message-templates': 'User Message Templates',
      '/users/*/subscribable-distribution-lists': 'User Distribution List Subscriptions',
      '/users/*/profile': 'User Profile',
      '/users/*/user-permissions': 'User Permissions',
      '/users/*/devices': 'User Devices',
      '/users/*/domains': 'User Domains',
      '/users/*/security-groups': 'User Security Groups',
      '/users/*/tokens': 'User Tokens',
      '/users/*/tracking-events/*/activities': 'User Tracking Event Activities',
      '/users/*/scim-subscriptions': 'Cloud User Loader Groups Subscriptions',
      '/users/*/scim-security-groups': 'Cloud User Loader Groups Security Groups',
      '/distribution-lists': 'Distribution Lists',
      '/distribution-lists/*/domains': 'Distribution List Domains',
      '/distribution-lists/*/user-subscriptions': 'Distribution List User Subscriptions',
      '/distribution-lists/*/scim-groups': 'Distribution List User Loader Groups',
      '/device-groups': 'Device Groups',
      '/device-groups/*/domains': 'Device Group Domains',
      '/devices': 'Devices',
      [buildPermissionsLabelKey('/Admin/System/recipientgroups/*/clearPhones', {
        endpointId: '*',
      })]: 'Admin Telephony Recipient Groups Clear Phones',
      [buildPermissionsLabelKey('/Devices/phones/refresh', {
        endpointId: '*',
      })]: 'Refresh Devices',
      '/areas-of-interest': 'Areas of Interest',
      '/areas-of-interest/*/boundary-triggers': 'Area of Interest Boundary Triggers',
      '/areas-of-interest/*/boundary-triggers/*/activities':
        'Area of Interest Boundary Trigger Activities',
      '/tracking-events': 'Tracking Events',
      '/tracking-events/*/activities': 'Tracking Event Activities',
      '/failover-pairs': 'Failover Pairs',
      '/ip-speakers': 'IP Speakers and Desktop Notifiers',
      '/ip-speaker-settings': 'IP Speakers',
      '/ip-speaker-sip-parameters': 'SIP Speaker Parameters',
      '/ip-speaker-jobs': 'IP Speaker Actions',
      [buildPermissionsLabelKey('/IPSpeakers', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/*/test/*', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Test (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/reboot', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Reboot (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/reboot/all', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Reboot All (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/adjustvolume/*', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Adjust Volume (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/adjustvolume/*/all', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Adjust Volume All (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/delete/nonregistered', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Delete Non-Registered Speakers (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/new', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers New (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/new/*', {
        endpointId: '*',
      })]: 'IP Speakers New (by MAC Address) (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/new/*/test/*', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Test New (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/new/reboot', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Reboot New (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/import', {
        endpointId: '*',
      })]: 'IP Speakers Import (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/import/spreadsheet', {
        endpointId: '*',
      })]: 'IP Speakers Import Spreadsheet (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/filters/attributes', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Filter Attributes (LEGACY)',
      [buildPermissionsLabelKey('/IPSpeakers/statistics', {
        endpointId: '*',
      })]: 'IP Speakers and Desktop Notifiers Statistics (LEGACY)',
      [buildPermissionsLabelKey('/Plugins/LPI/configuration', {
        endpointId: '*',
      })]: 'LPI Configuration',
      [buildPermissionsLabelKey('/Plugins/LPI/configuration/testTone', {
        endpointId: '*',
      })]: 'LPI Configuration Test Tone',
      [buildPermissionsLabelKey('/Plugins/LPI/serverGroups', {
        endpointId: '*',
      })]: 'LPI Server Groups',
      [buildPermissionsLabelKey('/Plugins/LPI/serverGroups/*/servers', {
        endpointId: '*',
      })]: 'LPI Server Groups Servers',
      [buildPermissionsLabelKey('/Plugins/LPI/pagingDevices', {
        endpointId: '*',
      })]: 'LPI Paging Devices',
      [buildPermissionsLabelKey('/Plugins/LPI/pagingDevices/*/zones', {
        endpointId: '*',
      })]: 'LPI Paging Devices Zones',
      [buildPermissionsLabelKey('/Plugins/LPI/GenerateMACAddress', {
        endpointId: '*',
      })]: 'LPI Generate MAC Address',
      [buildPermissionsLabelKey('/Plugins/LPI/pagingDevices/currentActivity', {
        endpointId: '*',
      })]: 'LPI Paging Devices Current Activity',
      [buildPermissionsLabelKey('/Plugins/LPI/pagingDevices/currentActivity/broadcast/stop', {
        endpointId: '*',
      })]: 'LPI Paging Devices Current Activity Broadcast Stop',
      [buildPermissionsLabelKey('/Plugins/LPI/pagingDevices/currentActivity/test/stop/*', {
        endpointId: '*',
      })]: 'LPI Paging Devices Current Activity Test Stop',
      [buildPermissionsLabelKey('/Plugins/LPI/license', { endpointId: '*' })]: 'LPI License',
      [buildPermissionsLabelKey('/Plugins/PagingGateway/gateways/*', {
        endpointId: '*',
      })]: 'Paging Gateways 1x',
      [buildPermissionsLabelKey('/PagingGateways/gateways', {
        endpointId: '*',
      })]: 'Paging Gateways 2x',
      [buildPermissionsLabelKey('/PagingGateways/PG2Activated', {
        endpointId: '*',
      })]: 'Check Paging Gateways 2x Active',
      [buildPermissionsLabelKey('/PagingGateways/gateways/*/*', {
        endpointId: '*',
      })]: 'Execute Commands on Paging Gateways 2x',
      [buildPermissionsLabelKey('/PagingGateways/gateways/*/gmsSubscriptions', {
        endpointId: '*',
      })]: 'View Paging Gateways 2x Multicast Addresses',
      [buildPermissionsLabelKey('/PagingGateways/gateways/*/password', {
        endpointId: '*',
      })]: 'View Paging Gateways 2x Password',
      [buildPermissionsLabelKey('/PagingGateways/Config', {
        endpointId: '*',
      })]: 'Paging Gateways 2x Global Settings',
      [buildPermissionsLabelKey('/PagingGateways/ForcefulResetAll', {
        endpointId: '*',
      })]: 'Immediate Reset All Paging Gateways 2x',
      [buildPermissionsLabelKey('/PagingGateways/GracefulResetAll', {
        endpointId: '*',
      })]: 'Graceful Reset All Paging Gateways 2x',
      [buildPermissionsLabelKey('/Plugins/M2M/schedules', {
        endpointId: '*',
      })]: 'M2M Schedules',
      [buildPermissionsLabelKey('/Plugins/M2M/contactClosures', {
        endpointId: '*',
      })]:
        `M2M Contact Closures (For on-premises servers version below ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/M2M/contactClosures/*/inputPorts', {
        endpointId: '*',
      })]:
        `M2M Contact Closures Input Ports (For on-premises servers version below ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/M2M/contactClosures/*/outputPorts', {
        endpointId: '*',
      })]:
        `M2M Contact Closures Output Ports (For on-premises servers version below ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/M2M/contactClosures', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `M2M Contact Closures  (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/M2M/contactClosures/*/inputPorts', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `M2M Contact Closures Input Ports (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/M2M/contactClosures/*/outputPorts', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `M2M Contact Closures Output Ports (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION})`,
      [`/extensions/${TEAMS_CHAT_EXTENSION_ID}/proxy/api/v1/status`]: 'Request Bot Status',
      [`/extensions/${TEAMS_CHAT_EXTENSION_ID}/proxy/api/v1/actions`]:
        'Execute Actions on Teams Bot',
      [`/extensions/${TEAMS_CHANNEL_EXTENSION_ID}/proxy/api/v1/all-teams`]:
        'View All Microsoft Teams',
      [`/extensions/${TEAMS_CHANNEL_EXTENSION_ID}/proxy/api/v1/all-channels`]:
        'View All Microsoft Teams Channels',
      [buildPermissionsLabelKey('/Plugins/QuickURL/devices', {
        endpointId: '*',
      })]: 'Quick URL Devices',
      [buildPermissionsLabelKey('/Plugins/QuickURL/license', {
        endpointId: '*',
      })]: 'Quick URL License',
      '/collaboration-groups': 'Outbound Systems',
      '/collaboration-groups/*/domains': 'Outbound System Domains',
      [`/extensions/${TWITTER_EXTENSION_ID}/proxy/api/v1/request-token`]:
        'Authorize InformaCast to Tweet on your behalf',
      [`/extensions/${CISCO_SPARK_EXTENSION_ID}/proxy/api/v1/spaces`]: 'View Existing Webex Spaces',
      [`/extensions/${CISCO_SPARK_EXTENSION_ID}/proxy/api/v1/session`]:
        'Invite/Remove Bot & Your Webex Space Management',
      [buildPermissionsLabelKey('/RecipientGroups', {
        endpointId: '*',
      })]: 'Recipient Groups',
      '/notifications': 'Notifications',
      '/notifications/*/activities': 'Notification Activities',
      '/notifications/*/domains': 'Notification Domains',
      '/notifications/*/recipients': 'Notification Recipients',
      '/message-templates': 'Message Templates',
      '/message-templates/*/domains': 'Message Template Domains',
      '/confirmation-requests': 'Confirmation Requests',
      '/confirmation-requests/*/domains': 'Confirmation Request Domains',
      '/confirmation-requests/*/dynamic-replies': 'Confirmation Request Dynamic Replies',
      '/confirmation-requests/*/escalation-rules': 'Confirmation Request Escalation Rules',
      '/sites': 'Sites',
      '/sites-management': 'Sites Import',
      '/sites/*/domains': 'Site Domains',
      '/site-roles': 'Site Roles',
      '/site-roles/*/domains': 'Site Role Domains',
      '/facilities': 'Site Access Points',
      '/facilities/*/users': 'Manage Site Users',
      '/scenarios': 'Scenarios',
      '/scenarios/*/rule-actions': 'API Connectors',
      '/scenarios/*/rule-actions/*/test': 'API Connector Tests',
      '/rule-actions': 'API Connectors',
      '/incident-plans': 'Incident Plans',
      '/incident-plans/*/resources': 'Incident Plan Resources',
      '/incident-plans/*/domains': 'Incident Plan Domains',
      '/incidents': 'Incidents',
      '/incidents/*/resources': 'Incident Resources',
      '/incidents/*/domains': 'Incident Domains',
      '/scenarios/*/domains': 'Scenario Domains',
      '/scenario-notifications': 'Scenario Notifications',
      '/dialcast-dialing-configurations': 'Dialing Configurations',
      '/dialcast-phone-exceptions': 'Phone Exceptions',
      [buildPermissionsLabelKey('/Admin/DialCast/dialingConfigurations', {
        endpointId: '*',
      })]:
        `DialCast Dialing Configurations (For on-premises servers version below ${REVERSE_SYNCING_VERSION}) (LEGACY)`,
      [buildPermissionsLabelKey('/Admin/DialCast/dialingConfigurations', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `DialCast Dialing Configurations (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION}) (LEGACY)`,
      [buildPermissionsLabelKey('/Admin/DialCast/phoneExceptions', {
        endpointId: '*',
      })]: 'DialCast Phone Exceptions (LEGACY)',
      [buildPermissionsLabelKey('/Plugins/CallAware/configuration', {
        endpointId: '*',
      })]: 'CallAware Configuration',
      [buildPermissionsLabelKey('/Plugins/CallAware/configuration/cucms/*', {
        endpointId: '*',
      })]: 'CallAware Configuration Cisco Unified CMs',
      [buildPermissionsLabelKey('/Plugins/CallAware/configuration/cucms/restore/*', {
        endpointId: '*',
      })]: 'CallAware Configuration Cisco Unified CM Restore',
      [buildPermissionsLabelKey('/Plugins/CallAware/callRedirects', {
        endpointId: '*',
      })]:
        `CallAware Call Redirects (For on-premises servers version below ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/CallAware/callRedirects', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `CallAware Call Redirects (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/CallAware/routingRequests', {
        endpointId: '*',
      })]:
        `CallAware Routing Requests (For on-premises servers version below ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/CallAware/routingRequests', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `CallAware Routing Requests (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/CallAware/diagnostics', {
        endpointId: '*',
      })]: 'CallAware Diagnostics',
      [buildPermissionsLabelKey('/Plugins/CallAware/callHistory', {
        endpointId: '*',
      })]: 'CallAware Call History',
      [buildPermissionsLabelKey('/Plugins/CallAware/callHistory/list', {
        endpointId: '*',
      })]: 'CallAware Call History List',
      [buildPermissionsLabelKey('/Plugins/CallAware/callHistory/audio', {
        endpointId: '*',
      })]: 'CallAware Call History Audio',
      [buildPermissionsLabelKey('/Plugins/CallAware/callHistory/audioStream', {
        endpointId: '*',
      })]: 'CallAware Call History Live Audio',
      [buildPermissionsLabelKey('/active-callaware-call', {
        endpointId: '*',
      })]: 'CallAware Live Call Status',
      [buildPermissionsLabelKey('/Plugins/CallAware/license', {
        endpointId: '*',
      })]: 'CallAware License',
      '/bell-schedules': 'Bell Schedules',
      '/bell-schedules/*/domains': 'Bell Schedule Domains',
      bellScheduleBulkExceptions: 'Bulk Bell Schedule Exceptions',
      '/ring-lists': 'Ring Lists',
      '/ring-lists/*/domains': 'Ring List Domains',
      [buildPermissionsLabelKey('/Plugins/NightBell/diagnostics', {
        endpointId: '*',
      })]: 'Night Bell Diagnostics',
      [buildPermissionsLabelKey('/Plugins/NightBell/configuration', {
        endpointId: '*',
      })]: 'Night Bell Configuration',
      [buildPermissionsLabelKey('/Plugins/NightBell/configuration/cucms/*', {
        endpointId: '*',
      })]: 'Night Bell Configuration Cisco Unified CMs',
      [buildPermissionsLabelKey('/Plugins/NightBell/configuration/cucms/restore/*', {
        endpointId: '*',
      })]: 'Night Bell Configuration Cisco Unified CM Restore',
      [buildPermissionsLabelKey('/Plugins/NightBell/triggers', {
        endpointId: '*',
      })]: `Night Bell Triggers (For on-premises servers version below ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/NightBell/triggers', {
        endpointId: '*',
        version: 'Fusion/V1',
      })]:
        `Night Bell Triggers (For on-premises servers version at or above ${REVERSE_SYNCING_VERSION})`,
      [buildPermissionsLabelKey('/Plugins/NightBell/license', {
        endpointId: '*',
      })]: 'Night Bell License',
      '/inbound-cap-rules': 'Inbound CAP Rules',
      [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/test-rule`]: 'Inbound CAP Rules Test',
      [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/test-cap-message`]:
        'Inbound CAP Rules Test CAP Message',
      [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/language-code-list`]:
        'Inbound CAP Language Code List',
      [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/lat-lon-lookup`]:
        'Inbound CAP Lat/Long Lookup',
      [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/cap/event`]: 'Inbound CAP Create Event',
      '/inbound-email': 'Inbound Email',
      '/inbound-email/*/outbound-rules': 'Outbound Rules',
      '/inbound-rss-feeds': 'Inbound RSS',
      [`/extensions/${ZOOM_SITES_EXTENSION_ID}/proxy/api/v1/sites`]: 'Retrieve Sites from Zoom',
      [`/extensions/${ZOOM_EXTENSION_ID}/proxy/api/v1/status`]: 'Configure Integration',
      [`/extensions/${ZOOM_EXTENSION_ID}/proxy/api/v1/account`]: 'Remove Disconnected Integration',
      [`/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/api/v1/integration-status`]:
        'View Integration Status',
      [`/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/api/v1/integration-actions`]:
        'Configure Integration',
      [`/extensions/${WEBEX_CALLING_EXTENSION_ID}/proxy/api/v1/webex-calling`]:
        'Configure Webex Calling',
      [`/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/api/v1/sites`]:
        'Retrieve Sites from Kontakt.io',
      [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/disabled-webhooks`]: 'Disabled Webhooks',
      [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/event-action-results`]:
        'Event Action Results',
      [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/webhooks/*`]: 'Webhooks',
      [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/webhooks/*/enable`]: 'Webhooks Enable',
      [`/extensions/${IP_CAMERAS_SERVICE_EXTENSION_ID}/proxy/ip-cameras-vendor-config`]:
        'IP Camera Vendor Configs',
      [`/extensions/${IP_CAMERAS_SERVICE_EXTENSION_ID}/proxy/ip-camera-jobs`]: 'IP Camera Jobs',
      '/scheduled-notifications': 'Scheduled Notifications',
      '/notifications/*/devices': 'Notification Devices',
      '/notification-profiles': 'Notification Profiles',
      '/notification-profiles/*/settings': 'Notification Profile Settings',
      '/notification-profiles/*/settings/*/attachments':
        'Notification Profile Settings Attachments',
      '/notification-profiles/*/domains': 'Notification Profile Domains',
      '/tracking-event-notifications': 'Tracking Event Notifications',
      '/load-definitions': 'User Loaders',
      '/load-definitions/*/domains': 'User Loader Domains',
      '/load-definitions/*/load-requests': 'User Loader Load Requests',
      '/load-definitions/*/security-group-mappings': 'User Loader Security Group Mappings',
      '/load-definitions/*/distribution-list-mappings': 'User Loader Distribution List Mappings',
      '/load-definitions/*/user-type-mappings': 'User Loader User Type Mappings',
      '/scim-users': 'Cloud User Loader Users',
      '/scim-users/*/groups': 'Cloud User Loader User Groups',
      '/scim-groups': 'Cloud User Loader Groups',
      '/scim-groups/*/members': 'Cloud User Loader Group Members',
      '/scim-groups/*/domains': 'Cloud User Loader Group Domains',
      '/idps': 'Identity Providers',
      '/brandings': 'Brandings',
      '/domains': 'Domains',
      '/domains/*/bell-schedules': 'Domain Bell Schedules',
      '/domains/*/collaboration-groups': 'Domain Outbound Systems',
      '/domains/*/confirmation-requests': 'Domain Confirmation Requests',
      '/domains/*/device-groups': 'Domain Device Groups',
      '/domains/*/distribution-lists': 'Domain Distribution Lists',
      '/domains/*/incidents': 'Domain Incidents',
      '/domains/*/incident-plans': 'Domain Incident Plans',
      '/domains/*/load-definitions': 'Domain User Loaders',
      '/domains/*/message-templates': 'Domain Message Templates',
      '/domains/*/notification-profiles': 'Domain Notification Profiles',
      '/domains/*/notifications': 'Domain Notifications',
      '/domains/*/ring-lists': 'Domain Ring Lists',
      '/domains/*/scenarios': 'Domain Scenarios',
      '/domains/*/security-groups': 'Domain Security Groups',
      '/domains/*/site-roles': 'Domain Site Roles',
      '/domains/*/sites': 'Domain Sites',
      '/domains/*/users': 'Domain Users',
      '/security-groups': 'Security Groups',
      '/security-groups/*/members': 'Security Group Members',
      '/security-groups/*/permissions': 'Security Group Permissions',
      '/security-groups/*/scim-groups': 'Security Group User Loader Groups',
      '/security-groups/*/domains': 'Security Group Domains',
      '/settings': 'Time Zone and Locale',
      '/activation-groups': 'Activation Groups',
      '/cucm-clusters/*/fusion-servers/phone-gatherer': 'Phone Gatherer',
      '/applications': 'Applications',
      '/installed-applications': 'Manage Status',
      [buildPermissionsLabelKey('/EventSubscriptions', {
        endpointId: '*',
      })]: 'Event Subscriptions',
      [buildPermissionsLabelKey('/EventSubscriptions/*/events', {
        endpointId: '*',
      })]: 'Event Subscription Events',
      [buildPermissionsLabelKey('/Admin/Telephony/cucmClusters', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM Clusters',
      [buildPermissionsLabelKey('/Admin/Telephony/cucmClusters/primary', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM Clusters Primary',
      [buildPermissionsLabelKey('/Admin/Telephony/cucmClusters/*/trustInfo', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM Cluster Security',
      [buildPermissionsLabelKey('/Admin/Telephony/cucmExternalCallControl/keepalive', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM External Call Control Keep Alive',
      [buildPermissionsLabelKey('/Admin/Telephony/cucmExternalCallControl/webServiceURLs', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM External Call Control Web Service URLs',
      [buildPermissionsLabelKey('/Admin/Telephony/cucmExternalCallControl/secureWebServiceURLs', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM External Call Control Secure Web Service URLs',
      [buildPermissionsLabelKey(
        '/Admin/Telephony/cucmExternalCallControl/webServiceURLs/chooseAnotherToken',
        {
          endpointId: '*',
        }
      )]: 'Admin Telephony Cisco Unified CM External Call Control Regenerate Token',
      // I wonder if this gets used anymore?
      [buildPermissionsLabelKey('/Admin/Telephony/cucmPhoneUpdates', {
        endpointId: '*',
      })]: 'Admin Telephony Cisco Unified CM Phone Updates',
      [buildPermissionsLabelKey('/Admin/Telephony/ctiCertificates/list', {
        endpointId: '*',
        version: 'V2',
      })]: 'View',
      [buildPermissionsLabelKey('/Admin/Telephony/ctiCertificates/install', {
        endpointId: '*',
      })]: 'Install',
      [buildPermissionsLabelKey('/Admin/Telephony/ctiCertificates/delete', {
        endpointId: '*',
      })]: 'Delete',
      [buildPermissionsLabelKey('/Admin/Voice/General', {
        endpointId: '*',
      })]: 'Admin Voice General',
      [buildPermissionsLabelKey('/Admin/Voice/General/Audio/*', {
        endpointId: '*',
      })]: 'Admin Voice General Audio',
      [buildPermissionsLabelKey('/Admin/Voice/General/Audio/*/*', {
        endpointId: '*',
      })]: 'Admin Voice General Voice Audio',
      [buildPermissionsLabelKey('/Admin/Voice/General/restore', {
        endpointId: '*',
      })]: 'Admin Voice General Restore',
      [buildPermissionsLabelKey('/Admin/Voice/Inbound', {
        endpointId: '*',
      })]: 'Admin Voice Inbound',
      [buildPermissionsLabelKey('/Admin/Voice/Inbound/Audio/*', {
        endpointId: '*',
      })]: 'Admin Voice Inbound Audio',
      [buildPermissionsLabelKey('/Admin/Voice/Inbound/Audio/*/*', {
        endpointId: '*',
      })]: 'Admin Voice Inbound Voice Audio',
      [buildPermissionsLabelKey('/Admin/Voice/Inbound/restore', {
        endpointId: '*',
      })]: 'Admin Voice Inbound Restore',
      [buildPermissionsLabelKey('/Admin/Voice/Broadcast', {
        endpointId: '*',
      })]: 'Admin Voice Broadcast',
      [buildPermissionsLabelKey('/Admin/Voice/Broadcast/Audio/*', {
        endpointId: '*',
      })]: 'Admin Voice Broadcast Audio',
      [buildPermissionsLabelKey('/Admin/Voice/Broadcast/Audio/*/*', {
        endpointId: '*',
      })]: 'Admin Voice Broadcast Voice Audio',
      [buildPermissionsLabelKey('/Admin/Voice/Broadcast/restore', {
        endpointId: '*',
      })]: 'Admin Voice Broadcast Restore',
      [buildPermissionsLabelKey('/Admin/System/callDetailConfig', {
        endpointId: '*',
      })]: 'Admin System Call Detail Config',
      [buildPermissionsLabelKey('/Admin/SIP/access', {
        endpointId: '*',
      })]: 'SIP Access View',
      sipAccessUpdate: 'SIP Access Update',
      [buildPermissionsLabelKey('/Admin/SIP/access', { endpointId: '*' })]: 'SIP Access',
      [buildPermissionsLabelKey('/Admin/SIP/access/allow', {
        endpointId: '*',
      })]: 'SIP Access Allow',
      [buildPermissionsLabelKey('/Admin/SIP/access/deny', {
        endpointId: '*',
      })]: 'SIP Access Deny',
      [buildPermissionsLabelKey('/Admin/SIP/access/restore', {
        endpointId: '*',
      })]: 'SIP Access Restore',
      [buildPermissionsLabelKey('/Admin/SIP/access/directives', {
        endpointId: '*',
      })]: 'SIP Access Directives',
      [buildPermissionsLabelKey('/Admin/SIP/authentication', {
        endpointId: '*',
      })]: 'SIP Call Security',
      [buildPermissionsLabelKey('/Admin/SIP/authentication/restore', {
        endpointId: '*',
      })]: 'SIP Call Security Restore',
      [buildPermissionsLabelKey('/Admin/SIP/userCredentials', {
        endpointId: '*',
      })]: 'SIP User Credentials',
      [buildPermissionsLabelKey('/Admin/SIP/certificates', {
        endpointId: '*',
      })]: 'SIP Certificates',
      [buildPermissionsLabelKey('/Admin/SIP/certificates/*', {
        endpointId: '*',
      })]: 'SIP Certificates View and Download/Delete',
      [buildPermissionsLabelKey('/Admin/SIP/certificates/view', {
        endpointId: '*',
      })]: 'SIP Certificates Details View',
      [buildPermissionsLabelKey('/Admin/SIP/stack', { endpointId: '*' })]: 'SIP Stack',
      [buildPermissionsLabelKey('/Admin/SIP/stack/restore', {
        endpointId: '*',
      })]: 'SIP Stack Restore',
      [buildPermissionsLabelKey('/Admin/SIP/restart', { endpointId: '*' })]: 'SIP Restart',
      [buildPermissionsLabelKey('/Admin/SIP/accounts', {
        endpointId: '*',
      })]: 'SIP Accounts',
      [buildPermissionsLabelKey('/Admin/SIP/serverGroups', {
        endpointId: '*',
      })]: 'SIP Server Groups',
      [buildPermissionsLabelKey('/Admin/SIP/registrations', {
        endpointId: '*',
      })]: 'SIP Registrations',
      [buildPermissionsLabelKey('/Admin/SIP/dnsSrvOverview', {
        endpointId: '*',
      })]: 'DNS SRV Records',
      '/extensions': 'Extensions',
      '/extensions/*/attachments': 'Extension Attachments',
      [`/extensions/${ICOP_EXTENSION_ID}/endpoints`]: 'Extension Endpoints',
      [`/extensions/${ICOP_EXTENSION_ID}/endpoints/*/uploads`]: 'Extension Endpoints Uploads',
      [`/extensions/${ICOP_EXTENSION_ID}/endpoints/*/mappings`]: 'Extension Endpoints Mappings',
      [`/extensions/${ICOP_EXTENSION_ID}/endpoints/*/activities`]: 'Extension Endpoints Activities',
      [`/extensions/${ICOP_EXTENSION_ID}/endpoints/*/devices`]: 'Extension Endpoints Devices',
      [`/extensions/${ICOP_EXTENSION_ID}/provider-public-key/delete`]: 'Rotate Encryption Key',
      [`/extensions/${APNS_DESKTOP_EXTENSION_ID}`]: 'InformaCast for Mac/Windows',
      [`/extensions/${DND_EXTENSION_ID}`]: 'Do Not Disturb',
      [`/extensions/${EMAIL_EXTENSION_ID}`]: 'Email',
      [`/extensions/${IOS_EXTENSION_ID}`]: 'InformaCast for Android/ iOS',
      [`/extensions/${SMS_EXTENSION_ID}`]: 'SMS',
      [`/extensions/${PHONE_CALL_EXTENSION_ID}`]: 'Calling',
      [`/extensions/${PHONE_CALL_EXTENSION_ID}/proxy/api/v1/outgoing-numbers`]:
        'Calling Verify Outbound Number',
      '/triggers': 'Triggers',
      '/integrations': 'Integrations',
      '/outboundIpaws': 'IPAWS',
      [buildPermissionsLabelKey('/Admin/overview', {
        endpointId: '*',
      })]: 'Server Configuration',
      [buildPermissionsLabelKey('/Admin/Network', {
        endpointId: '*',
      })]: 'Network Parameters',
      [buildPermissionsLabelKey('/Admin/Broadcast', {
        endpointId: '*',
      })]: 'Broadcast Parameters',
      [buildPermissionsLabelKey('/Admin/Network/timeouts', {
        endpointId: '*',
      })]: 'Network Timeouts',
      [buildPermissionsLabelKey('/Admin/messageCodes', {
        endpointId: '*',
      })]: 'Admin Message Codes',
      [buildPermissionsLabelKey('/Admin/localizationData', {
        endpointId: '*',
      })]: 'Admin Localization Data',
      [buildPermissionsLabelKey('/Admin/System/serverCertificate/view', {
        endpointId: '*',
      })]: 'View Server Certificates',
      [buildPermissionsLabelKey('/Admin/System/serverCertificate/download', {
        endpointId: '*',
      })]: 'Download Server Certificates',
      [buildPermissionsLabelKey('/Admin/System/clientCertificates', {
        endpointId: '*',
      })]: 'List Client Certificates',
      [buildPermissionsLabelKey('/Admin/System/clientCertificates/view/*', {
        endpointId: '*',
      })]: 'View Client Certificate Details',
      [buildPermissionsLabelKey('/Admin/System/isAutoImportingCertificatesEnabled', {
        endpointId: '*',
      })]: 'View SSL Parameters',
      updatePermissionAutoImporting: 'Update Auto Importing Certificates',
      [buildPermissionsLabelKey('/Admin/System/disableAutoImportingCertificates', {
        endpointId: '*',
      })]: 'Disable Auto Importing Certificates',
      [buildPermissionsLabelKey('/Admin/System/enableAutoImportingCertificates', {
        endpointId: '*',
      })]: 'Enable Auto Importing Certificates',
      '/admin/SystemHealth/AlarmActions/Get': 'View Alarms',
      '/admin/SystemHealth/AlarmEvents/Create': 'Create Alarm Events',
      '/admin/SystemHealth/AlarmActions': 'Manage Alarms',
      '/reports': 'Reports',
      '/test': 'permissions list test1',
      '/test2': 'permissions list test2',
    },
    phoneCache: {
      updateTooltip:
        'Discover current IP phone information from Cisco Unified Communications Manager (may be time consuming)',
      modalBody:
        "Click the <strong>OK</strong> button to discover all phones' IP addresses within your <0>{{resourceName}}</0> <strong>from Cisco Unified " +
        'Communications Manager</strong>.<br><br>' +
        '<strong>Note:</strong> Because this process may take some time, you should only perform this action if you have made phone-affecting changes ' +
        'and need them to be immediately detected by InformaCast.<br><br>' +
        "For <1>HTTP-activated phones</1>,  InformaCast will update the phones' <strong>Authentication Server</strong> and " +
        '<strong>Secure Authentication URL</strong> fields <strong>in Cisco Unified CM</strong> during the next phone update (by default, once an hour). ' +
        'However, phones won’t pick up any changes until they reboot, which is controlled by your <2>phone reset strategy</2>.',
      modalTitle:
        'Re-discover Cisco IP Phones for Unified CM via On-premises Server {{selectedServerName}}',
      title: 'Phone Cache Update Results',
    },
    deviceAttributes: {
      modalTitle: 'Device Attributes',
      descriptiveText: 'Descriptive Text',
      defunct: 'Defunct: {{device}}',
      pagingGateway: {
        name: 'Name',
        mac: 'MAC Address',
        ip: 'IP Address',
        status: 'Status',
        statusDetail: 'Status Detail',
      },
    },
    filterTester: {
      groupableTitle: 'Groupable Devices:',
      pagingGatewaysTitle: 'Paging Gateways:',
      label: {
        matchingDevices: 'Matching Devices',
        viewDeviceAttributes: 'View Device Attributes',
      },
    },
    ruleBuilder: {
      knownFilterValues: {
        '-2': '-2',
        '-1': '-1',
        'As-Is': 'As-Is',
        '+1': '+1',
        '+2': '+2',
        'Cisco IP Phone': 'Cisco IP Phone for Unified CM',
        'IP Phone 3905': 'IP Phone 3905',
        'IP Phone 6921': 'IP Phone 6921',
        'IP Phone 6941': 'IP Phone 6941',
        'IP Phone 6945': 'IP Phone 6945',
        'IP Phone 6961': 'IP Phone 6961',
        'IP Phone 7811': 'IP Phone 7811',
        'IP Phone 7821': 'IP Phone 7821',
        'IP Phone 7832': 'IP Phone 7832',
        'IP Phone 7841': 'IP Phone 7841',
        'IP Phone 7861': 'IP Phone 7861',
        'IP Phone 7905': 'IP Phone 7905',
        'IP Phone 7906': 'IP Phone 7906',
        'IP Phone 7911': 'IP Phone 7911',
        'IP Phone 7912': 'IP Phone 7912',
        'IP Phone 7920': 'IP Phone 7920',
        'IP Phone 7921': 'IP Phone 7921',
        'IP Phone 7925': 'IP Phone 7925',
        'IP Phone 7926': 'IP Phone 7926',
        'IP Phone 7931': 'IP Phone 7931',
        'IP Phone 7937': 'IP Phone 7937',
        'IP Phone 7940': 'IP Phone 7940',
        'IP Phone 7941': 'IP Phone 7941',
        'IP Phone 7941-GE': 'IP Phone 7941-GE',
        'IP Phone 7942': 'IP Phone 7942',
        'IP Phone 7945': 'IP Phone 7945',
        'IP Phone 7960': 'IP Phone 7960',
        'IP Phone 7961': 'IP Phone 7961',
        'IP Phone 7961-GE': 'IP Phone 7961-GE',
        'IP Phone 7962': 'IP Phone 7962',
        'IP Phone 7965': 'IP Phone 7965',
        'IP Phone 7970': 'IP Phone 7970',
        'IP Phone 7971': 'IP Phone 7971',
        'IP Phone 7975': 'IP Phone 7975',
        'IP Phone 8811': 'IP Phone 8811',
        'IP Phone 8821': 'IP Phone 8821',
        'IP Phone 8831': 'IP Phone 8831',
        'IP Phone 8832': 'IP Phone 8832',
        'IP Phone 8841': 'IP Phone 8841',
        'IP Phone 8845': 'IP Phone 8845',
        'IP Phone 8851': 'IP Phone 8851',
        'IP Phone 8851NR': 'IP Phone 8851NR',
        'IP Phone 8861': 'IP Phone 8861',
        'IP Phone 8865': 'IP Phone 8865',
        'IP Phone 8865NR': 'IP Phone 8865NR',
        'IP Phone 8941': 'IP Phone 8941',
        'IP Phone 8945': 'IP Phone 8945',
        'IP Phone 8961': 'IP Phone 8961',
        'IP Phone 9841': 'IP Phone 9841',
        'IP Phone 9851': 'IP Phone 9851',
        'IP Phone 9951': 'IP Phone 9951',
        'IP Phone 9971': 'IP Phone 9971',
        'IP Speaker and Desktop Notifier': 'IP Speaker and Desktop Notifier',
        M2M: 'M2M',
        'Multicast Stream': 'Multicast Stream',
        No: 'No',
        'Paging Gateway 2': 'Paging Gateway 2',
        'Quick URL': 'Quick URL',
        Yes: 'Yes',
        'Poly Obi Phone': 'Poly OBi Phone',
        'Poly UCS Phone': 'Poly UCS Phone',
      },
      filterComparisonValues: {
        EQUALS: 'Equal',
        EQUALSLower: 'equal',
        BEGINS_WITH: 'Begin With',
        BEGINS_WITHLower: 'begin with',
        CONTAINS: 'Contain',
        CONTAINSLower: 'contain',
        CONTAINS_IN: 'Contains In',
        CONTAINS_INLower: 'contains in',
        ENDS_WITH: 'End With',
        ENDS_WITHLower: 'end with',
        IS_IN_SUBNET: 'Belong to Host Bitmask',
        IS_IN_SUBNETLower: 'belong to host bitmask',
      },
      label: {
        addRule: 'Add Rule',
        caseSensitive: 'Case Sensitive',
        ignoreCase: 'Ignore Case',
        expression: 'Expression',
        and: 'AND',
        or: 'OR',
        comparisonType: 'Comparison Type',
        rules: 'Rules',
        index: 'Index',
        filter: 'Filter',
        logicalExpression: 'Logical Expression',
        duplicateRule: 'Rule with this value already exists',
        does: 'Does',
        doesLower: 'does',
        doesNot: 'Does Not',
        doesNotLower: 'does not',
      },
      note: {
        subtitle: 'Create rules to include or exclude devices',
        filterByRulesWarning:
          'Rule filter results are limited if device group filters are specified. Only devices that belong to chosen device groups will be considered.',
        logicalExpression:
          'Specify a combination of rules based on rule index number and the words “and” and “or.” For example, (1 or 2 and not 3).',
      },
    },
    downloadQrcodeFilename: 'SignInQr.png',
    userLoaderShared: {
      memberCount: 'Member Count',
      importedStatus: 'Imported Status',
      licenseStatus: 'License Status',
      doNotLicense: 'Do Not License Users',
      manageDomains: 'Manage Domains',
      manageSites: 'Manage Site Access',
      viewGroupMembers: 'View Members',
      moreActions: 'More Actions',
      userCreationErrors: 'User Creation Errors',
      viewUserGroups: 'View User Group Memberships',
      licenseUsers: 'License Users',
      uploadedGroupMembership: 'Uploaded Group Members',
      uploadedGroups: 'Uploaded Groups',
      uploadedGroupsLower: 'uploaded groups',
      uploadedUsers: 'Uploaded Users',
      promotion: '↑ Promotion',
      promoteText:
        'Promote from a currently selected child domain to a parent domain. This may remove many currently selected domains.',
      demotion: '↓ Demotion',
      demoteText: 'Demote from a currently selected parent domain to a child domain.',
      scimGroupMembers: 'Uploaded Group Members',
      noName: '[No Formatted Name Received]',
      manageGroups: '{{resource}} Uploaded Group Domains',
      manageGroupsFaciltiies: '{{resource}} Uploaded Group Site Access',
      groupsSubtitleFaciltiies:
        "Assign the users in the {{groupName}} uploaded group to global- or local-level sites. Depending on their user type and permissions, this will grant them access to those sites' resources.",
      updateScimFacilitiesSuccess:
        'You have successfully updated the {{resource}} group sites for {{name}}.',
      groupsSubtitle:
        'Add or remove the uploaded users within the {{groupName}} {{resource}} from domains.',
      updateScimDomainsSuccess:
        'You have successfully updated the {{resource}} group domains for {{name}}.',
      groupsNote:
        'If users are in many {{resource}} groups, domain associations are promoted to their highest parent levels.',
    },
    integration: {
      import: {
        sitesTitle: '{{integrationName}} Site Import Details',
        sitesSubtitle: 'Select available {{integrationName}} sites to import into InformaCast.',
        entryTitle: 'Import',
        entrySubtitle:
          'Click the Import button to import resources from your {{integrationName}} environment into InformaCast.',
        configTitle: '{{integrationName}} Configuration',
        mgmtTitle: '{{integrationName}} Import Details',
        mgmtSubtitle:
          'View the details of your {{integrationName}} import and take any next steps.',
        infoTitle: 'Import Information',
        nextStepsTitle: 'Next Steps',
        nextStepsSubtitle:
          "Now that you've imported {{integrationName}} sites, you can further integrate them with Command Center’s site roles and scenarios.",
        noSites: 'No sites available for import.',
        infoSubtitle:
          'You can find information about what you have imported into InformaCast below.',
        sitesIntegrationDescription:
          'Allows you to quickly configure scenarios to send relevant information based up on the location of users and devices.',
        label: {
          import: 'Import',
          sites: 'Sites',
          selectSites: 'Select Sites',
          sitesIntegration: '{{integrationName}} Sites Integration',
          imported: 'Imported',
          notImported: 'Not Imported',
          noImportDate: 'None',
        },
        sitesTable: {
          nameColumn: 'Site Name',
          existsColumn: 'Preexisting Site Name',
          linkedColumn: 'Linked',
          noSites: 'No sites',
          noSitesFound: 'No sites found',
        },
        mgmtTable: {
          integrationColumn: 'Integration',
          detailsColumn: 'Details',
          statusColumn: 'Status',
          dateImportedColumn: 'Date Imported',
        },
        nextSteps: {
          createSiteRoles: 'Create Site Roles',
          siteRolesDesc:
            "Create site roles to add to your imported sites. Site roles describe the audience of a scenario's notifications when a site is chosen as the answer to a scenario's question or a variable's value, e.g. Staff versus Students.",
          goToSiteRoles: 'Go To Site Roles',
          createSites: 'Assign Recipients to Site Roles',
          sitesDesc:
            "Assign recipients to the site roles in your imported sites, and they will receive a scenario's notifications when a site is chosen as the answer to a scenario's question or variable's value.",
          goToSites: 'Go To Sites',
          createScenarios: 'Create Scenarios',
          scenariosDesc:
            "Add your imported sites to standard scenarios as the answer to a scenario's question or modify the CallAware for Zoom integration scenario to use your imported sites as variable values.",
          goToScenarios: 'Go To Scenarios',
        },
        tooltip: {
          unlinkSites: 'Unlink Sites',
        },
        unlinkSitesDialog: {
          title: 'Unlink Imported Sites',
          message:
            'You have chosen to unlink the sites imported from {{integrationName}}. Unlinking imported sites allows you to delete these sites in InformaCast or change their names, actions that are prevented while sites are linked.',
          failure: 'Failed to unlink {{integrationName}} sites.',
          success: 'Successfully unlinked {{integrationName}} sites.',
        },
        importExistingSitesConfirm: {
          title: 'Merge Preexisting Sites',
          message:
            "You have chosen to merge one or more {{integrationName}} {{noun}} with existing InformaCast sites of the same names. While the information in your {{integrationName}} environment will remain the same, the information in InformaCast's sites will change. They will now include both their existing information and information imported from {{integrationName}}.",
        },
        growl: { importSuccess: 'You have imported sites from {{integrationName}} successfully.' },
      },
    },
    keyboardShortcut: {
      label: {
        functionKey: 'Modifier Key {{number}}',
        shortcutKey: 'Key',
        clearModifierKeys: 'Clear Keys',
      },
      windowFunctionKey: {
        shift: 'Shift',
        alt: 'Alt',
        control: 'Control',
      },
    },
    frequencyPicker: {
      frequencies: {
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
      },
      frequencyError: 'Frequency type is required',
      daysOfMonth: {
        first: '1st',
        middle: '15th',
      },
      dateError: 'Day of month must be selected.',
      daysOfWeek: {
        sundayShort: 'U',
        mondayShort: 'M',
        tuesdayShort: 'T',
        wednesdayShort: 'W',
        thursdayShort: 'R',
        fridayShort: 'F',
        saturdayShort: 'S',
      },
      daysOfWeekError: 'At least one day needs to be selected.',
      occurs: 'Occurs',
      occursAtError: 'Occurs at time must be selected.',
      at: 'At',
      repeats: 'Repeats',
      onTheseDays: 'On These Days',
      onThe: 'On The',
      cron: 'Cron Expression',
      cronPlaceholder: '0 15 10 L-2 * ?',
      cronHelp: 'Click <0>here</0> to learn more about creating cron expressions.',
      advancedScheduling: 'Use Advanced Scheduling',
      standardScheduling: 'Use Standard Scheduling',
    },
    fusionServerSelect: {
      serverSelectWidget: {
        onPremisesServers: 'On-premises Servers',
        selected: 'Selected On-premises Server',
        available: 'Available On-premises Servers',
      },
      label: {
        close: 'Close On-premises Servers Dropdown Menu',
        serverSelect: 'On-premises Servers Dropdown Menu',
        noDescription: 'No Description',
        manage: 'Manage',
        manageServers: 'Manage Servers',
        activeFusionServer: '* Denotes Server-specific Configurations',
      },
    },
    failoverEditingBackupInfoBanner: {
      changeToPrimary:
        "You're on your backup on-premises server, and changes here are not permitted. <0>Switch to your primary on-premises server</0>.",
      makeChangesToBackup:
        "You're on your backup on-premises server. Certain changes are permitted here, but most configuration is performed on your primary on-premises server. <0>Switch to your primary on-premises server.</0>",
    },
    uploadComponent: {
      modal: {
        title: 'Edit Upload',
        subtitle: 'Use the image handles to frame a portion of your image to upload.',
      },
    },
    variableComponent: {
      label: 'Insert Variables',
      labelName: 'Insert Variables for {{name}}',
      subtitle: 'Select a variable to include a dynamic element that may assume different values.',
      dateTime: {
        title: 'Date/Time Sent',
        dateFormat: 'Date Format',
        timeFormat: 'Time Format',
        dateShort: {
          title: 'Short',
          subtitle: '6/30/22',
        },
        date: {
          title: 'Medium',
          subtitle: 'Jun 30, 2022',
        },
        dateLong: {
          title: 'Long',
          subtitle: 'June 30, 2022',
        },
        dateFull: {
          title: 'Full',
          subtitle: 'Tuesday, June 30, 2022',
        },
        timeShort: {
          title: 'Short',
          subtitle: '7:03 AM',
        },
        time: {
          title: 'Medium',
          subtitle: '7:03:47 AM',
        },
        timeLong: {
          title: 'Long',
          subtitle: '7:03:47 AM PDT',
        },
        timeFull: {
          title: 'Full',
          subtitle: '7:03:47 AM PDT',
        },
        timeZone: {
          title: 'Time Zone',
          subtitle: 'PDT',
        },
      },
      location: {
        description: {
          title: 'Full Description',
          subtitle: 'West Campus (Main Office, Floor 1, Room 102)',
        },
        siteName: {
          title: 'Site',
          subtitle: 'West Campus',
          interpolated: 'Site: {{name}}',
        },
        buildingName: {
          title: 'Building',
          subtitle: 'Main Office',
          interpolated: 'Building: {{name}}',
        },
        floorName: {
          title: 'Floor',
          subtitle: 'Floor 1',
          interpolated: 'Floor: {{name}}',
        },
        zoneName: {
          title: 'Room/Zone',
          subtitle: 'Room 102',
          interpolated: 'Room: {{name}}',
        },
        buildingFullAddress: {
          title: 'Full Description',
          subtitle: '1002 Deming Way, Suite 102, Madison WI. 53717',
        },
        buildingAddressLine1: {
          title: 'Address Line 1',
          subtitle: '1002 Deming Way',
        },
        buildingAddressLine2: {
          title: 'Address Line 2',
          subtitle: 'Suite 102',
        },
        buildingCity: {
          title: 'City',
          subtitle: 'Madison',
        },
        buildingState: {
          title: 'State',
          subtitle: 'WI',
        },
        buildingZip: {
          title: 'ZIP Code',
          subtitle: '53717',
        },
        buildingCountry: {
          title: 'Country',
          subtitle: 'USA',
        },
      },
      scenarioLocation: {
        title: 'Scenario Location',
        siteInformation: 'Site Information',
        buildingInformation: 'Building Information',
      },

      escalationRule: {
        title: 'Escalation Rules',
        timeThreshold: {
          title: 'The time to wait before escalating',
          subtitle: '5',
        },
        timeThresholdUnits: {
          title: 'The measurement of time to wait before escalating',
          subtitle: 'Hour(s)',
        },
        comparison: {
          title: 'The comparison value for the response option',
          subtitle: 'Greater Than',
        },
        numberOfUsers: {
          title: 'The number of users expected to respond',
          subtitle: '12',
        },
        optionValue: {
          title: 'The matching response option',
          subtitle: 'I need assistance',
        },
      },

      callAware: {
        title: 'CallAware',
        callID: {
          title: 'Cisco Call ID',
          subtitle: '15127/1',
        },
        callingDN: {
          title: 'Calling DN',
          subtitle: '70040',
        },
        calledDN: {
          title: 'Destination DN',
          subtitle: '70042',
        },
        callingPhone: {
          title: 'Calling Phone Name',
          subtitle: 'SEP000BBED8875C',
        },
        date: {
          title: 'Call Date',
          subtitle: '10/16/2009',
        },
        phoneDescription: {
          title: 'Calling Phone Description',
          subtitle: 'Lobby Phone',
        },
        time: {
          title: 'Call Time',
          subtitle: '04:45:11 PM',
        },
      },

      initiatingInformation: {
        title: 'Initiating Information',
        sipUsername: {
          title: 'SIP Username',
          subtitle: "The user section of a Notification DialCast's SIP Invite From header",
        },
        sipHostname: {
          title: 'SIP Hostname',
          subtitle: "The host section of a Notification DialCast's SIP Invite From header",
        },
        sipDisplayName: {
          title: 'SIP Display Name',
          subtitle: "The display name section of a Notification DialCast's SIP Invite From header",
        },
        sipHeader: {
          title: 'SIP Header',
          subtitle:
            "The entire URL-decoded value of a Notification DialCast's SIP Invite From header",
        },
        description: {
          title: 'Description',
          subtitle: "The description of a notification's initiating device or user",
        },
        directoryNumber: {
          title: 'Directory Number',
          subtitle:
            "The directory number of a Notification DialCast's initiating Cisco IP phone for Unified CM",
        },
        email: {
          title: 'Email',
          subtitle: "The email address of a notification's initiating user",
        },
        name: {
          title: 'Name',
          subtitle: "The name of a notification's initiating user",
        },
        userType: {
          title: 'User Type',
          subtitle: "The user type of a notification's initiating user",
        },
      },

      kontaktio: {
        badgeInformation: 'Badge Information',
        badgeAlias: 'Badge Alias',
        badgeTrackingId: 'Badge Tracking ID',
      },
      aoi: {
        title: 'Boundary Triggers',
        aoiInfo: 'Area of Interest and Trigger Information',
        name: { title: 'Boundary Name', subtitle: 'Lincoln Memorial' },
        aoiName: {
          title: 'Area of Interest Name',
          subtitle: 'National Mall',
        },
        triggeringUsername: {
          title: 'Boundary-crossing Username',
          subtitle: 'Miranda Espinosa',
        },
        triggeringEmail: {
          title: 'Boundary-crossing User Email',
          subtitle: 'miranda.espinosa@gmail.com',
        },
        action: {
          title: 'Boundary Action',
          subtitle: 'Entered',
        },
      },
      zoom: {
        submenu: {
          initiatingCall: 'Initiating Call',
          calledNumber: 'Called Number',
        },
        callId: { title: 'ID', subtitle: '730243848629' },
        calleePhoneNumber: { title: 'Phone', subtitle: '911' },
        callerDisplayName: { title: 'Name', subtitle: 'Miranda Espinosa' },
        callerExtensionNumber: { title: 'Extension', subtitle: '4512' },
        callerExtensionType: { title: 'Extension Type', subtitle: 'user' },
        callerId: { title: 'User ID', subtitle: 'KhnAb3eORtG' },
        callerPhoneNumber: { title: 'Phone Number', subtitle: '+16085551212' },
        callerSiteId: { title: 'Site ID', subtitle: 'vfYD1CniTla004QUUWth1A' },
        callerSiteName: { title: 'Site Name', subtitle: 'West Campus' },
        callerTimeZone: { title: 'Time Zone', subtitle: 'America/Chicago' },
        deliverTo: { title: 'Location', subtitle: 'PSAP' },
        ringingStartTime: { title: 'Start Time', subtitle: '2023-11-17T14:30:54Z' },
        router: { title: 'Router Location', subtitle: 'ZOOM' },
        emergencyAddress: {
          addressLine1: { title: 'Address Line 1', subtitle: '1002 Deming Way' },
          addressLine2: { title: 'Address Line 2', subtitle: 'Suite 102' },
          city: { title: 'City', subtitle: 'Madison' },
          country: { title: 'Country', subtitle: 'USA' },
          stateCode: { title: 'State', subtitle: 'WI' },
          zip: { title: 'Zip Code', subtitle: '53717' },
        },
      },
      dialcast: {
        label: 'DialCasts',
        variables: {
          serverId: 'On-premises Server ID',
          dialcastId: 'DialCast ID',
          dialcastName: 'DialCast Name',
          expressionOrPattern: 'DialCast Expression or Pattern',
          dialcastPattern: 'DialCast Pattern',
          callingDn: 'Directory Number',
          initiatorId: 'ID',
          initiatorMac: 'MAC Address',
          userType: 'User Type',
          description: 'Description',
          sipUsername: 'SIP Username',
          sipHostname: 'SIP Hostname',
          sipDisplayName: 'SIP Display Name',
          sipHeader: 'SIP Header',
        },
        description: {
          serverId: 'The ID of the on-premises server initiating the DialCast',
          dialcastId: 'The ID of the initiated DialCast',
          dialcastName: 'The name of the initiated DialCast',
          expressionOrPattern:
            "The regular expression specifying the initiated DialCast's dialing pattern, or the dialing pattern itself",
          dialcastPattern: 'The DN or dialing pattern of an initiated DialCast',
          callingDn: "The directory number of a DialCast's initiating device",
          initiatorId: "The ID of a DialCast's initiating device",
          initiatorMac: "The MAC address of a DialCast's initiating device",
          userType: "The user type of a DialCast's initiating user",
          description: "The description of a DialCast's initiating device or user",
          sipUsername: "The user section of a SIP Invite's From header",
          sipHostname: "The host section of a SIP Invite's From header",
          sipDisplayName: "The display name section of a SIP Invite's From header",
          sipHeader: "The entire URL-decoded value of a SIP Invite's From header",
        },
      },
    },
    location: {
      noLocation: 'No Location',
      none: 'None',
      site: {
        interpolated: 'Site: {{name}}',
      },
      building: {
        interpolated: 'Building: {{name}}',
      },
      floor: {
        interpolated: 'Floor: {{name}}',
      },
      zone: {
        interpolated: 'Room: {{name}}',
      },
    },
    deviceInfo: {
      deleted: 'This device may have been deleted.',
      unknownDevice: 'Unknown Device',
    },
    deviceDetails: {
      controls: 'Controls',
    },
    allUsers: {
      caption: 'Users who will potentially receive your notification.',
      viewDeviceDetails: 'View Device Details',
      deviceDetails: 'Device Details',
      devicesCaption: 'User devices that will potentially receive your notification.',
    },
    allDevices: {
      caption: 'Devices that will potentially receive your notification.',
      viewAllDeviceDetails: 'View On-Premises Device Details',
      allDeviceDetils: 'On-Premises Device Details',
      attributesCaption: 'Device attributes and their details',
    },
  },
};

export default SharedLocale;
export const shared_t = registerResource<typeof SharedLocale.en>('shared', SharedLocale);
