import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, from, timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { PermissionDeniedError } from '../../common-types';
import { MobileApiIcopServer, MobileApiLiveCallAwareCallActivity } from '../../mobile-api-types';
import { updateCallAwareLiveCalls } from '../callaware/CallStatusActions';
import { core_t } from '../CoreLocale';
import { growl } from '../layout/LayoutActions';
import { showDirtyFormOrActiveOperationModal } from '../modal/ModalActions';
import { shouldShowDirtyFormOrActiveOperationModal } from '../modal/ModalEpics';
import { reloadCurrentPage } from '../navigation/NavigationActions';
import { RootState } from '../RootReducer';
import { logout } from '../session/SessionActions';
import store from '../store';
import { BackgroundRef, reqAll } from '../utils/api';
import { isBrowserVisible } from '../utils/common';
import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import log from '../utils/log';
import { isFeatureEnabled } from '../utils/session';
import {
  confirmFusionServerChange,
  refreshIcopServers,
  setIcopServerId,
  startMonitoringApplicationHealth,
  startMonitoringLiveCallAwareCalls,
  updateIcopServers,
} from './ICOPServersActions';

export const STORAGE_ICOP_SERVER_ID_KEY = (userId: string) =>
  `admin-webapp.${userId}.selectedICOPServerId`;

// Given a response of servers, first check if there are any in the response, if so, see if the one from our local
// storage is still valid and use that. If it is not valid, just use the first server's id.
export const getSetIcopServerIdAction = (
  servers: MobileApiIcopServer[],
  userId: string
): Action[] => {
  if (!isEmpty(servers)) {
    const previouslySelectedServerId = window.localStorage.getItem(
      STORAGE_ICOP_SERVER_ID_KEY(userId)
    );
    if (previouslySelectedServerId && servers.some(s => s.id === previouslySelectedServerId)) {
      return [setIcopServerId(previouslySelectedServerId, false)];
    } else {
      return [setIcopServerId(servers[0].id, false)];
    }
  } else {
    return [];
  }
};

// Check if there are ICOP servers, and update store.
export const refreshServersEpic: Epic<Action, Action, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshIcopServers)),
    withLatestFrom(store$.pipe(map(s => s.session))),
    switchMap(([action, session]) => {
      if (session && isFeatureEnabled('fusion', session)) {
        return reqAll<MobileApiIcopServer>(
          [
            'fusionExtensionEndpoints',
            [],
            'GET',
            { params: { skipEndpointHealthAssessment: true, includeConfig: false, limit: 500 } },
          ],
          store,
          BackgroundRef
        ).pipe(
          switchMap(servers => [
            updateIcopServers(servers),
            ...getSetIcopServerIdAction(servers, session?.userId!),
            ...(action.payload.cb ? action.payload.cb() : []),
          ]),
          catchError((error: any) => {
            if (error instanceof PermissionDeniedError) {
              log.debug('No permissions to load extension endpoints', error);
            } else {
              log.error('Failed to load extension endpoints', error);
            }
            return from([
              updateIcopServers([]),
              ...(action.payload.cb ? action.payload.cb(error) : []),
            ]);
          })
        );
      } else {
        return action.payload.cb ? action.payload.cb() : [];
      }
    })
  );

// Set id in localStorage.
export const setSelectedServerIdEpic: Epic<Action, Action, RootState, any> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(setIcopServerId)),
    withLatestFrom(store$.pipe(map(s => ({ servers: s.icopServers.servers, session: s.session })))),
    switchMap(([action, state]) => {
      window.localStorage.setItem(
        STORAGE_ICOP_SERVER_ID_KEY(state.session?.userId!),
        action.payload.id
      );
      // If we have withNotice, the user manually changed servers. There is a story to track
      // what the correct behavior should be here: MF-34
      return action.payload.withNotice
        ? [
            growl(
              core_t(['icop', 'servers', 'serverChanged'], {
                server: find(state.servers, (s: MobileApiIcopServer) => s.id === action.payload.id)!
                  .name,
              })
            ),
            reloadCurrentPage(),
          ]
        : [];
    })
  );

// Asks the user to change their currently selected fusion server
export const askUserToChangeFusionServerEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(confirmFusionServerChange)),
    switchMap(action => [
      shouldShowDirtyFormOrActiveOperationModal([])
        ? showDirtyFormOrActiveOperationModal(
            core_t(['changeICOPServerWarningConfirmation', 'title']),
            core_t(['changeICOPServerWarningConfirmation', 'message']),
            [],
            () => [setIcopServerId(action.payload.id, true)]
          )
        : setIcopServerId(action.payload.id, true),
    ])
  );

// Periodically check and update fusion servers every 30s starting after 30s
export const endpointPollingEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startMonitoringApplicationHealth)),
    switchMap(() =>
      timer(30000, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          reqAll<MobileApiIcopServer>(
            [
              'fusionExtensionEndpoints',
              [],
              'GET',
              {
                transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform],
                params: { skipEndpointHealthAssessment: true, includeConfig: false, limit: 500 },
              },
            ],
            store,
            BackgroundRef
          ).pipe(
            map(endpoints => updateIcopServers(endpoints)),
            catchError(error => {
              if (!(error instanceof PermissionDeniedError)) {
                log.error('Failed to determine health of endpoints', error);
              }
              return EMPTY;
            })
          )
        ),
        takeUntil(action$.pipe(ofType(logout)))
      )
    )
  );

// Periodically check and update fusion servers every 30s starting after 30s
export const callAwarePollingEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startMonitoringLiveCallAwareCalls)),
    switchMap(() =>
      timer(1000, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          reqAll<MobileApiLiveCallAwareCallActivity>(
            [
              'pluginCallAwareActiveCalls',
              [],
              'GET',
              { transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform] },
            ],
            store,
            BackgroundRef
          ).pipe(
            map(calls => updateCallAwareLiveCalls(calls)),
            catchError(error => {
              if (!(error instanceof PermissionDeniedError)) {
                log.error('Failed to determine CallAware live status', error);
              }
              return EMPTY;
            })
          )
        ),
        takeUntil(action$.pipe(ofType(logout)))
      )
    )
  );

export default combineEpics(
  refreshServersEpic,
  setSelectedServerIdEpic,
  askUserToChangeFusionServerEpic,
  endpointPollingEpic,
  callAwarePollingEpic
);
