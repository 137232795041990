import { registerResource } from './utils/localization';

export const CoreLocale = {
  en: {
    logoAltText: 'InformaCast Logo',
    customLogoAltText: 'Singlewire InformaCast Logo',
    homePage: {
      message: {
        noLongerSignedIn: 'You are no longer logged in. Please log in to continue.',
      },
    },
    icop: {
      servers: {
        serverChanged:
          'You have successfully selected the InformaCast On-premises server named {{server}}',
      },
    },
    panicButton: {
      panicHidden:
        'You have hidden the panic tracking event banner. To view active panic tracking events, go to Recipients | Tracking Events.',
    },
    message: {
      updateSuccess: 'You have successfully updated the {{resourceName}} {{data}}.',
      createSuccess: 'You have successfully created the {{resourceName}} {{data}}.',
      failedToSave:
        'The {{resourceName}} could not be saved. Please try again. If problems continue, contact your system administrator.',
      failedToDisableDomains: 'Failed to disable domains.',
      successfullyDisabledDomains: 'You have successfully disabled domains.',
    },
    label: {
      information: 'information',
      defunctAndUnlicensed: 'Defunct, Unlicensed',
      unlicensed: 'Unlicensed: {{device}}',
      defunct: 'Defunct Device',
      locked: 'Locked',
      licensed: 'Licensed',
      notLicensed: 'Not Licensed',
      canceled: 'Canceled',
      expired: 'Expired',
      inProgress: 'In Progress',
      initializing: 'Initializing',
      inProgressInitializing: 'In Progress: Initializing',
      inProgressPercent: 'In Progress: {{percent}}%',
      complete: 'Complete',
      unknown: 'Unknown',
      meterAbbrev: 'm',
      kilometerAbbrev: 'km',
      coordinates: 'Coordinates',
      radius: 'Radius',
      includeMobile: 'Includes Mobile Devices',
      includeDesktop: 'Includes Desktop Devices',
    },
    messages: {
      dirtyForm: 'You have unsaved changes. {{message}}',
      cancellableRequests: 'You have operations in progress that have not finished. {{message}}',
      dirtyFormAndCancellableRequests:
        'You have unsaved changes and operations in progress that have not finished. {{message}}',
    },
    logoutWarningConfirmation: {
      title: 'Confirm Logout',
      message: 'Are you sure you want to log out?',
    },
    navigationWarningConfirmation: {
      title: 'Warning!',
      message: 'Are you sure you want to navigate away?',
    },
    changeICOPServerWarningConfirmation: {
      title: 'Confirm Active Server Change',
      message: 'Are you sure you want to change your active server?',
    },
    changeDomainWarningConfirmation: {
      title: 'Confirm Active Domain Change',
      message: 'Are you sure you want to change your active domain?',
    },
    changeFacilityWarningConfirmation: {
      title: 'Confirm Active Site Change',
      message: 'Are you sure you want to change your active site?',
    },
    facilities: {
      failedGetAllFacilities: 'Failed to get all sites.',
    },
    domains: {
      tabTitle: 'Domains',
      noVisibleDomains: 'No domains visible from your current domain.',
      addDomainResourceFailedMessage: 'Failed to add resource to domain.',
      failedGetAllDomains: 'Failed to get all domains.',
      demoteDomainResource: {
        title: 'Demotion Required',
        message: `Moving {{resourceType}} '{{resourceName}}' to the '{{toPath}}' domain will demote it and remove it from the parent '{{fromPath}}' domain.`,
        failedMessage: 'Failed to demote resource.',
      },
      manageDomainResources: {
        title: 'Domain {{resources}}',
        subtitle: `Add or remove {{resources}} from the '{{domainName}}' domain.`,
      },
      manageResourceDomains: {
        title: '{{resource}} Domains',
        subtitle: `Add or remove the '{{resourceName}}' {{resource}} from domains.`,
      },
      promoteDomainResource: {
        title: 'Promotion Required',
        message: `Moving {{resourceType}} '{{resourceName}}' to the '{{toPath}}' domain will promote it and remove it from the '{{fromPath}}' domain.`,
        twoDescendantsMessage: `Moving {{resourceType}} '{{resourceName}}' to domain '{{toPath}}' will take it out of the domains '{{fromPath1}}' and '{{fromPath2}}'.`,
        manyDescendantsMessage: `Moving {{resourceType}} '{{resourceName}}' to domain '{{toPath}}' will take it out of the domains '{{fromPath1}}', '{{fromPath2}}', and others.`,
        failedMessage: 'Failed to promote resource.',
      },
      removeDomainResourceFailedMessage: 'Failed to remove resource from domain.',
      promotePermission: {
        fromOneChild: `promotable from subdomain '{{subdomain}}'`,
        fromTwoChildren: `promotable from subdomains '{{subdomain1}}' and '{{subdomain2}}'`,
        fromManyChildren: `promotable from subdomains '{{subdomain1}}', '{{subdomain2}}', and others`,
      },
      permissionFromParent: `inherited from parent domain '{{parent}}'`,
    },
    login: {
      loginSuccess: 'Welcome back {{name}}.',
      logoutSuccess: 'You have successfully logged out.',
    },
    messageTemplateDetails: {
      label: {
        inactiveCampaign: '{{distributionList}} (Inactive Campaign)',
        campaign: '{{distributionList}} (Campaign)',
        ciscoWebexTeams: '{{collaborationGroup}} (Cisco Webex)',
        conferenceCall: '{{collaborationGroup}} (Conference Call)',
        msteamsChat: '{{collaborationGroup}} (Microsoft Teams)',
        msteamsChannel: '{{collaborationGroup}} (Microsoft Teams Channel)',
        outboundCap: '{{collaborationGroup}} (Outbound CAP)',
        outboundRss: '{{collaborationGroup}} (Outbound RSS)',
        twitter: '{{collaborationGroup}} (Twitter)',
      },
    },
    navigation: {
      home: 'Home',
      reports: {
        title: 'Reports',
        user: 'User Reports',
        anonymousUser: 'Anonymous User Reports',
        notificationHistory: 'Notification History Reports',
        confirmation: 'Confirmation Reports',
        auditEvents: 'Change History Reports',
      },
      admin: {
        title: 'Admin',
        overview: 'Server Configuration',
        systemHealth: 'System Health',
        dataImport: 'Data Import',
        userLoaders: 'User Loaders',
        schoolInformation: 'School Information',
        selfRegistration: 'Self-registration',
        bulkUpload: 'Bulk Uploads',
        securityGroups: 'Security Groups',
        domains: 'Domains',
        facilities: 'Facilities',
        oauthAppsEnabled: {
          serviceAccounts: 'Service Accounts',
        },
        triggers: 'Triggers',
        servers: 'Servers',
        distributedActivation: 'Activation Groups',
        fusionFailover: 'Failover Pairs',
        phoneResetStrategy: 'Phone Reset Strategy',
        activeNotifications: 'Active Notifications',
        clearDevices: 'Clear Devices',
        dialToIntercom: 'Dial To Intercom',
        telephony: {
          title: 'Cisco Unified CM',
          cucmClusters: 'Clusters',
          cucmExternalCallControl: 'External Call Control',
          cucmPhoneRefreshOverview: 'Phone Refresh Overview',
          cucmPhoneParameters: 'Phone Parameters',
          ctiCertificates: 'CTI Certificates',
        },
        sip: {
          title: 'SIP',
          access: 'Access',
          accounts: 'Accounts',
          callSecurity: 'Call Security',
          certificates: 'SIP Certificates',
          restart: 'SIP Restart',
          speakerParameters: 'SIP Speaker Parameters',
          stack: 'Stack',
          userCredentials: 'User Credentials',
          serverGroups: 'SIP Server Groups',
          registrations: 'SIP Registrations',
          dnsSrvRecords: 'DNS SRV Records',
        },
        licenses: {
          title: 'Licenses',
          userCount: 'User Count',
          phones: 'Cisco IP Phones for Unified CM',
          polyPhones: 'Poly Phones',
          speakers: 'IP Speakers',
          idns: 'Desktop Notifiers',
        },
        integrations: {
          title: 'Integrations',
          msteams: 'Microsoft Teams',
          webexCalling: 'Webex Calling',
          zoom: 'Zoom',
          ruleActions: 'API Connectors',
          verkada: 'Verkada',
          ipCameras: 'IP Cameras',
        },
        settings: {
          appearance: 'Appearance',
          support: 'In-app Support',
          title: 'Settings',
          polyPhones: 'Poly Phones',
          android: 'Android',
          applications: 'Applications',
          publicApplications: 'Application Marketplace',
          kontaktioWearablePanicButton: 'Kontakt.io',
          desktops: 'Desktops',
          calling: 'Calling',
          dominsConfig: 'Domains',
          dnd: 'Do Not Disturb',
          email: 'Email',
          ios: 'iOS',
          sms: 'SMS',
          tts: 'Text-to-Speech',
          ipaws: 'IPAWS',
          timeZoneAndLocale: 'Time Zone and Locale',
          idn: 'Desktop Notifiers',
          windows: 'Windows',
          mac: 'Mac',
          informaCastApps: 'InformaCast Apps',
          voiceMenus: {
            title: 'Voice Menus',
            generalVoiceMenu: 'General Voice Menu',
            inboundVoiceMenu: 'Inbound Call Voice Menu',
            broadcastVoiceMenu: 'Broadcast Voice Menu',
          },
          networkParameters: {
            title: 'Network Parameters',
            broadcastParameters: 'Broadcast Parameters',
            slpParameters: 'SLP Parameters',
            speakerParameters: 'Speaker Parameters',
          },
          slpParameters: {
            title: 'SLP Parameters',
          },
          speakerSettings: {
            title: 'IP Speakers',
          },
          certificates: {
            title: 'Certificates',
            server: 'Server Certificate',
            client: 'Client Certificates',
            ssl: 'SSL Parameters',
          },
        },
        security: {
          title: 'Security',
          encryptionKeyManagement: 'Encryption Key',
        },
      },
      recipients: {
        title: 'Recipients',
        manageUsers: 'Users',
        distributionLists: 'Distribution Lists',
        manageAnonymousUsers: 'Anonymous Users',
        deviceGroups: 'Device Groups',
        areasOfInterest: 'Areas of Interest',
        trackingEvents: 'Tracking Events',
        speakers: 'IP Speakers',
        idns: 'Desktop Notifiers',
        pagingGateways: 'Paging Gateways',
        msteams: 'Microsoft Teams',
        quickUrl: 'Quick URL',
        gms: 'Multicast Streams',
        gmsPhones: 'Cloud Phones',
        gmsPhonesGeneric: 'Cloud Phones Generic',
        desktopUsers: {
          title: 'Desktop Devices',
          desktopUsers: 'Desktop Devices',
        },
        outboundSystems: {
          title: 'Outbound Systems',
          outboundCap: 'Outbound CAP',
          outboundRss: 'Outbound RSS',
          teamsGroup: 'Microsoft Teams',
          conferenceCall: 'Conference Calls',
          webexTeams: 'Cisco Webex',
          twitter: 'Twitter',
        },
        lpi: {
          title: 'Legacy Paging Interface',
          home: 'Home',
          config: 'Configuration',
          sipServerGroups: 'SIP Server Groups',
          pagingDevices: 'Paging Devices',
          currentActivity: 'Current Activities',
        },
        m2m: {
          title: 'M2M',
          schedules: 'Schedules',
          contactClosures: 'Contact Closures',
        },
        devices: {
          title: 'API Devices',
        },
      },
      notifications: {
        title: 'Notifications',
        history: 'Notification History',
        messageTemplates: 'Message Templates',
        followUpMessageTemplate: 'Follow-up Message Template',
        confirmationRequests: 'Confirmation Requests',
        trackingEventNotifications: 'Tracking Event Notifications',
        notificationProfiles: 'Notification Profiles',
        dialCast: {
          title: 'DialCast',
          dialingConfigs: 'Dialing Configurations',
          phoneExceptions: 'Phone Exceptions',
        },
        callAwareRules: 'CallAware Rules',
        callAware: {
          title: 'CallAware',
          config: 'Configuration',
          cucms: 'Cisco Unified CM Clusters',
          callRedirects: 'Call Redirects',
          routingRequests: 'Routing Requests',
          callHistory: 'Call History',
          diagnostics: 'Diagnostics',
        },
        scheduledNotifications: 'Scheduled Notifications',
        inboundEvents: {
          title: 'Event Sources',
          icap: 'Inbound CAP',
          email: 'Email',
          rss: 'Inbound RSS',
        },
        ipaws: {
          alerts: 'Alerts',
          configuration: 'Configuration',
          customAreas: 'Custom Areas',
          title: 'IPAWS',
        },
        bells: {
          title: 'Bell Schedules',
          exceptions: 'Exceptions',
          overview: 'Overview',
          ringLists: 'Ring Lists',
          schedules: 'Schedules',
        },
        nightBell: {
          title: 'Night Bell',
          config: 'Configuration',
          cucms: 'Cisco Unified CM Clusters',
          triggers: 'Triggers',
          diagnostics: 'Diagnostics',
        },
        commandCenter: {
          title: 'Command Center',
          siteRoles: 'Site Roles',
          sites: 'Sites',
          sitesV2: 'Sites v2',
          site: 'Site',
          scenarios: 'Scenarios',
          launchers: 'Scenario Launchers',
          incidentPlans: 'Incident Plans',
          incidents: 'Incidents',
        },
      },
    },
    campaign: {
      noCampaignNumber: '[No Campaign Number]',
      noPermissions: '[No permissions]',
      requestFailed: '[Request Failed]',
    },
    phoneCache: {
      distributeFailed: 'Phone cache sync failed on {{formattedTimestamp}}.',
      failedToLoadActivities: 'Failed to load endpoint activities.',
      failedToUpdate: 'Phone cache update initiated at {{formattedTimestamp}} has failed.',
      loading: 'Loading latest phone cache status...',
      neverUpdated: 'Phone cache has never been updated.',
      noUpdates: 'No phone cache update results available',
      pending: 'Phone cache update began on {{formattedTimestamp}}.',
      rediscoverSubmitted:
        'You have submitted the request to re-discover phones. This background process may take some time to complete.',
      updateCompleted:
        'Phone cache update last completed on {{formattedTimestamp}}. It may take up to a few minutes for phones to finish synchronizing.',
    },
    ruleBuilder: {
      filterTestFailed:
        'Failed to test filters. Please try again. If problems continue, contact your system administrator.',
    },
  },
};

export default CoreLocale;
export const core_t = registerResource<typeof CoreLocale.en>('core', CoreLocale);
