// A central place to store constants, when the same constant will be used in multiple places.

// ALARM IDS
export const RedStateAlarmID = 'dcaca6f6-9d71-11eb-b775-0242ac110003';
export const YellowStateAlarmID = 'cdcf1a88-9d71-11eb-b775-0242ac110003';
export const LicenseAlarmID = 'e2125942-9d71-11eb-b775-0242ac110003';
export const SpeakerAlarmID = 'f28b0080-9d71-11eb-b775-0242ac110003';
export const PhoneCacheUpdateAlarmID = '91b26697-934f-11ec-b6a2-99eae723bd46';
export const SISLoaderAlarmID = 'a25f212f-9d71-11eb-b775-0242ac110003';
export const FusionDeltaSyncErrorEventAlarmID = '4f2a8452-71bc-11ed-aa23-9bc083285fdb';
export const WebhookErrorAlarmID = '4d683041-6dd1-11ee-953a-a791b5c65e4d';
export const TokenExpirationAlarmID = '5bcebefb-4d8c-11ee-85bb-c5b3d0db8947';
export const ScenarioDeviceResolutionFailedAlarmID = 'e91f7c07-c9cc-11ee-9e02-51949edf0286';
export const PotentialSpeakerConfigurationIssueAlarmID = 'ba027b01-19e3-11ef-866b-217134b6c754';
export const IpCameraServiceIssueAlarmID = '4813567e-42c2-11ef-ae45-2dacc0099706';
export const WebexCallingIntegrationFailureAlarmID = '81b9bf16-9c5d-11ef-820f-a7c434e9dbb3';

export const AllAlarmIds = [
  RedStateAlarmID,
  YellowStateAlarmID,
  LicenseAlarmID,
  SpeakerAlarmID,
  PhoneCacheUpdateAlarmID,
  SISLoaderAlarmID,
  FusionDeltaSyncErrorEventAlarmID,
  WebhookErrorAlarmID,
  TokenExpirationAlarmID,
  ScenarioDeviceResolutionFailedAlarmID,
  PotentialSpeakerConfigurationIssueAlarmID,
  IpCameraServiceIssueAlarmID,
  WebexCallingIntegrationFailureAlarmID,
] as const;

// EXTENSION IDS
export const ANDROID_EXTENSION_ID = '5a181177-3f02-11e6-9617-6bb7c7eddc0e';
export const ANDROID_QA_EXTENSION_ID = '6ff812ad-18e7-11e7-9f96-1d559a8159e8';
export const APNS_DESKTOP_EXTENSION_ID = 'ddca87d8-d2af-11eb-9747-5d78afe13d0b';
export const APNS_DESKTOP_SANDBOX_EXTENSION_ID = 'be227b70-d2af-11eb-9747-5d78afe13d0b';
export const CISCO_SPARK_EXTENSION_ID = '299036d0-188a-11e7-a184-fd464c9fabab';
export const DND_EXTENSION_ID = 'b77bb090-2fde-11e5-80c4-56847afe9799';
export const EMAIL_EXTENSION_ID = 'b6d90e60-2a40-11e5-8134-9a9e6c05013a';
export const ICOP_DEVICE_EXTENSION_ID = 'e322d549-4e80-11e6-af2d-9baf604d14b0';
export const ICOP_EXTENSION_ID = '551de6ae-3d36-11e6-834a-3d1fcb19aadd';
export const IDN_EXTENSION_ID = '56d6e006-542e-11e6-ae77-e3b4cf41fce9';
export const INBOUND_CAP_EXTENSION_ID = 'e1b2db4b-626a-11e7-a3f9-0de43455e890';
export const IOS_EXTENSION_ID = '839771a8-4915-11e6-8b2f-d310e58d9c03';
export const IOS_QA_EXTENSION_ID = '5fdb030c-18e7-11e7-9f96-273f619cf339';
export const IOS_SANDBOX_EXTENSION_ID = '1a172e36-3f02-11e6-9617-9fc8b50f4de1';
export const IPAWS_EXTENSION_ID = 'e1b2db4b-626a-11e7-a3f9-0de43455e899';
export const IPAWS_ADMIN_EXTENSION_ID = '34500f62-d7f6-11ea-a103-ab79d5b2d450';
export const OUTBOUND_CAP_EXTENSION_ID = 'e1b2db4b-626a-11e7-a3f9-0de43455e892';
export const OUTBOUND_CAP_DEVICE_EXTENSION_ID = 'e1b2db4b-626a-11e7-a3f9-0de43455e893';
export const PHONE_CALL_EXTENSION_ID = '0a466b30-7670-11e5-ba6e-765ae9d26291';
export const POLY_EXTENSION_ID = 'b1f767c6-886b-11e9-ae1b-61e96372bfa1';
export const SMS_EXTENSION_ID = '17c57b50-833d-11e5-b222-def38c5ff9e6';
export const TEAMS_CALL_EXTENSION_ID = 'acf4cc22-1528-11e9-a215-1b69016c9905';
export const TEAMS_CHAT_EXTENSION_ID = 'f0e5d932-c31f-11e8-b4c1-7831c1bab3f6';
export const WEBEX_CALLING_EXTENSION_ID = '6118dfae-397d-11ef-691f-2710a7f00a24';
export const TEAMS_CHANNEL_EXTENSION_ID = 'f6bf06bf-34d3-11eb-86d2-fdad5c6ff418';
export const TWITTER_EXTENSION_ID = '8fa9c390-8926-11e8-b64d-cbf1729c5619';
export const KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID = '44d2902c-4344-11ec-bf8d-f70025e4774c';
export const WNS_EXTENSION_ID = 'acb4e1a4-d029-11ea-b857-a730b507a69c';
export const ZOOM_EXTENSION_ID = '92616e30-189e-11eb-ae72-8d8ced6174aa';
export const ZOOM_SITES_EXTENSION_ID = '0990f412-cf80-11eb-93a4-1b8f855dda9d';
export const EVENT_SERVICE_EXTENSION_ID = '6c321b72-6c5f-11ee-9071-a7b1e721ff1e';
export const IP_CAMERAS_SERVICE_EXTENSION_ID = 'd7a20f24-0bdb-11ef-ab84-633508817e15';

// REPORT IDS
export const GENERAL_COUNTS_REPORT_API_ID = '30a05e90-9156-11e6-b405-11f7f6be9a24';
export const PHONE_CACHE_STATUS_REPORT_API_ID = 'dad2edbe-6b2e-11e7-907b-a6006ad3dba0';
export const TELEPHONY_NUMBERS_REPORT_API_ID = '40c2d980-5c87-11e5-836c-92a0fedb8915';
export const DEVICE_REGISTRATIONS_REPORT_API_ID = '19fb51f4-0113-11ec-b663-4dfb693b3582';
export const FUSION_UPGRADE_STATUS_REPORT_API_ID = '935413b1-66c2-11e9-b4f7-514408f15ee0';
export const NOTIFICATION_ACTIVITIES_ROLLING_SUM_V2_ID = 'a4d0346e-2921-11eb-94a3-4bd812c322a9';
export const MULTICAST_CONFIG_REPORT_ID = '1696b9af-1dee-11ea-bdf5-210dc607cd1e';
export const TELEPHONY_USAGE_SCALE = 100000.0;

// Gotcha: exporting ids as an object to accommodate legacy code
export const REPORT_API_IDS = {
  generalCounts: GENERAL_COUNTS_REPORT_API_ID,
  phoneCacheStatus: PHONE_CACHE_STATUS_REPORT_API_ID,
  telephonyNumbers: TELEPHONY_NUMBERS_REPORT_API_ID,
  fusionUpgradeStatus: FUSION_UPGRADE_STATUS_REPORT_API_ID,
};

export const ANONYMOUS_USER_REPORT_ID = 'ffb9be7a-73bf-11e7-81a9-bb2acacbf0d9';
export const NOTIFICATION_HISTORY_REPORT_ID = 'fffce1fb-73bf-11e7-81a9-f312ef69431f';
export const OPTED_OUT_REPORT_ID = 'ff695489-73bf-11e7-81a9-3557b17e8348';
export const USERS_REPORT_ID = 'fec17bc8-73bf-11e7-81a9-f7379b42fa1e';
export const CONFIRMATION_REPORT_ID = 'fba2f02e-7d23-11eb-9439-0242ac130002';
export const AUDIT_EVENTS_REPORT_ID = '624a6fd3-bf69-11ed-8e9c-4f237c460d8a';
export const AUDITABLE_RESOURCES_REPORT_ID = '4f213338-f8be-11ed-8c04-6b6216e9fb1c';
export const TOKEN_EXPIRATION_REPORT_ID = '8fda36f6-4d02-11ee-a811-79b58e8b3a00';

// FUSION
export const REVERSE_SYNCING_VERSION = '14.15.1';
export const REVERSE_SYNCING_FEATURE = 'reverse-syncing';
export const CLOUD_BELL_SCHEDULE_FEATURE = 'cloud-bell-schedules';
export const CURRI_FEATURE = 'curri';
export const DIAL_TO_INTERCOM_FEATURE = 'dial-to-intercom';
export const DISTRIBUTED_ACTIVATION_FEATURE = 'distributed-activation';
export const FIPS_FEATURE = 'fips';
export const GENERIC_MULTICAST_STREAMING_FEATURE = 'generic-multicast';
export const NON_VALIDATED_ALERT_TONE_CODES_FEATURE = 'non-validated-alert-tone-codes';
export const STYLE_SUPPORT_FEATURE = 'style-support';
export const VDI_LOCATION_FEATURE = 'vdi-location';
export const FUSION_SERVER_UPGRADE_FEATURE = 'fusion-server-upgrade';
export const REQUIRE_NUMERIC_SIP_SPEAKER_DN = 'require-numeric-sip-speaker-dn';
export const ADVANCED_BROADCAST_PARAMETERS_FEATURE = 'advanced-broadcast-parameters';
export const ADHOC_QUEUEING_FEATURE = 'adhoc-queueing';
export const CTI_OVER_TLS_FEATURE = 'cti-over-tls';
export const FAILOVER_FEATURE = 'failover';
export const CTI_MAX_FEATURE = 'cti-max';
export const DNS_SRV_FEATURE = 'dns-srv';
export const CLOUD_CUCM_CLUSTERS_FEATURE = 'cloud-cucm-clusters';
export const PROVIDER_KEY_ROTATION = 'provider-key-rotation';
export const COLLECT_LOGS_FEATURE = 'collect-logs';
export const UCAAS_INTERCOM_FEATURE = 'ucaas-intercom';
export const UNENCRYPTED_UCAAS_INTERCOM_FEATURE = 'unencrypted-ucaas-intercom';
export const SYSTEM_ALARMS_FEATURE = 'system-alarms';
export const ON_PREM_BROADCAST_SUMMARY = 'on-prem-broadcast-summary';
export const PG2_FEATURE = 'pg2';
export const PG_DISTRIBUTED_ACTIVATION = 'pg-distributed-activation';
export const PG_ENABLE_DEVICE_GROUPS = 'pg2-enable-device-groups';
export const PG_NAME = 'pg2-name';
export const PG_EVENTS = 'pg-events';
export const PG_FILTER_RETRIEVAL = 'pg-filter-retrieval';
export const MULTI_REGISTRATION_REALMS_FEATURE = 'multi-registration-realms';
export const NOTIFICATION_HISTORY_FEATURE = 'notification-history-details';
export const FLEXIBLE_SIP_CALL_SECURITY_FEATURE = 'flexible-sip-call-security';
export const REMOVE_CAN_LISTEN = 'remove-can-listen';
export const SIP_ACCOUNTS_FEATURE = 'sip-accounts';
export const IDN_LARGE_FONTS = 'idn-large-fonts';
export const WEBEX_CALLING = 'webex-calling';

export const BRANDINGS_ID = '2601234b-a1e6-11ea-b7db-f3cc7d266a8e';
export const MODELESS_INFORMACAST = 'modeless-informacast';
export const DISTRIBUTED_GATHERING = 'distributed-gathering';

// GOOGLE API KEY/MAPS
export const GOOGLE_API_KEY = 'AIzaSyAlsjDDLEzacmIvJZbWi6Ht6DpEpWUYVeI';
export const GOOGLE_MAPS_API_VERSION = '3.45.7';
export const SINGLEWIRE_COORDINATES = { latitude: 43.0731, longitude: -89.4012 };
export const LOWER_48_BOUNDS = { north: 47.5, east: -69.8, south: 24.5, west: -122.3 };

// ROOT DOMAIN ID
export const ROOT_DOMAIN_ID = '69dab71b-12b2-11e8-bfe3-41154f2b6534';

// GLOBAL FACILITY ID
export const GLOBAL_FACILITY_ID = ROOT_DOMAIN_ID;

// INITIAL DEFAULT TTS VOICE
export const INITIAL_DEFAULT_TTS_VOICE_ID = '751de142-841a-11ea-a32f-17f989f7588d';
export const INITIAL_DEFAULT_TTS_VOICE_NAME = 'Joanna';
export const INITIAL_DEFAULT_TTS_LANGUAGE = 'en-US';

// PAGER CONSTANTS
export const DEFAULT_PAGER_LIMIT = 50;
export const LIST_SCENARIOS_PAGER_ID = 'list-scenarios';

// SITES CONSTANTS
export const SITE_MAX_NAME_LENGTH = 255; // 255 is the max name length for campuses/buildings/floors/rooms in Kontakt.io so we match it here

// USER LOCATIONS
export const MAX_USER_LOCATIONS = 3;
export const MAX_ZOOM = 0.005;

// DEFAULT NOTIFICATION PROFILE ID
export const DEFAULT_NOTIFICATION_PROFILE_ID = '4079969a-966a-11e8-96d9-fb917867f39d';

// LAYOUT MODAL IDS
export const LAYOUT_CONFIRM_MODAL_ID = 'layoutConfirmModal';
export const LAYOUT_INFO_MODAL_ID = 'layoutInfoModal';
export const LAYOUT_REGISTRY_MODAL_ID = 'layoutRegistryModal';
export const LAYOUT_INFO_REGISTRY_MODAL_ID = 'layoutInfoRegistryModal';

// ALERT TONES
export const ALERT_TONES = [
  'default',
  'silent',
  'accent',
  'bright',
  'chime',
  'chirp',
  'crystal',
  'fire-alarm',
  'industrial-alarm',
  'klaxon',
  'tone',
];

export const ADDITIONAL_ALERT_TONES = [
  '911-dialed',
  'all-clear1',
  'all-clear2',
  'bell',
  'class-change1',
  'class-change2',
  'class-change3',
  'class-change4',
  'class-change5',
  'class-change6',
  'code-blue',
  'code-call',
  'evacuate1',
  'evacuate2',
  'evacuate3',
  'evacuate4',
  'internal-emergency1',
  'internal-emergency2',
  'internal-emergency3',
  'location-closing',
  'lockdown-1-pulse',
  'lockdown-2-pulses',
  'lockdown-3-pulses',
  'lockdown',
  'room-turn',
  'severe-weather-advisory',
  'shift-change1',
  'shift-change2',
  'shift-change3',
  'shift-change4',
  'take-shelter',
  'tornado-siren',
];

export const REGEX_VALIDATION = {
  phoneNumber:
    /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
};

export const KnownDeviceTypes = {
  Android: 'mobile',
  apns: 'mobile',
  'apns-desktop-sandbox': 'desktop', // Mac Desktop for QA Testing
  'apns-desktop': 'desktop', // Mac Desktop
  'apns-sandbox': 'mobile',
  IPSpeaker: 'speaker',
  M2M_OutputPort: 'm2mOutputPort',
  'Multicast Stream': 'multicastStream',
  'msteams-chat': 'msteamsChat',
  PagingGateway: 'pagingGateway',
  'Paging Gateway 2': 'pagingGateway2',
  'phone-call': 'phoneCall',
  QuickURL: 'quickUrl',
  'singlewire:CiscoIPPhone': 'phones',
  'singlewire:email': 'email',
  SMS: 'sms',
  WNS: 'desktop', // Windows Notification Service (Windows Desktop)
} as const;

export const COLOR_REGEX = /^#?(([a-fA-F0-9]){3}){1,2}$/;
export const COLOR_REGEX_6 = /^#?(([a-fA-F0-9]){3}){2}$/;

// Help Link lookup
export const refLookup = {
  fusion: 'InformaCast_Fusion',
  informacast: 'InformaCast',
  external: 'External',
};

export const STATE_NAMES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

// IP Camera vendor ids
export const VERKADA_VENDOR_ID = '7226d862-f6a1-11ee-8591-6b4253d9dc1c';
