import { useCallback, useState } from 'react';
import { Typography } from 'singlewire-components';

export const useLiveRegion = (defaultText?: string) => {
  // State and setters for debounced value
  const [liveRegionText, setLiveRegionText] = useState<string | null>(null);

  const Component = (
    <div aria-live="polite" aria-atomic={true} role="status">
      <Typography variant="body" srOnly>
        {liveRegionText}
      </Typography>
    </div>
  );

  const updateLiveRegion = useCallback(
    (text?: string) => {
      setLiveRegionText(text || defaultText || null);
      setTimeout(() => {
        setLiveRegionText(null);
      }, 2000);
    },
    [defaultText]
  );

  return { Component, updateLiveRegion };
};
