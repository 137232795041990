import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { confirmFusionServerChange } from '../../core/icop-servers/ICOPServersActions';
import { navigateTo } from '../../core/navigation/NavigationActions';
import { RootState } from '../../core/RootReducer';
import { FusionEndpointSelect } from './FusionEndpointSelect';

// This component allows a user to switch between Fusion servers.

export type FusionEndpointSelectProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  selectedICOPServer: state.icopServers.selectedICOPServer,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ confirmFusionServerChange, navigateTo }, dispatch);

export const FusionEndpointSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FusionEndpointSelect);
